import Menu from './Menu';
import { MouseEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from 'tss-react/mui';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles()((theme) => ({
	cardHeader: {
		height: '75px',
	},
	avatar: {
		backgroundColor: '#1e1b34',
		boxShadow: theme.shadows[3],
	},
}));

interface IProps {
	menuOpen: boolean;
	title: string | null | undefined;
	handleMenuClick: (event: MouseEvent<HTMLElement>) => void;
	anchorMenuEl: null | HTMLElement;
}

const CardHeaderComponent = ({
	menuOpen,
	title = '',
	anchorMenuEl,
	handleMenuClick,
}: IProps) => {
	const { classes } = useStyles();

	// TODO: Pull in with Model
	const techPackDesignerName = 'Sarah Peterson';

	return (
		<CardHeader
			className={classes.cardHeader}
			avatar={
				<Avatar
					aria-label=""
					className={classes.avatar}
					src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
				>
					{techPackDesignerName.charAt(0)}
				</Avatar>
			}
			action={
				<>
					<IconButton
						id="card-options-button"
						aria-controls={
							menuOpen ? 'card-options-menu' : undefined
						}
						aria-label=""
						aria-haspopup="true"
						aria-expanded={menuOpen ? 'true' : undefined}
						onClick={handleMenuClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						menuOpen={menuOpen}
						handleMenuClick={handleMenuClick}
						anchorMenuEl={anchorMenuEl}
					/>
				</>
			}
			title={title}
			subheader={`By: ${techPackDesignerName}`}
		/>
	);
};

export default CardHeaderComponent;

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Breadcrumbs from './Breadcrumbs';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import TechPackCard from '../TechPacks/TechPackCard';
import { useParams, Link as ReactLink } from 'react-router-dom';
import { IProductTypeTechPackMap, ITechPack } from '../../../common/interfaces';
import { useFindOneCollectionWithTechPacksQuery } from '../../api/generated';

// Put into Component
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles()((theme) => ({
	collectionContainer: {},
}));

const Collection = () => {
	const { classes } = useStyles();
	const { id: collectionIdString } = useParams();
	const colllectionId = Number(collectionIdString);
	const { data } = useFindOneCollectionWithTechPacksQuery({
		findOneCollectionInput: {
			id: colllectionId,
		},
	});
	const collection = data?.findOneCollection;

	const productTypeTechPacksMap: IProductTypeTechPackMap = {};

	collection?.techPacks?.forEach((techPack: ITechPack) => {
		if (techPack.productType) {
			if (!productTypeTechPacksMap[techPack.productType]) {
				productTypeTechPacksMap[techPack.productType] = [];
			}

			productTypeTechPacksMap[techPack.productType].push(techPack);
		}
	});

	const techPacksComponents = Object.keys(productTypeTechPacksMap)?.map(
		(productType) => {
			const techPacks = productTypeTechPacksMap[productType];

			const techPackComponents = techPacks.map((techPack) => {
				const { id } = techPack;
				const path = `/tech-packs/${id}`;

				return (
					<Link
						to={path}
						key={id}
						color="primary"
						underline="none"
						component={ReactLink}
					>
						<TechPackCard key={id} techPack={techPack} />
					</Link>
				);
			});

			return (
				<Box key={productType}>
					<Accordion
						sx={{
							marginBottom: '10px',
						}}
						elevation={0}
					>
						<AccordionSummary
							sx={{
								minHeight: '50px !important',
								maxHeight: '50px !important',
								borderBottom: '1px solid #eee',
							}}
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>{productType}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box>{techPackComponents}</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
			);
		}
	);

	return (
		<Container maxWidth="xl">
			<Breadcrumbs pageName={collection?.name} />
			<Box className={classes.collectionContainer}>
				{techPacksComponents}
			</Box>
		</Container>
	);
};

export default Collection;

const themeColors = {
	heatherGrey: '#74778e',
	lightestGrey: '#eee',
	// darkPurple: '#690eef',
	// lightPurple: '#9f16f4',
	// lightBlue: '#4b95f7',
	// lighterBlue: '#4ec4eb',
	// darkAqua: '#28e6d5',
	// lightAqua: '#29f1df',
	// green: '#1fbf52',
	// lightGreen: '#88fb77',
	// darkOrange: '#fa9a2f',
	// lightOrange: '#fdcc3c',
	// coinbaseBorderColor: 'rgb(236, 239, 241)',
	// red: '#e65c76',
};

export default themeColors;

import { FiFramer } from 'react-icons/fi';
import { makeStyles } from 'tss-react/mui';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

const fiIconSize = 22;
const drawerFontColor = '#fff';

const useStyles = makeStyles()((theme) => ({
	drawerHeaderListItem: {},
	drawerHeaderListItemText: {
		color: drawerFontColor,
	},
	drawerHeaderLogo: {
		color: drawerFontColor,
	},
}));

const Logo = ({
	open,
	onClick,
}: {
	open: boolean;
	onClick: React.MouseEventHandler<HTMLLIElement>;
}) => {
	const { classes } = useStyles();

	return (
		<ListItem
			key="Facilery"
			disablePadding
			sx={{ display: 'block' }}
			onClick={onClick}
			className={classes.drawerHeaderListItem}
		>
			<ListItemButton
				disableRipple
				sx={{
					minHeight: 48,
					justifyContent: open ? 'initial' : 'center',
					px: 2.5,
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 3 : 'auto',
						justifyContent: 'center',
					}}
				>
					<FiFramer
						size={fiIconSize}
						className={classes.drawerHeaderLogo}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Facilery"
					className={classes.drawerHeaderListItemText}
					primaryTypographyProps={{
						sx: { fontSize: '1.3rem' },
					}}
					sx={{ opacity: open ? 1 : 0 }}
				/>
			</ListItemButton>
		</ListItem>
	);
};

export default Logo;

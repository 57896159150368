import { useEffect, useState } from 'react';
import tagCandidates from '../../../mockData/tagCandidates';

const mentionsCache = new Map();

const lookupService = {
	search(string: string, callback: (results: Array<string>) => void): void {
		setTimeout(() => {
			const results = tagCandidates.filter((mentionCandidate) =>
				mentionCandidate.toLowerCase().includes(string.toLowerCase())
			);
			callback(results);
		}, 500);
	},
};

interface IOptions {
	mentionString: string | null;
}

const useMentionLookupService = ({ mentionString }: IOptions) => {
	const [results, setResults] = useState<Array<string>>([]);

	useEffect(() => {
		const cachedResults = mentionsCache.get(mentionString);

		if (mentionString == null) {
			setResults([]);
			return;
		}

		if (cachedResults === null) {
			return;
		} else if (cachedResults !== undefined) {
			setResults(cachedResults);
			return;
		}

		mentionsCache.set(mentionString, null);

		lookupService.search(mentionString, (newResults) => {
			mentionsCache.set(mentionString, newResults);
			setResults(newResults);
		});
	}, [mentionString]);

	return results;
};

export default useMentionLookupService;

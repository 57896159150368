import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { MentionNode, TagNode } from './nodes';
import { onError, onChange } from './handlers';
import ExampleTheme from './themes/ExampleTheme';
import Placeholder from './components/Placeholder';
import { MentionsPlugin, TagPlugin } from './plugins';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';

import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';

const useStyles = makeStyles()((theme) => ({
	editorInput: {
		position: 'relative',
		tabSize: '1',
		resize: 'none',
		outline: '0',
		minHeight: '50px',
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.lightestGrey.main}`,
		borderRadius: '5px',
		'& .editor-paragraph': {
			textAlign: 'left',
		},
		'& p:first-of-type': {
			margin: '0px',
		},
		'& span.mention': {
			backgroundColor: '#fff !important',
			color: theme.palette.secondary.main,
			padding: '0px 5px',
			// borderRadius: '5px',
			// border: '1px solid #5951FF',
		},
	},
	plainTextPluginContainer: {
		position: 'relative',
	},
	placeholderPlainText: {
		top: '9px',
	},
	placeholderRichText: {
		top: '54px',
	},
}));

const initialConfig = {
	onError,
	theme: ExampleTheme,
	nodes: [
		TagNode,
		LinkNode,
		ListNode,
		MentionNode,
		ListItemNode,
		AutoLinkNode,
	],
	namespace: 'LexicalEditor',
};

interface IProps {
	enableMentions?: boolean;
	enableTags?: boolean;
	enableRichText?: boolean;
	placeholder: string;
}

const LexicalEditor = ({
	enableTags = false,
	enableRichText = false,
	enableMentions = false,
	placeholder,
}: IProps) => {
	const { classes } = useStyles();
	const [floatingAnchorElem, setFloatingAnchorElem] =
		useState<HTMLDivElement | null>(null);

	console.log(floatingAnchorElem);

	const onRef = (_floatingAnchorElem: HTMLDivElement) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<div className={classes.plainTextPluginContainer}>
				{enableRichText ? (
					<>
						<RichTextPlugin
							contentEditable={
								<div>
									<div className="editor" ref={onRef}>
										<ToolbarPlugin />
										<ContentEditable
											className={classes.editorInput}
										/>
									</div>
								</div>
							}
							placeholder={
								<Placeholder
									text={placeholder}
									className={classes.placeholderRichText}
								/>
							}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<ListPlugin />
						<LinkPlugin />
						<AutoLinkPlugin />
					</>
				) : (
					<PlainTextPlugin
						contentEditable={
							<ContentEditable className={classes.editorInput} />
						}
						placeholder={
							<Placeholder
								text={placeholder}
								className={classes.placeholderPlainText}
							/>
						}
						ErrorBoundary={LexicalErrorBoundary}
					/>
				)}
			</div>
			<OnChangePlugin onChange={onChange} />
			<AutoFocusPlugin />
			<HistoryPlugin />
			{enableTags ? <TagPlugin /> : ''}
			{enableMentions ? <MentionsPlugin /> : ''}
		</LexicalComposer>
	);
};

export default LexicalEditor;

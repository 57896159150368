import SideBar from './SideBar';
import Box from '@mui/material/Box';
import PageContent from './PageContent';
import PageContentWithHeader from './PageContentWithHeader';

interface IProps {
	withHeader?: boolean;
	children: JSX.Element;
}

const LayoutApp = ({ children, withHeader = true }: IProps) => {
	return (
		<Box sx={{ display: 'flex' }}>
			<SideBar />
			{withHeader ? (
				<PageContentWithHeader children={children} />
			) : (
				<PageContent children={children} />
			)}
		</Box>
	);
};

export default LayoutApp;

import moment from 'moment';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import { ITechPack, IImageUrls } from '../../../../common/interfaces';

const imageHeight = 125;

const useStyles = makeStyles()((theme) => ({
	techPackHeaderContainer: {
		top: '0',
		zIndex: '10',
		position: 'sticky',
		backgroundColor: '#fff',
	},
	tableContainer: {
		display: 'flex',
		height: `${imageHeight + 2}px`,
		// border: '1px solid #ccc',
	},
	imageContainer: {
		height: `${imageHeight}px`,
	},
	image: {
		height: `${imageHeight}px`,
	},
	tableRowsContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: '1',
		height: `${imageHeight}px`,
	},
	tableRowContainer: {
		display: 'flex',
		flexGrow: '.5',
		borderTop: '1px solid #ccc',
		'&:first-of-type': {
			borderTop: '1px solid #ccc',
		},
		'&:last-of-type': {
			borderBottom: '1px solid #ccc',
		},
	},
	tableCell: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		width: '33.333%',
		paddingLeft: theme.spacing(1),
		fontSize: '.9rem',
		'&:first-of-type': {
			borderRight: '1px solid #ccc',
		},
		'&:nth-of-type(2)': {
			borderRight: '1px solid #ccc',
		},
		'&:last-of-type': {
			borderRight: '1px solid #ccc',
		},
	},
}));

interface IProps {
	techPack: ITechPack | null | undefined;
}

const TechPackHeader = ({ techPack }: IProps) => {
	let imageUrl = '';
	const { classes } = useStyles();
	const company = techPack?.company;
	const logoUrls = company?.logoUrls;

	if (typeof logoUrls === 'string') {
		const imageUrls: IImageUrls = JSON.parse(logoUrls);
		imageUrl = imageUrls.desktop.medium;
	}

	return (
		<Box className={classes.techPackHeaderContainer}>
			<Box className={classes.tableContainer}>
				<Box className={classes.imageContainer}>
					<img className={classes.image} src={imageUrl} alt="" />
				</Box>
				<Box className={classes.tableRowsContainer}>
					<Box className={classes.tableRowContainer}>
						<Box className={classes.tableCell}>
							{techPack?.productType?.toUpperCase()} #
							{techPack?.modelNumber}
						</Box>
						<Box className={classes.tableCell}>
							Designer: Sarah Peterson
						</Box>
						<Box className={classes.tableCell}>
							Date: {moment(techPack?.createdAt).format('l')}
						</Box>
					</Box>
					<Box className={classes.tableRowContainer}>
						<Box className={classes.tableCell}>
							Season: {techPack?.season}
						</Box>
						<Box className={classes.tableCell}>
							Product Type: {techPack?.productType}
						</Box>
						<Box className={classes.tableCell}>
							Production Supplier: Morning Tiger
						</Box>
					</Box>
					<Box className={classes.tableRowContainer}>
						<Box className={classes.tableCell}>
							Main Material: {techPack?.mainMaterial}
						</Box>
						<Box className={classes.tableCell}>
							Size Ranges: {techPack?.sizeRanges}
						</Box>
						<Box className={classes.tableCell}>
							Tech Designer: Kaythy Kallas
						</Box>
					</Box>
					<Box className={classes.tableRowContainer}>
						<Box className={classes.tableCell}>
							Garment Finish: Kaythy Kallas
						</Box>
						<Box className={classes.tableCell}>
							Fit: {techPack?.fit}
						</Box>
						<Box className={classes.tableCell}>
							Pattern Maker: Morning Tiger
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default TechPackHeader;

interface IOptions {
	pathname: string;
}

const getFirstPathFromPathname = ({ pathname }: IOptions) => {
	const pathnameParts = pathname.split('/');
	return pathnameParts[1];
};

export default getFirstPathFromPathname;

const defaultColors = {
	primaryLight: '#1e1b34',
	primaryMain: '#1e1b34', // Blue
	primaryDark: '#1e1b34', // Blue Dark
	secondaryMain: '#5951FF', // Purple
	backgroundDefault: '#ffffff',
	errorMain: '#cc0101',
};

export default defaultColors;

// primaryMain: '#6610f2', // Purple
// primaryDark: '#560bd0', // Puple Dark
// secondaryMain: '#19857b',
// line: '#eceff1'

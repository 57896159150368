import { IEvent } from '../interfaces';
import { IFullCalendarEvent } from '../../../../common/interfaces';

interface IGenEventsOptions {
	calendarEvents?: IFullCalendarEvent[];
}

const generateEventsFromFullCalendarEvents = ({
	calendarEvents,
}: IGenEventsOptions): IEvent[] | undefined => {
	return calendarEvents?.map((calendarEvent: any) => {
		const { id, end, title, start, color, allDay, groupId, fitting } =
			calendarEvent;

		return {
			id,
			end,
			title,
			start,
			allDay,
			groupId,
			extendedProps: { color, fitting },
		};
	});
};

export default generateEventsFromFullCalendarEvents;

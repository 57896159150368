import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { useState, MouseEvent } from 'react';
import { FiCircle, FiCheckCircle } from 'react-icons/fi';

const darkPurple = '#1e1b34';
const green = '#2aa775';

const useStyles = makeStyles()((theme) => ({
	taskCardContainer: {
		display: 'flex',
		marginBottom: '20px',
	},
	taskCardActionContainer: {
		cursor: 'pointer',
		padding: theme.spacing(1),
	},
	cardCompletionIcon: {
		top: '3px',
		color: '#ddd',
		position: 'relative',
	},
	cardCompletionIconComplete: {
		color: green,
	},
	taskCardDetailsContainer: {
		padding: theme.spacing(1),
	},
	taskCardDetailsTitle: {
		color: darkPurple,
	},
	taskCardMetaDetailsContainer: {
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		fontSize: '.9rem',
		marginTop: '8px',
	},
	taskCardDetailsDescription: {
		color: theme.palette.heatherGrey.main,
	},
	taskCardDetailsDueDate: {
		color: theme.palette.heatherGrey.main,
	},
	taskCardUserContainer: {},
	taskCardUserName: {
		color: theme.palette.heatherGrey.main,
	},
	circleSeperator: {
		margin: `0px ${theme.spacing(1)}`,
		color: theme.palette.heatherGrey.main,
		fill: theme.palette.heatherGrey.main,
	},
}));

interface IProps {
	task: any;
}

const TaskCard = ({ task }: IProps) => {
	const { classes } = useStyles();
	const [isClosed, setIsClosed] = useState(false);
	const { title, description, user, dueDate } = task;
	const { firstName, lastName } = user;

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (isClosed) {
			setIsClosed(false);
		} else {
			setIsClosed(true);
		}
	};
	return (
		<Paper className={classes.taskCardContainer}>
			<Box
				onClick={handleClick}
				className={classes.taskCardActionContainer}
			>
				{isClosed ? (
					<FiCheckCircle
						size={18}
						className={`${classes.cardCompletionIcon} ${classes.cardCompletionIconComplete}`}
					/>
				) : (
					<FiCircle
						size={18}
						className={classes.cardCompletionIcon}
					/>
				)}
			</Box>
			<Box className={classes.taskCardDetailsContainer}>
				<Box className={classes.taskCardDetailsTitle}>{title}</Box>
				<Box className={classes.taskCardDetailsDescription}>
					{description}
				</Box>
				<Box className={classes.taskCardMetaDetailsContainer}>
					<Box className={classes.taskCardDetailsDueDate}>
						{dueDate}
					</Box>
					<FiCircle size={5} className={classes.circleSeperator} />
					<Box className={classes.taskCardUserContainer}>
						<Box className={classes.taskCardUserName}>
							{firstName} {lastName}
						</Box>
					</Box>
				</Box>
			</Box>
		</Paper>
	);
};

export default TaskCard;

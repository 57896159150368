import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	techPackCardImageWrapper: {
		display: 'flex',
		justifyItems: 'center',
		justifyContent: 'center',
		width: '100%',
		borderBottom: '1px solid #eee',
	},
	techPackCardImageContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		width: '200px',
		minWidth: '200px',
		height: '200px',
		minHeight: '200px',
		padding: '10px',
	},
	techPackCardImage: {
		width: '100%',
	},
}));

interface IProps {
	imageUrl: string | null | undefined;
}

const CardImage = ({ imageUrl }: IProps) => {
	const { classes } = useStyles();

	return (
		<div className={classes.techPackCardImageWrapper}>
			<div className={classes.techPackCardImageContainer}>
				<img
					loading="lazy"
					alt=""
					src={`${imageUrl}`}
					className={classes.techPackCardImage}
				/>
			</div>
		</div>
	);
};

export default CardImage;

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import AnnotationCard from './AnnotationCard';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../../../../hooks';

const useStyles = makeStyles()((theme) => ({
	annotationsContainer: {
		height: '80%',
		marginLeft: '10px',
		minWidth: '500px',
		overflowY: 'scroll',
	},
	annotationsHeaderContainer: {
		padding: theme.spacing(2),
		borderBottom: '1px solid #ccc',
	},
	annotationsHeader: {
		fontSize: '1.3rem',
	},
	annotationsSubHeader: {
		fontSize: '.9rem',
	},
	annotationsContentContainer: {
		// padding: theme.spacing(2),
	},
	annotationsEmptyContentContainer: {
		margin: theme.spacing(2),
	},
	annotationsEmptyContent: {
		borderRadius: '10px',
		backgroundColor: theme.palette.lightestGrey.main,
		padding: theme.spacing(2),
		fontSize: '.9rem',
	},
}));

const Annotations = () => {
	const { classes } = useStyles();
	const canvasState = useAppSelector((state: any) => state.canvasState);
	const { shapesState } = canvasState;
	const { shapes } = shapesState;

	const annotationCardComponents = Object.keys(shapes)
		.map((id) => {
			const shape = shapes[id];

			return <AnnotationCard key={id} shape={shape} />;
		})
		.sort((a, b) => {
			const aCount = Number(a.props.shape.textContent);
			const bCount = Number(b.props.shape.textContent);

			return aCount - bCount;
		});

	return (
		<Paper elevation={3} className={classes.annotationsContainer}>
			<Box className={classes.annotationsHeaderContainer}>
				<Typography className={classes.annotationsHeader}>
					Annotations
				</Typography>
				<Typography className={classes.annotationsSubHeader}>
					Use @ to tag another user
				</Typography>
				<Typography className={classes.annotationsSubHeader}>
					Use # to tag a Point of Measurement
				</Typography>
			</Box>
			<Box className={classes.annotationsContentContainer}>
				<>
					{annotationCardComponents.length > 0 ? (
						annotationCardComponents
					) : (
						<Box
							className={classes.annotationsEmptyContentContainer}
						>
							<Typography
								className={classes.annotationsEmptyContent}
							>
								Click or drag lines on the image to add
								annotation.
							</Typography>
						</Box>
					)}
				</>
			</Box>
		</Paper>
	);
};

export default Annotations;

import { fabric } from 'fabric';
import { Key } from '../../enums';
import { Canvas, LineArrow, ActionState, CanvasManager } from '../../classes';

enum Action {
	Drag = 'drag',
}

interface IConstructorOptions {
	canvas: Canvas;
	canvasManager: CanvasManager;
}

class EventManager {
	canvas: Canvas;
	actionState: ActionState;
	canvasManager: CanvasManager;
	activeDrawingShape: LineArrow | undefined;
	activeSelectedShape: fabric.Object | undefined;

	constructor({ canvas, canvasManager }: IConstructorOptions) {
		this.canvas = canvas;
		this.canvasManager = canvasManager;
		this.actionState = new ActionState();

		this.activeDrawingShape = undefined;
	}

	keyDownHandler = (event: KeyboardEvent): void => {
		const shape = this.activeSelectedShape;
		const { key, metaKey, shiftKey } = event;

		if (metaKey && key === Key.k) {
			this.canvasManager.reset();
		}

		if (metaKey && !shiftKey && key === Key.z) {
			this.canvasManager.undo();
		}

		if (metaKey && shiftKey && key === Key.z) {
			this.canvasManager.redo();
		}

		if (key === Key.Backspace) {
			this.canvasManager.remove({ shape });
		}
	};

	onMouseDown = (event: any) => {
		const { pointer: points } = event;

		this.actionState.setDrawing(true);

		if (this.actionState.isDrawing && !this.actionState.isSelecting) {
			const shape = this.canvasManager.createLineArrow({
				points,
			});

			this.activeDrawingShape = shape;
			this.canvasManager.addShape({ shape });
		}
	};

	onMouseMovement = (event: any) => {
		const { pointer } = event;
		const { x, y } = pointer;

		if (this.actionState.isDrawing && !this.actionState.isSelecting) {
			this.canvasManager.updateShapePosition({
				points: { x, y },
				shape: this.activeDrawingShape,
			});
		}
	};

	onMouseUp = (event: any) => {
		if (this.actionState.isDrawing && !this.actionState.isSelecting) {
			const shape = this.activeDrawingShape;
			this.canvasManager.createGroupedShape({ shape });

			this.activeDrawingShape = undefined;
		}

		this.actionState.setDrawing(false);
	};

	onSelectionCreated = (obj: any) => {
		const { selected } = obj;

		this.activeSelectedShape = selected[0];
		this.actionState.setSelected(true);
	};

	onSelectionUpdated = (obj: any) => {
		const { selected } = obj;

		this.activeSelectedShape = selected[0];
		this.actionState.setSelected(true);
	};

	onSelectionCleared = (obj: any) => {
		this.activeSelectedShape = undefined;
		this.actionState.setSelected(false);
	};

	onObjectModified = (obj: any) => {
		const { action } = obj;

		if (action === Action.Drag) {
			this.canvasManager.saveState();
		}
	};

	addListeners = () => {
		this.canvas.api.on('mouse:down', this.onMouseDown);
		this.canvas.api.on('mouse:move', this.onMouseMovement);
		this.canvas.api.on('mouse:up', this.onMouseUp);
		this.canvas.api.on('selection:created', this.onSelectionCreated);
		this.canvas.api.on('selection:updated', this.onSelectionUpdated);
		this.canvas.api.on('selection:cleared', this.onSelectionCleared);
		this.canvas.api.on('object:modified', this.onObjectModified);

		document.onkeydown = this.keyDownHandler;
	};

	removeListeners = () => {
		this.canvas.api.off('mouse:up', this.onMouseUp);
		this.canvas.api.off('mouse:move', this.onMouseMovement);
		this.canvas.api.off('mouse:down', this.onMouseDown);
		this.canvas.api.off('selection:created', this.onSelectionCreated);
		this.canvas.api.off('selection:updated', this.onSelectionUpdated);
		this.canvas.api.off('selection:cleared', this.onSelectionCleared);

		document.onkeydown = this.keyDownHandler;
	};
}

export default EventManager;

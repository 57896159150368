import { api } from '../app/api/baseApi';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import canvasStateReducer from './slices/canvasStateSlice';
import authenticationReducer from './slices/authenticationSlice';

const store = configureStore({
	reducer: {
		// Add the generated reducer as a specific top-level slice
		[api.reducerPath]: api.reducer,
		canvasState: canvasStateReducer,
		authentication: authenticationReducer,
	},
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: false,
		}).concat(api.middleware);
	},
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

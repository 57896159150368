import { MouseEvent } from 'react';

interface IOptions {
	setMenuAnchorEl: CallableFunction;
	setSelectedSort: CallableFunction;
	setSelectedCollections: CallableFunction | undefined;
}

const genHandleSortClearClick = ({
	setMenuAnchorEl,
	setSelectedSort,
	setSelectedCollections,
}: IOptions) => {
	return (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setSelectedSort('');
		setMenuAnchorEl(null);
		setSelectedCollections && setSelectedCollections([]);
	};
};

export default genHandleSortClearClick;

import { makeStyles } from 'tss-react/mui';
import Collapse from '@mui/material/Collapse';
import { useState, MouseEvent } from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IActionItem } from '../../../../../common/interfaces';
import ExpandMore from '../../../../components/common/ExpandMore';

const useStyles = makeStyles()((theme) => ({
	cardActions: {
		padding: theme.spacing(1),
		paddingTop: theme.spacing(0),
	},
	expandedContent: {
		padding: theme.spacing(1),
		paddingTop: theme.spacing(0),
	},
	expandedContentItem: {
		padding: theme.spacing(1),
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
}));

interface IProps {
	actionItems: IActionItem[];
}

const CardActionsComponent = ({ actionItems }: IProps) => {
	const { classes } = useStyles();
	const [expanded, setExpanded] = useState(false);

	const handleExpandCardClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setExpanded(!expanded);
	};

	// Note: Here for now, not sure if this is what will be in expand menu
	const actionItemComponents = actionItems.map((actionItem, index) => {
		const itemNumber = index + 1;

		return (
			<Typography
				key={actionItem.id}
				className={classes.expandedContentItem}
			>
				{itemNumber}. {actionItem.action}
			</Typography>
		);
	});

	return (
		<>
			<CardActions disableSpacing className={classes.cardActions}>
				<ExpandMore
					expand={expanded}
					onClick={handleExpandCardClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</ExpandMore>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent className={classes.expandedContent}>
					{actionItemComponents}
				</CardContent>
			</Collapse>
		</>
	);
};

export default CardActionsComponent;

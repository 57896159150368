interface ConfigData {
	serverBaseUrl: string;
}

interface EnvironmentConfig {
	test: ConfigData;
	development: ConfigData;
	production: ConfigData;
	[key: string]: ConfigData;
}

const environmentConfig: EnvironmentConfig = {
	test: {
		serverBaseUrl: 'http://localhost:3001',
	},
	development: {
		serverBaseUrl: 'http://localhost:3001',
	},
	production: {
		serverBaseUrl: 'https://api.facilery.co',
	},
};

export default environmentConfig;

import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { CgMenuBoxed } from 'react-icons/cg';

const useStyles = makeStyles()((theme) => ({
	pageNavContainer: {
		position: 'fixed',
		margin: 'auto',
		// top: '200px',
		// left: '100px',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		alignItems: 'center',
		justifyItems: 'center',
		justifyContent: 'center',
		height: '500px',
		width: '100px',
		backgroundColor: 'white',
		zIndex: '15',
	},
}));

const PageNav = () => {
	const { classes } = useStyles();

	return (
		<Paper elevation={3} className={classes.pageNavContainer}>
			<CgMenuBoxed size={50} />
			<CgMenuBoxed size={50} />
			<CgMenuBoxed size={50} />
			<CgMenuBoxed size={50} />
			<CgMenuBoxed size={50} />
		</Paper>
	);
};

export default PageNav;

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { ITechPack } from '../../../../../common/interfaces';

const size = '36px';

const useStyles = makeStyles()((theme) => ({
	searchItemContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		padding: theme.spacing(1),
		cursor: 'pointer',
	},
	searchItemImageWrapper: {
		display: 'flex',
		justifyItems: 'center',
		justifyContent: 'center',
		width: size,
		padding: '2px',
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		borderRight: '1px solid rgba(0, 0, 0, 0.12)',
	},
	searchItemImageContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		width: size,
		minWidth: size,
		height: size,
		minHeight: size,
		marginRight: theme.spacing(2),
	},
	searchItemImage: {
		width: '100%',
	},
	searchItemNameContainer: {},
	searchItemName: {
		fontSize: '1.05rem',
	},
}));

interface IProps {
	techPack: ITechPack;
}

const TechPackSearchItem = ({ techPack }: IProps) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.searchItemContainer}>
			<Box className={classes.searchItemImageWrapper}>
				<Box className={classes.searchItemImageContainer}>
					<img
						alt=""
						src={`${techPack?.coverImageUrl}`}
						className={classes.searchItemImage}
					/>
				</Box>
			</Box>
			<Box className={classes.searchItemNameContainer}>
				<Typography className={classes.searchItemName}>
					{techPack?.name}
				</Typography>
			</Box>
		</Box>
	);
};

export default TechPackSearchItem;

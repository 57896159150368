// import type { RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { CanvasState } from '../../sdks/FabricCanvas/classes';
import { ShapesState } from '../../sdks/FabricCanvas/classes';
import { Action } from '../../sdks/FabricCanvas/classes/CanvasUtils/enums';

const enum Model {
	CanvasState = 'canvasState',
}

interface ICanvasState {
	canvasState: CanvasState;
	shapesState: ShapesState;
}

interface IState {
	canvasState: ICanvasState;
}

export const canvasStateSlice = createSlice({
	name: Model.CanvasState,
	initialState: {
		canvasState: new CanvasState(),
		shapesState: new ShapesState(),
	},
	reducers: {
		updateCanvasState: (state: any, { payload }: any) => {
			state.canvasState.update({
				state: payload,
			});

			return { ...state };
		},
		undoCanvasState: (state: any) => {
			const result = state.canvasState.undo();

			if (result?.action && result?.objectId) {
				if (result.action === Action.Added) {
					state.shapesState.addBack({ id: result.objectId });
				} else if (result.action === Action.Removed) {
					state.shapesState.delete({ id: result.objectId });
				}
			}

			return { ...state };
		},
		redoCanvasState: (state: any) => {
			const result = state.canvasState.redo();

			if (result?.action && result?.objectId) {
				if (result.action === Action.Added) {
					state.shapesState.addBack({ id: result.objectId });
				} else if (result.action === Action.Removed) {
					state.shapesState.delete({ id: result.objectId });
				}
			}

			return { ...state };
		},
		resetCanvasState: (state: any) => {
			state.canvasState.reset();
			state.shapesState.reset();

			return { ...state };
		},
		newCanvasState: (state: any) => {
			state.canvasState.new();

			return { ...state };
		},
		removeObejectFromCanvasState: (state: any, { payload }: any) => {
			const { id } = payload;

			state.canvasState.remove({ id });
			state.shapesState.delete({ id });

			return { ...state };
		},
		shapesStateAdd: (state: any, { payload }: any) => {
			const { shape } = payload;

			state.shapesState.add({
				shape,
			});

			return { ...state };
		},
		shapesStateReset: (state: any) => {
			state.shapesState.reset();

			return { ...state };
		},
		shapesStateDelete: (state: any, { payload }: any) => {
			const { id } = payload;

			state.shapesState.delete({ id });

			return { ...state };
		},
		shapesStateAddBack: (state: any, { payload }: any) => {
			const { id } = payload;

			state.shapesState.addBack({ id });

			return { ...state };
		},
	},
});

const { reducer, actions } = canvasStateSlice;

// Export Actions
// ------------------
export const {
	newCanvasState,
	redoCanvasState,
	undoCanvasState,
	resetCanvasState,
	updateCanvasState,
	removeObejectFromCanvasState,
	shapesStateAdd,
	shapesStateReset,
	shapesStateDelete,
	shapesStateAddBack,
} = actions;

// // Export Selectors
// // ------------------
const selectCanvasState = (state: IState) => {
	return state.canvasState;
};

export { selectCanvasState };

// Export Reducer
// ------------------
export default reducer;

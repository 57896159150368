import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
import { useState, MouseEvent } from 'react';
import Typography from '@mui/material/Typography';
import TaskForm from '../../../../Tasks/TaskForm';
import { Link as ReactLink } from 'react-router-dom';
import { FiPlus, FiTrash, FiX } from 'react-icons/fi';
import LexicalEditor from '../../../../../LexicalEditor';

const useStyles = makeStyles()((theme) => ({
	annotationCardContainer: {
		padding: theme.spacing(2),
		borderBottom: '1px solid #ccc',
	},
	annotationContainer: {
		width: '100%',
		display: 'flex',
	},
	annotationIdentifierContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'start',
		alignItems: 'start',
	},
	annotationIdentifier: {},
	annotationTextFieldContainer: {
		flexGrow: '1',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	annotationMenuContainer: {},
	annotationMenu: {
		cursor: 'pointer',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignContent: 'center',
		marginTop: theme.spacing(1),
	},
	iconContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		borderRadius: '3px',
		cursor: 'pointer',
	},
	iconAddTaskContainer: {
		backgroundColor: theme.palette.secondary.main,
		marginRight: theme.spacing(1),
		padding: '2px',
	},
	iconAddTask: {
		color: theme.palette.common.white,
	},
	iconDelete: {
		color: theme.palette.heatherGrey.main,
		'&:hover': {
			color: theme.palette.error.main,
		},
	},
	taskFormContainer: {
		marginTop: theme.spacing(1),
	},
	iconCloseTaskFormContainer: {
		position: 'relative',
	},
	iconCloseTaskForm: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		cursor: 'pointer',
	},
	tasksContainer: {
		marginTop: theme.spacing(2),
	},
	taskContainer: {
		cursor: 'pointer',
		fontSize: '.9rem',
		color: 'white',
		borderRadius: '5px',
		padding: '2px 5px',
		backgroundColor: theme.palette.secondary.main,
		boxShadow: theme.shadows[1],
		marginBottom: '5px',
	},
}));

interface IShape {
	id: string;
	name: string;
	textContent: string;
}

interface IProps {
	shape: IShape;
}

interface ITask {
	id: number;
	taskName: string;
}

const AnnotationCard = ({ shape }: IProps) => {
	const { classes } = useStyles();
	const { textContent: annotationIdentifier } = shape;
	const [displayTaskForm, setDisplayTaskForm] = useState(false);
	const [tasks, setTasks] = useState<ITask[]>([]);

	const handleDisplayTaskFormClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (displayTaskForm) {
			setDisplayTaskForm(false);
		} else {
			setDisplayTaskForm(true);
		}
	};

	const handleAddTaskClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (displayTaskForm) {
			setDisplayTaskForm(false);
		} else {
			setDisplayTaskForm(true);
		}

		const id = tasks.length;
		const task = {
			id,
			taskName: `Task #${id} - Let's do this thing..`,
		};

		const updatedTasks = [...tasks, task];

		setTasks(updatedTasks);
	};

	const taskComponents = tasks.map((task) => {
		const { id, taskName } = task;

		return (
			<Link
				to={`/tasks/${id}`}
				color="primary"
				underline="none"
				component={ReactLink}
			>
				<Box key={id} className={classes.taskContainer}>
					{taskName}
				</Box>
			</Link>
		);
	});

	return (
		<Box className={classes.annotationCardContainer}>
			<Box className={classes.annotationContainer}>
				<Box className={classes.annotationIdentifierContainer}>
					<Typography className={classes.annotationIdentifier}>
						{annotationIdentifier}.
					</Typography>
				</Box>
				<Box className={classes.annotationTextFieldContainer}>
					<LexicalEditor
						enableTags={true}
						enableMentions={true}
						placeholder="Annotation..."
					/>

					<Box className={classes.buttonGroup}>
						{displayTaskForm ? (
							<Box
								onClick={handleDisplayTaskFormClick}
								className={classes.iconCloseTaskFormContainer}
							>
								<FiX
									size={18}
									className={classes.iconCloseTaskForm}
								/>
							</Box>
						) : (
							<>
								<Box
									onClick={handleDisplayTaskFormClick}
									className={`${classes.iconContainer} ${classes.iconAddTaskContainer}`}
								>
									<FiPlus
										className={classes.iconAddTask}
										size={11}
									/>
								</Box>
								<Box className={classes.iconContainer}>
									<FiTrash
										className={classes.iconDelete}
										size={15}
									/>
								</Box>
							</>
						)}
					</Box>
				</Box>
			</Box>
			<Box className={classes.tasksContainer}>{taskComponents}</Box>
			{displayTaskForm && (
				<Box className={classes.taskFormContainer}>
					<TaskForm
						buttonSize="small"
						handleAddTaskClick={handleAddTaskClick}
					/>
				</Box>
			)}
		</Box>
	);
};

export default AnnotationCard;

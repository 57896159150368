import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { MentionCard } from './components';

const useStyles = makeStyles()((theme) => ({
	pageHeader: {
		display: 'flex',
		height: '50px',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		marginTop: '30px',
		marginBottom: '30px',
	},
	pageHeaderPageTitle: {
		color: theme.palette.primary.main,
		fontSize: '2rem',
	},
	buttonIcon: {
		marginRight: '5px',
	},
}));

const mentions = [
	{
		id: 0,
		user: '@Sarah Peterson',
	},
	{
		id: 1,
		user: '@Sarah Peterson',
	},
	{
		id: 2,
		user: '@Sarah Peterson',
	},
	{
		id: 3,
		user: '@Sarah Peterson',
	},
];

const Mentions = () => {
	const { classes } = useStyles();

	const mentionsComponents = mentions.map((mention) => {
		const { id } = mention;

		return <MentionCard key={id} mention={mention} />;
	});

	return (
		<Box sx={{ width: '100%' }}>
			<Box className={classes.pageHeader}>
				<Typography className={classes.pageHeaderPageTitle}>
					Mentions
				</Typography>
			</Box>

			<Box>
				<Container maxWidth="xl">{mentionsComponents}</Container>
			</Box>
		</Box>
	);
};

export default Mentions;

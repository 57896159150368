interface IShape {
	id: string;
	name: string;
	textContent: string;
}

interface IAddOptions {
	shape: IShape;
}

interface IDeleteOptions {
	id: number;
}

interface IGetOptions {
	id: number;
}

interface IShapes {
	[key: string]: IShape;
}

class ShapesState {
	shapes: IShapes;
	deletedShapes: IShapes;

	constructor() {
		this.shapes = {};
		this.deletedShapes = {};
	}

	add = ({ shape }: IAddOptions) => {
		const { id } = shape;

		this.shapes[id] = shape;
	};

	addBack = ({ id }: IDeleteOptions) => {
		this.shapes[id] = { ...this.deletedShapes[id] };

		delete this.deletedShapes[id];
	};

	delete = ({ id }: IDeleteOptions) => {
		this.deletedShapes[id] = { ...this.shapes[id] };

		delete this.shapes[id];
	};

	get = ({ id }: IGetOptions) => {
		return this.shapes[id];
	};

	getAll = () => {
		return this.shapes;
	};

	lenght = () => {
		return Object.keys(this.shapes).length;
	};

	count = () => {
		return Object.keys(this.shapes).length + 1;
	};

	reset = () => {
		this.shapes = {};
	};
}

export default ShapesState;

import { api } from './baseApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type Collection = {
  __typename?: 'Collection';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Float']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isReady?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  techPacks?: Maybe<Array<TechPack>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Float']>;
};

export type CollectionCursorInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type CollectionInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Float']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type CollectionOrderByInput = {
  companyId?: InputMaybe<Scalars['String']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CollectionWhereInput = {
  AND?: InputMaybe<Array<CollectionInput>>;
  NOT?: InputMaybe<Array<CollectionInput>>;
  OR?: InputMaybe<Array<CollectionInput>>;
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Float']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type Company = {
  __typename?: 'Company';
  collections?: Maybe<Array<Collection>>;
  contactNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  logoUrls?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  techPacks?: Maybe<Array<TechPack>>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<User>>;
};

export type CompanyCursorInput = {
  contactNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  logoUrls?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CompanyInput = {
  contactNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  transferData?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyOrderByInput = {
  contactNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  logoUrls?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyInput>>;
  NOT?: InputMaybe<Array<CompanyInput>>;
  OR?: InputMaybe<Array<CompanyInput>>;
  contactNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  transferData?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateCollectionInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isReady?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type CreateCompanyInput = {
  contactNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logoUrls?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateFittingInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type CreateFittingsTechPackInput = {
  fittingId?: InputMaybe<Scalars['Float']>;
  techPackId?: InputMaybe<Scalars['Float']>;
};

export type CreateFullCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  allow?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  fittingId?: InputMaybe<Scalars['Float']>;
  groupId?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type CreateTechPackInput = {
  collectionId?: InputMaybe<Scalars['Float']>;
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  fit?: InputMaybe<Scalars['String']>;
  garmentFinish?: InputMaybe<Scalars['String']>;
  hasBeenFit?: InputMaybe<Scalars['Boolean']>;
  isReady?: InputMaybe<Scalars['Boolean']>;
  mainMaterial?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patternMaker?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  sizeRanges?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type CreateUserEmailInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation?: InputMaybe<Scalars['String']>;
};

export type FindAllCollectionsInput = {
  cursor?: InputMaybe<CollectionCursorInput>;
  orderBy?: InputMaybe<CollectionOrderByInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<CollectionWhereInput>;
};

export type FindAllCompaniesInput = {
  cursor?: InputMaybe<CompanyCursorInput>;
  orderBy?: InputMaybe<CompanyOrderByInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type FindAllFittingsInput = {
  cursor?: InputMaybe<FittingCursorInput>;
  orderBy?: InputMaybe<FittingOrderByInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<FittingWhereInput>;
};

export type FindAllFittingsTechPacksInput = {
  cursor?: InputMaybe<FittingsTechPackCursorInput>;
  orderBy?: InputMaybe<FittingsTechPackOrderByInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<FittingsTechPackWhereInput>;
};

export type FindAllFullCalendarEventsInput = {
  cursor?: InputMaybe<FullCalendarEventCursorInput>;
  orderBy?: InputMaybe<FullCalendarEventOrderByInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<FullCalendarEventWhereInput>;
};

export type FindAllTechPacksInput = {
  cursor?: InputMaybe<TechPackCursorInput>;
  orderBy?: InputMaybe<TechPackOrderByInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<TechPackWhereInput>;
};

export type FindAllUsersInput = {
  cursor?: InputMaybe<UserCursor>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<UserWhereInput>;
};

export type FindOneCollectionInput = {
  id?: InputMaybe<Scalars['Float']>;
};

export type FindOneCompanyInput = {
  id?: InputMaybe<Scalars['Float']>;
};

export type FindOneFittingInput = {
  id?: InputMaybe<Scalars['Float']>;
};

export type FindOneFittingsTechPackInput = {
  id?: InputMaybe<Scalars['Float']>;
};

export type FindOneFullCalendarEventInput = {
  id?: InputMaybe<Scalars['Float']>;
};

export type FindOneTechPackInput = {
  id?: InputMaybe<Scalars['Float']>;
};

export type FindOneUserInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
};

export type Fitting = {
  __typename?: 'Fitting';
  calendarEvents?: Maybe<Array<FullCalendarEvent>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  techPacks?: Maybe<Array<FittingsTechPack>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Float']>;
};

export type FittingCursorInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type FittingInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type FittingOrderByInput = {
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FittingWhereInput = {
  AND?: InputMaybe<Array<FittingInput>>;
  NOT?: InputMaybe<Array<FittingInput>>;
  OR?: InputMaybe<Array<FittingInput>>;
  companyId?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type FittingsTechPack = {
  __typename?: 'FittingsTechPack';
  createdAt?: Maybe<Scalars['DateTime']>;
  fitting?: Maybe<Fitting>;
  fittingId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  techPack?: Maybe<TechPack>;
  techPackId?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FittingsTechPackCursorInput = {
  fittingId?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  techPackId?: InputMaybe<Scalars['Float']>;
};

export type FittingsTechPackInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fittingId?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  techPackId?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FittingsTechPackOrderByInput = {
  createdAt?: InputMaybe<Scalars['String']>;
  fittingId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  techPackId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type FittingsTechPackWhereInput = {
  AND?: InputMaybe<Array<FittingsTechPackInput>>;
  NOT?: InputMaybe<Array<FittingsTechPackInput>>;
  OR?: InputMaybe<Array<FittingsTechPackInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fittingId?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  techPackId?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FullCalendarEvent = {
  __typename?: 'FullCalendarEvent';
  allDay?: Maybe<Scalars['Boolean']>;
  allow?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  fitting?: Maybe<Fitting>;
  fittingId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Float']>;
};

export type FullCalendarEventCursorInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  allow?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  fittingId?: InputMaybe<Scalars['Float']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type FullCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  allow?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  fittingId?: InputMaybe<Scalars['Float']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type FullCalendarEventOrderByInput = {
  allDay?: InputMaybe<Scalars['String']>;
  allow?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['String']>;
  fittingId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FullCalendarEventWhereInput = {
  AND?: InputMaybe<Array<FullCalendarEventInput>>;
  NOT?: InputMaybe<Array<FullCalendarEventInput>>;
  OR?: InputMaybe<Array<FullCalendarEventInput>>;
  allDay?: InputMaybe<Scalars['Boolean']>;
  allow?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  fittingId?: InputMaybe<Scalars['Float']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type LogOutResponse = {
  __typename?: 'LogOutResponse';
  isAuthenticated: Scalars['Boolean'];
  userId: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCollection: Collection;
  createCompany: Company;
  createFitting: Fitting;
  createFittingsTechPack: FittingsTechPack;
  createFullCalendarEvent: FullCalendarEvent;
  createTechPack: TechPack;
  deleteCollection: Collection;
  deleteCompany: Company;
  deleteFitting: Fitting;
  deleteFittingsTechPack: FittingsTechPack;
  deleteFullCalendarEvent: FullCalendarEvent;
  deleteTechPack: TechPack;
  deleteUser: User;
  logOut: LogOutResponse;
  login: SessionResponse;
  signUp: SessionResponse;
  updateCollection: Collection;
  updateCompany: Company;
  updateFitting: Fitting;
  updateFittingsTechPack: FittingsTechPack;
  updateFullCalendarEvent: FullCalendarEvent;
  updateTechPack: TechPack;
  updateUser: User;
};


export type MutationCreateCollectionArgs = {
  createCollectionInput: CreateCollectionInput;
};


export type MutationCreateCompanyArgs = {
  createCompanyInput: CreateCompanyInput;
};


export type MutationCreateFittingArgs = {
  createFittingInput: CreateFittingInput;
};


export type MutationCreateFittingsTechPackArgs = {
  createFittingsTechPackInput: CreateFittingsTechPackInput;
};


export type MutationCreateFullCalendarEventArgs = {
  createFullCalendarEventInput: CreateFullCalendarEventInput;
};


export type MutationCreateTechPackArgs = {
  createTechPackInput: CreateTechPackInput;
};


export type MutationDeleteCollectionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFittingArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFittingsTechPackArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFullCalendarEventArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTechPackArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationLogOutArgs = {
  userId: Scalars['Float'];
};


export type MutationLoginArgs = {
  sessionInput: SessionInput;
};


export type MutationSignUpArgs = {
  createUserEmailInput: CreateUserEmailInput;
};


export type MutationUpdateCollectionArgs = {
  id: Scalars['Int'];
  updateCollectionInput: UpdateCollectionInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['Int'];
  updateCompanyInput: UpdateCompanyInput;
};


export type MutationUpdateFittingArgs = {
  id: Scalars['Int'];
  updateFittingInput: UpdateFittingInput;
};


export type MutationUpdateFittingsTechPackArgs = {
  id: Scalars['Int'];
  updateFittingsTechPackInput: UpdateFittingsTechPackInput;
};


export type MutationUpdateFullCalendarEventArgs = {
  id: Scalars['Int'];
  updateFullCalendarEventInput: UpdateFullCalendarEventInput;
};


export type MutationUpdateTechPackArgs = {
  id: Scalars['Int'];
  updateTechPackInput: UpdateTechPackInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  updateUserInput: UpdateUserInput;
};

export type Query = {
  __typename?: 'Query';
  currentUser: SessionResponse;
  findAllCollections: Array<Collection>;
  findAllCompanies: Array<Company>;
  findAllFittings: Array<Fitting>;
  findAllFittingsTechPacks: Array<FittingsTechPack>;
  findAllFullCalendarEvents: Array<FullCalendarEvent>;
  findAllTechPacks: Array<TechPack>;
  findAllUsers: Array<User>;
  findOneCollection?: Maybe<Collection>;
  findOneCompany?: Maybe<Company>;
  findOneFitting?: Maybe<Fitting>;
  findOneFittingsTechPack?: Maybe<FittingsTechPack>;
  findOneFullCalendarEvent?: Maybe<FullCalendarEvent>;
  findOneTechPack?: Maybe<TechPack>;
  findOneUser?: Maybe<User>;
};


export type QueryFindAllCollectionsArgs = {
  findAllCollectionsInput: FindAllCollectionsInput;
};


export type QueryFindAllCompaniesArgs = {
  findAllCompaniesInput: FindAllCompaniesInput;
};


export type QueryFindAllFittingsArgs = {
  findAllFittingsInput: FindAllFittingsInput;
};


export type QueryFindAllFittingsTechPacksArgs = {
  findAllFittingsTechPacksInput: FindAllFittingsTechPacksInput;
};


export type QueryFindAllFullCalendarEventsArgs = {
  findAllFullCalendarEventsInput: FindAllFullCalendarEventsInput;
};


export type QueryFindAllTechPacksArgs = {
  findAllTechPacksInput: FindAllTechPacksInput;
};


export type QueryFindAllUsersArgs = {
  findAllUsersInput: FindAllUsersInput;
};


export type QueryFindOneCollectionArgs = {
  findOneCollectionInput: FindOneCollectionInput;
};


export type QueryFindOneCompanyArgs = {
  findOneCompanyInput: FindOneCompanyInput;
};


export type QueryFindOneFittingArgs = {
  findOneFittingInput: FindOneFittingInput;
};


export type QueryFindOneFittingsTechPackArgs = {
  findOneFittingsTechPackInput: FindOneFittingsTechPackInput;
};


export type QueryFindOneFullCalendarEventArgs = {
  findOneFullCalendarEventInput: FindOneFullCalendarEventInput;
};


export type QueryFindOneTechPackArgs = {
  findOneTechPackInput: FindOneTechPackInput;
};


export type QueryFindOneUserArgs = {
  findOneUserInput: FindOneUserInput;
};

export type SessionInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SessionResponse = {
  __typename?: 'SessionResponse';
  isAuthenticated: Scalars['Boolean'];
  user: User;
};

export type TechPack = {
  __typename?: 'TechPack';
  collection?: Maybe<Collection>;
  collectionId?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Float']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  fit?: Maybe<Scalars['String']>;
  garmentFinish?: Maybe<Scalars['String']>;
  hasBeenFit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  isReady?: Maybe<Scalars['Boolean']>;
  mainMaterial?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  patternMaker?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  sizeRanges?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Float']>;
};

export type TechPackCursorInput = {
  collectionId?: InputMaybe<Scalars['Float']>;
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fit?: InputMaybe<Scalars['String']>;
  garmentFinish?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  mainMaterial?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patternMaker?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  sizeRanges?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type TechPackInput = {
  collectionId?: InputMaybe<Scalars['Float']>;
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  fit?: InputMaybe<Scalars['String']>;
  garmentFinish?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  mainMaterial?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patternMaker?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  sizeRanges?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type TechPackOrderByInput = {
  collectionId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fit?: InputMaybe<Scalars['String']>;
  garmentFinish?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mainMaterial?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patternMaker?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  sizeRanges?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type TechPackWhereInput = {
  AND?: InputMaybe<Array<TechPackInput>>;
  NOT?: InputMaybe<Array<TechPackInput>>;
  OR?: InputMaybe<Array<TechPackInput>>;
  collectionId?: InputMaybe<Scalars['Float']>;
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  fit?: InputMaybe<Scalars['String']>;
  garmentFinish?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  mainMaterial?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patternMaker?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  sizeRanges?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type UpdateCollectionInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isReady?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type UpdateCompanyInput = {
  contactNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logoUrls?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateFittingInput = {
  companyId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type UpdateFittingsTechPackInput = {
  fittingId?: InputMaybe<Scalars['Float']>;
  techPackId?: InputMaybe<Scalars['Float']>;
};

export type UpdateFullCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  allow?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  fittingId?: InputMaybe<Scalars['Float']>;
  groupId?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type UpdateTechPackInput = {
  collectionId?: InputMaybe<Scalars['Float']>;
  companyId?: InputMaybe<Scalars['Float']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  fit?: InputMaybe<Scalars['String']>;
  garmentFinish?: InputMaybe<Scalars['String']>;
  hasBeenFit?: InputMaybe<Scalars['Boolean']>;
  isReady?: InputMaybe<Scalars['Boolean']>;
  mainMaterial?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patternMaker?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  sizeRanges?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  collections?: Maybe<Array<Collection>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCursor = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
};

export type UserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserOrderByInput = {
  createdAt?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserInput>>;
  NOT?: InputMaybe<Array<UserInput>>;
  OR?: InputMaybe<Array<UserInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FindAllCollectionsQueryVariables = Exact<{
  findAllCollectionsInput: FindAllCollectionsInput;
}>;


export type FindAllCollectionsQuery = { __typename?: 'Query', findAllCollections: Array<{ __typename?: 'Collection', id?: number | null, name?: string | null, coverImageUrl?: string | null, isArchived?: boolean | null, userId?: number | null, isReady?: boolean | null, deadline?: any | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', id?: number | null, email?: string | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null } | null }> };

export type FindAllCollectionsWithTechPacksQueryVariables = Exact<{
  findAllCollectionsInput: FindAllCollectionsInput;
}>;


export type FindAllCollectionsWithTechPacksQuery = { __typename?: 'Query', findAllCollections: Array<{ __typename?: 'Collection', id?: number | null, name?: string | null, coverImageUrl?: string | null, isArchived?: boolean | null, userId?: number | null, isReady?: boolean | null, deadline?: any | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', id?: number | null, email?: string | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null } | null, techPacks?: Array<{ __typename?: 'TechPack', id?: number | null, name?: string | null, modelNumber?: string | null, description?: string | null, season?: string | null, productType?: string | null, supplier?: string | null, mainMaterial?: string | null, sizeRanges?: string | null, garmentFinish?: string | null, fit?: string | null, patternMaker?: string | null, coverImageUrl?: string | null, userId?: number | null, collectionId?: number | null, createdAt?: any | null, updatedAt?: any | null }> | null }> };

export type FindOneCollectionQueryVariables = Exact<{
  findOneCollectionInput: FindOneCollectionInput;
}>;


export type FindOneCollectionQuery = { __typename?: 'Query', findOneCollection?: { __typename?: 'Collection', id?: number | null, name?: string | null, coverImageUrl?: string | null, isArchived?: boolean | null, userId?: number | null, isReady?: boolean | null, deadline?: any | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', id?: number | null, email?: string | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null };

export type FindOneCollectionWithTechPacksQueryVariables = Exact<{
  findOneCollectionInput: FindOneCollectionInput;
}>;


export type FindOneCollectionWithTechPacksQuery = { __typename?: 'Query', findOneCollection?: { __typename?: 'Collection', id?: number | null, name?: string | null, coverImageUrl?: string | null, isArchived?: boolean | null, userId?: number | null, isReady?: boolean | null, deadline?: any | null, createdAt?: any | null, updatedAt?: any | null, techPacks?: Array<{ __typename?: 'TechPack', id?: number | null, name?: string | null, modelNumber?: string | null, description?: string | null, season?: string | null, productType?: string | null, supplier?: string | null, mainMaterial?: string | null, sizeRanges?: string | null, garmentFinish?: string | null, fit?: string | null, patternMaker?: string | null, coverImageUrl?: string | null, userId?: number | null, collectionId?: number | null, createdAt?: any | null, updatedAt?: any | null }> | null, user?: { __typename?: 'User', id?: number | null, email?: string | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null };

export type FindAllTechPacksQueryVariables = Exact<{
  findAllTechPacksInput: FindAllTechPacksInput;
}>;


export type FindAllTechPacksQuery = { __typename?: 'Query', findAllTechPacks: Array<{ __typename?: 'TechPack', id?: number | null, name?: string | null, modelNumber?: string | null, description?: string | null, season?: string | null, productType?: string | null, supplier?: string | null, mainMaterial?: string | null, sizeRanges?: string | null, garmentFinish?: string | null, fit?: string | null, patternMaker?: string | null, coverImageUrl?: string | null, userId?: number | null, collectionId?: number | null, isReady?: boolean | null, deadline?: any | null, hasBeenFit?: boolean | null, createdAt?: any | null, updatedAt?: any | null }> };

export type CreateFittingMutationVariables = Exact<{
  createFittingInput: CreateFittingInput;
}>;


export type CreateFittingMutation = { __typename?: 'Mutation', createFitting: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, createdAt?: any | null, updatedAt?: any | null } };

export type DeleteFittingMutationVariables = Exact<{
  deleteFittingId: Scalars['Int'];
}>;


export type DeleteFittingMutation = { __typename?: 'Mutation', deleteFitting: { __typename?: 'Fitting', id?: number | null } };

export type FindAllFittingsQueryVariables = Exact<{
  findAllFittingsInput: FindAllFittingsInput;
}>;


export type FindAllFittingsQuery = { __typename?: 'Query', findAllFittings: Array<{ __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, companyId?: number | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', id?: number | null } | null, company?: { __typename?: 'Company', id?: number | null } | null, techPacks?: Array<{ __typename?: 'FittingsTechPack', techPack?: { __typename?: 'TechPack', id?: number | null, name?: string | null, modelNumber?: string | null, description?: string | null, season?: string | null, productType?: string | null, supplier?: string | null, mainMaterial?: string | null, sizeRanges?: string | null, garmentFinish?: string | null, fit?: string | null, patternMaker?: string | null, coverImageUrl?: string | null, userId?: number | null, companyId?: number | null, collectionId?: number | null, isReady?: boolean | null, hasBeenFit?: boolean | null, deadline?: any | null, createdAt?: any | null, updatedAt?: any | null } | null }> | null }> };

export type FindOneFittingQueryVariables = Exact<{
  findOneFittingInput: FindOneFittingInput;
}>;


export type FindOneFittingQuery = { __typename?: 'Query', findOneFitting?: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, companyId?: number | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', id?: number | null } | null, company?: { __typename?: 'Company', id?: number | null } | null, techPacks?: Array<{ __typename?: 'FittingsTechPack', techPack?: { __typename?: 'TechPack', id?: number | null, name?: string | null, modelNumber?: string | null, description?: string | null, season?: string | null, productType?: string | null, supplier?: string | null, mainMaterial?: string | null, sizeRanges?: string | null, garmentFinish?: string | null, fit?: string | null, patternMaker?: string | null, coverImageUrl?: string | null, userId?: number | null, companyId?: number | null, collectionId?: number | null, isReady?: boolean | null, hasBeenFit?: boolean | null, deadline?: any | null, createdAt?: any | null, updatedAt?: any | null } | null }> | null } | null };

export type UpdateFittingMutationVariables = Exact<{
  updateFittingId: Scalars['Int'];
  updateFittingInput: UpdateFittingInput;
}>;


export type UpdateFittingMutation = { __typename?: 'Mutation', updateFitting: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, createdAt?: any | null, updatedAt?: any | null } };

export type CreateFullCalendarEventMutationVariables = Exact<{
  createFullCalendarEventInput: CreateFullCalendarEventInput;
}>;


export type CreateFullCalendarEventMutation = { __typename?: 'Mutation', createFullCalendarEvent: { __typename?: 'FullCalendarEvent', id?: number | null, title?: string | null, description?: string | null, color?: string | null, allDay?: boolean | null, start?: any | null, end?: any | null, groupId?: string | null, allow?: boolean | null, userId?: number | null, companyId?: number | null, fittingId?: number | null, createdAt?: any | null, updatedAt?: any | null, fitting?: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, companyId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null } };

export type DeleteFullCalendarEventMutationVariables = Exact<{
  deleteFullCalendarEventId: Scalars['Int'];
}>;


export type DeleteFullCalendarEventMutation = { __typename?: 'Mutation', deleteFullCalendarEvent: { __typename?: 'FullCalendarEvent', id?: number | null, title?: string | null, description?: string | null, color?: string | null, allDay?: boolean | null, start?: any | null, end?: any | null, groupId?: string | null, allow?: boolean | null, userId?: number | null, companyId?: number | null, fittingId?: number | null, createdAt?: any | null, updatedAt?: any | null, fitting?: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, companyId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null } };

export type FindAllFullCalendarEventsQueryVariables = Exact<{
  findAllFullCalendarEventsInput: FindAllFullCalendarEventsInput;
}>;


export type FindAllFullCalendarEventsQuery = { __typename?: 'Query', findAllFullCalendarEvents: Array<{ __typename?: 'FullCalendarEvent', id?: number | null, title?: string | null, description?: string | null, color?: string | null, allDay?: boolean | null, start?: any | null, end?: any | null, groupId?: string | null, allow?: boolean | null, userId?: number | null, companyId?: number | null, fittingId?: number | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', id?: number | null } | null, company?: { __typename?: 'Company', id?: number | null } | null, fitting?: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, companyId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null }> };

export type FindOneFullCalendarEventQueryVariables = Exact<{
  findOneFullCalendarEventInput: FindOneFullCalendarEventInput;
}>;


export type FindOneFullCalendarEventQuery = { __typename?: 'Query', findOneFullCalendarEvent?: { __typename?: 'FullCalendarEvent', id?: number | null, title?: string | null, description?: string | null, color?: string | null, allDay?: boolean | null, start?: any | null, end?: any | null, groupId?: string | null, allow?: boolean | null, userId?: number | null, companyId?: number | null, fittingId?: number | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', id?: number | null } | null, company?: { __typename?: 'Company', id?: number | null } | null, fitting?: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, companyId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null } | null };

export type UpdateFullCalendarEventMutationVariables = Exact<{
  updateFullCalendarEventId: Scalars['Int'];
  updateFullCalendarEventInput: UpdateFullCalendarEventInput;
}>;


export type UpdateFullCalendarEventMutation = { __typename?: 'Mutation', updateFullCalendarEvent: { __typename?: 'FullCalendarEvent', id?: number | null, title?: string | null, description?: string | null, color?: string | null, allDay?: boolean | null, start?: any | null, end?: any | null, groupId?: string | null, allow?: boolean | null, userId?: number | null, companyId?: number | null, fittingId?: number | null, createdAt?: any | null, updatedAt?: any | null, fitting?: { __typename?: 'Fitting', id?: number | null, name?: string | null, description?: string | null, userId?: number | null, companyId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null } };

export type FindOneTechPackQueryVariables = Exact<{
  findOneTechPackInput: FindOneTechPackInput;
}>;


export type FindOneTechPackQuery = { __typename?: 'Query', findOneTechPack?: { __typename?: 'TechPack', id?: number | null, name?: string | null, modelNumber?: string | null, description?: string | null, season?: string | null, productType?: string | null, supplier?: string | null, mainMaterial?: string | null, sizeRanges?: string | null, garmentFinish?: string | null, fit?: string | null, patternMaker?: string | null, coverImageUrl?: string | null, isReady?: boolean | null, deadline?: any | null, hasBeenFit?: boolean | null, userId?: number | null, collectionId?: number | null, createdAt?: any | null, updatedAt?: any | null, collection?: { __typename?: 'Collection', id?: number | null, name?: string | null, coverImageUrl?: string | null, isArchived?: boolean | null, userId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null, company?: { __typename?: 'Company', id?: number | null, name?: string | null, description?: string | null, contactNumber?: string | null, type?: string | null, logoUrls?: any | null, createdAt?: any | null, updatedAt?: any | null } | null } | null };


export const FindAllCollectionsDocument = `
    query FindAllCollections($findAllCollectionsInput: FindAllCollectionsInput!) {
  findAllCollections(findAllCollectionsInput: $findAllCollectionsInput) {
    id
    name
    coverImageUrl
    isArchived
    userId
    isReady
    deadline
    createdAt
    updatedAt
    user {
      id
      email
      role
      createdAt
      updatedAt
    }
  }
}
    `;
export const FindAllCollectionsWithTechPacksDocument = `
    query FindAllCollectionsWithTechPacks($findAllCollectionsInput: FindAllCollectionsInput!) {
  findAllCollections(findAllCollectionsInput: $findAllCollectionsInput) {
    id
    name
    coverImageUrl
    isArchived
    userId
    isReady
    deadline
    createdAt
    updatedAt
    user {
      id
      email
      role
      createdAt
      updatedAt
    }
    techPacks {
      id
      name
      modelNumber
      description
      season
      productType
      supplier
      mainMaterial
      sizeRanges
      garmentFinish
      fit
      patternMaker
      coverImageUrl
      userId
      collectionId
      createdAt
      updatedAt
    }
  }
}
    `;
export const FindOneCollectionDocument = `
    query FindOneCollection($findOneCollectionInput: FindOneCollectionInput!) {
  findOneCollection(findOneCollectionInput: $findOneCollectionInput) {
    id
    name
    coverImageUrl
    isArchived
    userId
    isReady
    deadline
    createdAt
    updatedAt
    user {
      id
      email
      role
      createdAt
      updatedAt
    }
  }
}
    `;
export const FindOneCollectionWithTechPacksDocument = `
    query FindOneCollectionWithTechPacks($findOneCollectionInput: FindOneCollectionInput!) {
  findOneCollection(findOneCollectionInput: $findOneCollectionInput) {
    id
    name
    coverImageUrl
    isArchived
    userId
    isReady
    deadline
    createdAt
    updatedAt
    techPacks {
      id
      name
      modelNumber
      description
      season
      productType
      supplier
      mainMaterial
      sizeRanges
      garmentFinish
      fit
      patternMaker
      coverImageUrl
      userId
      collectionId
      createdAt
      updatedAt
    }
    user {
      id
      email
      role
      createdAt
      updatedAt
    }
  }
}
    `;
export const FindAllTechPacksDocument = `
    query FindAllTechPacks($findAllTechPacksInput: FindAllTechPacksInput!) {
  findAllTechPacks(findAllTechPacksInput: $findAllTechPacksInput) {
    id
    name
    modelNumber
    description
    season
    productType
    supplier
    mainMaterial
    sizeRanges
    garmentFinish
    fit
    patternMaker
    coverImageUrl
    userId
    collectionId
    isReady
    deadline
    hasBeenFit
    createdAt
    updatedAt
  }
}
    `;
export const CreateFittingDocument = `
    mutation CreateFitting($createFittingInput: CreateFittingInput!) {
  createFitting(createFittingInput: $createFittingInput) {
    id
    name
    description
    userId
    createdAt
    updatedAt
  }
}
    `;
export const DeleteFittingDocument = `
    mutation DeleteFitting($deleteFittingId: Int!) {
  deleteFitting(id: $deleteFittingId) {
    id
  }
}
    `;
export const FindAllFittingsDocument = `
    query FindAllFittings($findAllFittingsInput: FindAllFittingsInput!) {
  findAllFittings(findAllFittingsInput: $findAllFittingsInput) {
    id
    name
    description
    userId
    companyId
    createdAt
    updatedAt
    user {
      id
    }
    company {
      id
    }
    techPacks {
      techPack {
        id
        name
        modelNumber
        description
        season
        productType
        supplier
        mainMaterial
        sizeRanges
        garmentFinish
        fit
        patternMaker
        coverImageUrl
        userId
        companyId
        collectionId
        isReady
        hasBeenFit
        deadline
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const FindOneFittingDocument = `
    query FindOneFitting($findOneFittingInput: FindOneFittingInput!) {
  findOneFitting(findOneFittingInput: $findOneFittingInput) {
    id
    name
    description
    userId
    companyId
    createdAt
    updatedAt
    user {
      id
    }
    company {
      id
    }
    techPacks {
      techPack {
        id
        name
        modelNumber
        description
        season
        productType
        supplier
        mainMaterial
        sizeRanges
        garmentFinish
        fit
        patternMaker
        coverImageUrl
        userId
        companyId
        collectionId
        isReady
        hasBeenFit
        deadline
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const UpdateFittingDocument = `
    mutation UpdateFitting($updateFittingId: Int!, $updateFittingInput: UpdateFittingInput!) {
  updateFitting(id: $updateFittingId, updateFittingInput: $updateFittingInput) {
    id
    name
    description
    userId
    createdAt
    updatedAt
  }
}
    `;
export const CreateFullCalendarEventDocument = `
    mutation CreateFullCalendarEvent($createFullCalendarEventInput: CreateFullCalendarEventInput!) {
  createFullCalendarEvent(
    createFullCalendarEventInput: $createFullCalendarEventInput
  ) {
    id
    title
    description
    color
    allDay
    start
    end
    groupId
    allow
    userId
    companyId
    fittingId
    createdAt
    updatedAt
    fitting {
      id
      name
      description
      userId
      companyId
      createdAt
      updatedAt
    }
  }
}
    `;
export const DeleteFullCalendarEventDocument = `
    mutation DeleteFullCalendarEvent($deleteFullCalendarEventId: Int!) {
  deleteFullCalendarEvent(id: $deleteFullCalendarEventId) {
    id
    title
    description
    color
    allDay
    start
    end
    groupId
    allow
    userId
    companyId
    fittingId
    createdAt
    updatedAt
    fitting {
      id
      name
      description
      userId
      companyId
      createdAt
      updatedAt
    }
  }
}
    `;
export const FindAllFullCalendarEventsDocument = `
    query FindAllFullCalendarEvents($findAllFullCalendarEventsInput: FindAllFullCalendarEventsInput!) {
  findAllFullCalendarEvents(
    findAllFullCalendarEventsInput: $findAllFullCalendarEventsInput
  ) {
    id
    title
    description
    color
    allDay
    start
    end
    groupId
    allow
    userId
    companyId
    fittingId
    createdAt
    updatedAt
    user {
      id
    }
    company {
      id
    }
    fitting {
      id
      name
      description
      userId
      companyId
      createdAt
      updatedAt
    }
  }
}
    `;
export const FindOneFullCalendarEventDocument = `
    query FindOneFullCalendarEvent($findOneFullCalendarEventInput: FindOneFullCalendarEventInput!) {
  findOneFullCalendarEvent(
    findOneFullCalendarEventInput: $findOneFullCalendarEventInput
  ) {
    id
    title
    description
    color
    allDay
    start
    end
    groupId
    allow
    userId
    companyId
    fittingId
    createdAt
    updatedAt
    user {
      id
    }
    company {
      id
    }
    fitting {
      id
      name
      description
      userId
      companyId
      createdAt
      updatedAt
    }
  }
}
    `;
export const UpdateFullCalendarEventDocument = `
    mutation UpdateFullCalendarEvent($updateFullCalendarEventId: Int!, $updateFullCalendarEventInput: UpdateFullCalendarEventInput!) {
  updateFullCalendarEvent(
    id: $updateFullCalendarEventId
    updateFullCalendarEventInput: $updateFullCalendarEventInput
  ) {
    id
    title
    description
    color
    allDay
    start
    end
    groupId
    allow
    userId
    companyId
    fittingId
    createdAt
    updatedAt
    fitting {
      id
      name
      description
      userId
      companyId
      createdAt
      updatedAt
    }
  }
}
    `;
export const FindOneTechPackDocument = `
    query FindOneTechPack($findOneTechPackInput: FindOneTechPackInput!) {
  findOneTechPack(findOneTechPackInput: $findOneTechPackInput) {
    id
    name
    modelNumber
    description
    season
    productType
    supplier
    mainMaterial
    sizeRanges
    garmentFinish
    fit
    patternMaker
    coverImageUrl
    isReady
    deadline
    hasBeenFit
    userId
    collectionId
    createdAt
    updatedAt
    collection {
      id
      name
      coverImageUrl
      isArchived
      userId
      createdAt
      updatedAt
    }
    company {
      id
      name
      description
      contactNumber
      type
      logoUrls
      createdAt
      updatedAt
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    FindAllCollections: build.query<FindAllCollectionsQuery, FindAllCollectionsQueryVariables>({
      query: (variables) => ({ document: FindAllCollectionsDocument, variables })
    }),
    FindAllCollectionsWithTechPacks: build.query<FindAllCollectionsWithTechPacksQuery, FindAllCollectionsWithTechPacksQueryVariables>({
      query: (variables) => ({ document: FindAllCollectionsWithTechPacksDocument, variables })
    }),
    FindOneCollection: build.query<FindOneCollectionQuery, FindOneCollectionQueryVariables>({
      query: (variables) => ({ document: FindOneCollectionDocument, variables })
    }),
    FindOneCollectionWithTechPacks: build.query<FindOneCollectionWithTechPacksQuery, FindOneCollectionWithTechPacksQueryVariables>({
      query: (variables) => ({ document: FindOneCollectionWithTechPacksDocument, variables })
    }),
    FindAllTechPacks: build.query<FindAllTechPacksQuery, FindAllTechPacksQueryVariables>({
      query: (variables) => ({ document: FindAllTechPacksDocument, variables })
    }),
    CreateFitting: build.mutation<CreateFittingMutation, CreateFittingMutationVariables>({
      query: (variables) => ({ document: CreateFittingDocument, variables })
    }),
    DeleteFitting: build.mutation<DeleteFittingMutation, DeleteFittingMutationVariables>({
      query: (variables) => ({ document: DeleteFittingDocument, variables })
    }),
    FindAllFittings: build.query<FindAllFittingsQuery, FindAllFittingsQueryVariables>({
      query: (variables) => ({ document: FindAllFittingsDocument, variables })
    }),
    FindOneFitting: build.query<FindOneFittingQuery, FindOneFittingQueryVariables>({
      query: (variables) => ({ document: FindOneFittingDocument, variables })
    }),
    UpdateFitting: build.mutation<UpdateFittingMutation, UpdateFittingMutationVariables>({
      query: (variables) => ({ document: UpdateFittingDocument, variables })
    }),
    CreateFullCalendarEvent: build.mutation<CreateFullCalendarEventMutation, CreateFullCalendarEventMutationVariables>({
      query: (variables) => ({ document: CreateFullCalendarEventDocument, variables })
    }),
    DeleteFullCalendarEvent: build.mutation<DeleteFullCalendarEventMutation, DeleteFullCalendarEventMutationVariables>({
      query: (variables) => ({ document: DeleteFullCalendarEventDocument, variables })
    }),
    FindAllFullCalendarEvents: build.query<FindAllFullCalendarEventsQuery, FindAllFullCalendarEventsQueryVariables>({
      query: (variables) => ({ document: FindAllFullCalendarEventsDocument, variables })
    }),
    FindOneFullCalendarEvent: build.query<FindOneFullCalendarEventQuery, FindOneFullCalendarEventQueryVariables>({
      query: (variables) => ({ document: FindOneFullCalendarEventDocument, variables })
    }),
    UpdateFullCalendarEvent: build.mutation<UpdateFullCalendarEventMutation, UpdateFullCalendarEventMutationVariables>({
      query: (variables) => ({ document: UpdateFullCalendarEventDocument, variables })
    }),
    FindOneTechPack: build.query<FindOneTechPackQuery, FindOneTechPackQueryVariables>({
      query: (variables) => ({ document: FindOneTechPackDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFindAllCollectionsQuery, useLazyFindAllCollectionsQuery, useFindAllCollectionsWithTechPacksQuery, useLazyFindAllCollectionsWithTechPacksQuery, useFindOneCollectionQuery, useLazyFindOneCollectionQuery, useFindOneCollectionWithTechPacksQuery, useLazyFindOneCollectionWithTechPacksQuery, useFindAllTechPacksQuery, useLazyFindAllTechPacksQuery, useCreateFittingMutation, useDeleteFittingMutation, useFindAllFittingsQuery, useLazyFindAllFittingsQuery, useFindOneFittingQuery, useLazyFindOneFittingQuery, useUpdateFittingMutation, useCreateFullCalendarEventMutation, useDeleteFullCalendarEventMutation, useFindAllFullCalendarEventsQuery, useLazyFindAllFullCalendarEventsQuery, useFindOneFullCalendarEventQuery, useLazyFindOneFullCalendarEventQuery, useUpdateFullCalendarEventMutation, useFindOneTechPackQuery, useLazyFindOneTechPackQuery } = injectedRtkApi;


import { Sort } from '../../../../../common/enums';
import { ITechPack } from '../../../../../common/interfaces';

interface IOptions {
	sortOrder: Sort;
	techPacks: ITechPack[];
}

const sortByDealine = ({ techPacks, sortOrder }: IOptions) => {
	const sortedTechPacks = [...techPacks].sort(
		(techPackA: ITechPack, techPackB: ITechPack) => {
			let sort = 0;
			const techPackADeadline = new Date(
				techPackA.deadline as unknown as Date
			);
			const techPackBDeadline = new Date(
				techPackB.deadline as unknown as Date
			);
			const techPackPackADealineTimestamp = techPackADeadline.getTime();
			const techPackPackBDealineTimestamp = techPackBDeadline.getTime();

			if (sortOrder === Sort.ASC) {
				sort =
					techPackPackADealineTimestamp -
					techPackPackBDealineTimestamp;
			} else if (sortOrder === Sort.DESC) {
				sort =
					techPackPackBDealineTimestamp -
					techPackPackADealineTimestamp;
			}

			return sort;
		}
	);

	return sortedTechPacks;
};

export default sortByDealine;

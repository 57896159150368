import {
	checkForAtSignMentions,
	checkForCapitalizedNameMentions,
} from '.';
import { QueryMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin';

const getPossibleQueryMatch = (text: string): QueryMatch | null => {
	const match = checkForAtSignMentions(text, 1);
	return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
};

export default getPossibleQueryMatch;

import { IRow } from '../interfaces';

const rows: IRow[] = [
	{
		id: 1,
		pom: 'B600',
		description: 'Waist @ Top of WB',
		tolerance: 0.5,
		xs: 13,
		s: 15,
		m: 17,
		l: 19.5,
		xl: 22.5,
		notes: '',
	},
	{
		id: 2,
		pom: 'B610',
		description: 'Waistband Height',
		tolerance: 0.25,
		xs: 2,
		s: 2,
		m: 2,
		l: 2,
		xl: 2,
		notes: 'Closure is not placed properly and wbnd needs to be extended. See pics.',
	},
	{
		id: 3,
		pom: 'B615',
		description: 'Front Waist Drop',
		tolerance: 0.875,
		xs: 0.875,
		s: 0.875,
		m: 0.875,
		l: 0.875,
		xl: 0.875,
		notes: '',
	},
	{
		id: 4,
		pom: 'B620',
		description: 'Back Waist Drop',
		tolerance: 0.375,
		xs: 0.375,
		s: 0.375,
		m: 0.375,
		l: 0.375,
		xl: 0.375,
		notes: '',
	},
	{
		id: 5,
		pom: 'B651',
		description: 'Fly stitching Length',
		tolerance: 0.25,
		xs: 4,
		s: 4.5,
		m: 5,
		l: 5.5,
		xl: 6,
		notes: '',
	},
	{
		id: 6,
		pom: 'B670',
		description: 'Front Rise, incl wbnd',
		tolerance: 0.25,
		xs: 9,
		s: 10.25,
		m: 11.5,
		l: 13,
		xl: 16,
		notes: 'Reduce front rise to 9"',
	},
	{
		id: 7,
		pom: 'B675',
		description: 'Back Rise, incl wbnd',
		tolerance: 0.25,
		xs: 14.75,
		s: 16,
		m: 17.25,
		l: 18.75,
		xl: 21.75,
		notes: '',
	},
	{
		id: 8,
		pom: 'B705',
		description: 'Hip -2.5" up from crotch point',
		tolerance: 0.5,
		xs: 17,
		s: 19,
		m: 21,
		l: 23.5,
		xl: 26.5,
		notes: '',
	},
	{
		id: 9,
		pom: 'B710',
		description: 'Thigh -1" down from crotch',
		tolerance: 0.25,
		xs: 12,
		s: 13.25,
		m: 14.5,
		l: 16,
		xl: 19,
		notes: '',
	},
	{
		id: 10,
		pom: 'B715',
		description: 'Knee -14" down from crotch',
		tolerance: 0.25,
		xs: 7.75,
		s: 8.75,
		m: 9.75,
		l: 11,
		xl: 13.75,
		notes: '',
	},
	{
		id: 11,
		pom: 'B720',
		description: 'Leg Opening -Pants',
		tolerance: 0.25,
		xs: 6,
		s: 6.25,
		m: 6.5,
		l: 6.75,
		xl: 7,
		notes: '',
	},
	{
		id: 12,
		pom: 'B750',
		description: 'Inseam -Pants',
		tolerance: 0.25,
		xs: 28,
		s: 28,
		m: 28,
		l: 28,
		xl: 28,
		notes: 'Change inseam to 28"',
	},
];

export default rows;

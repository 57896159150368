import { MouseEvent } from 'react';
import { SortBy } from '../enums';
import { Sort } from '../../../../../common/enums';
import sortByDeadline from '../sortBy/sortByDealine';
import { ITechPack } from '../../../../../common/interfaces';

interface IOptions {
	sortOrder: Sort;
	techPacks: ITechPack[];
	setMenuAnchorEl: CallableFunction;
	setSelectedSort: CallableFunction;
	setSelectedTechPacks: CallableFunction | undefined;
}

const genHandleSortDeadlineClickASC = ({
	techPacks,
	sortOrder,
	setMenuAnchorEl,
	setSelectedSort,
	setSelectedTechPacks,
}: IOptions) => {
	return (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setMenuAnchorEl(null);
		setSelectedSort(`${SortBy.Deadline} (${sortOrder})`);

		const sortedTechPacks = sortByDeadline({
			techPacks,
			sortOrder,
		});

		setSelectedTechPacks && setSelectedTechPacks(sortedTechPacks);
	};
};

export default genHandleSortDeadlineClickASC;

import { $getRoot, $getSelection } from 'lexical';

const onChange = (editorState: any) => {
	editorState.read(() => {
		// Read the contents of the EditorState here.
		const root = $getRoot();
		const selection = $getSelection();

		console.log(root, selection);
	});
};

export default onChange;

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme) => ({
	sectionHeaderContainer: {
		position: 'sticky',
		top: '125px',
		zIndex: '5',
		backgroundColor: '#fff',
	},
	sectionHeader: {
		fontSize: '1.5rem',
		textAlign: 'center',
	},
}));

interface IProps {
	header: string;
}

const SectionHeader = ({ header = '' }: IProps) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.sectionHeaderContainer}>
			<Typography className={classes.sectionHeader}>{header}</Typography>
			<Divider />
		</Box>
	);
};

export default SectionHeader;

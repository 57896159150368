import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import { FiCheckCircle } from 'react-icons/fi';
import { AiOutlineSend } from 'react-icons/ai';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LexicalEditor from '../../components/LexicalEditor';

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
	},
	completeButtonContainer: {
		marginBottom: theme.spacing(3),
	},
	columnOne: {
		padding: theme.spacing(2),
	},
	columnTwo: {
		width: '500px',
		padding: theme.spacing(2),
	},
	taskDataContainer: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	taskName: {
		fontSize: '2rem',
		fontWeight: 'bold',
	},
	taskDescription: {
		marginTop: theme.spacing(2),
	},
	annotationDataContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	annotationImageContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
	},
	annotationImage: {
		width: '700px',
	},
	annotationContainer: {
		width: '700px',
		borderRadius: '10px',
		marginTop: theme.spacing(2),
		padding: theme.spacing(3),
		textAlign: 'center',
		border: `1px solid ${theme.palette.lightestGrey.main}`,
	},
	annotationTag: {
		color: theme.palette.secondary.main,
	},
	userContainer: {
		marginTop: theme.spacing(3),
	},
	usersHeader: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	userName: {},
	datesContainer: {
		marginTop: theme.spacing(3),
	},
	dateHeader: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	dueDate: {},
	createdDate: {},
	updatedDate: {},
	commentContainerContainer: {
		position: 'sticky',
		bottom: '0px',
		backgroundColor: '#fff',
		paddingBottom: theme.spacing(2),
	},
	commentContainer: {
		backgroundColor: '#fff',
		display: 'flex',
		width: '100%',
		borderRadius: '10px',
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		textAlign: 'center',
		boxShadow: theme.shadows[1],
		border: `1px solid ${theme.palette.lightestGrey.main}`,
	},
	lexicalEditorContainer: {
		width: '100%',
		backgroundColor: '#fff',
	},
	submitButtonContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		marginTop: '10px',
		marginLeft: '10px',
		height: '50px',
		cursor: 'pointer',
		// width: '100px',
		padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
		borderRadius: '10px',
		// borderTopRightRadius: '10px',
		// borderBottomRightRadius: '10px',
		placeSelf: 'flex-end',
		backgroundColor: theme.palette.secondary.main,
	},
	submitButton: {
		color: '#fff',
	},
	userCommentContainer: {
		display: 'flex',
		marginTop: theme.spacing(3),
	},
	userComment: {
		marginTop: theme.spacing(0),
	},
	userImageContainer: {},
	userImage: {
		width: '36px',
		height: '36px',
		borderRadius: '10px',
	},
	userCommentName: {
		fontWeight: 'bold',
	},
	commentTextContainer: {
		marginLeft: theme.spacing(2),
	},

	userCommentNameContainer: {
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
	},
	userCommentTime: {
		color: theme.palette.heatherGrey.main,
		marginLeft: theme.spacing(1),
	},
}));

const Task = () => {
	const { classes } = useStyles();

	return (
		<Container maxWidth="xl">
			<Box className={classes.container}>
				<Box className={classes.columnOne}>
					<Box className={classes.taskDataContainer}>
						<Box className={classes.completeButtonContainer}>
							<Button
								disableRipple
								type="submit"
								color="secondary"
								variant="contained"
								startIcon={<FiCheckCircle size={18} />}
							>
								Mark Complete
							</Button>
						</Box>
						<Box className={classes.taskName}>
							Let's do this thing..
						</Box>
						<Box className={classes.taskDescription}>
							Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
							Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
							Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
							Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
							Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
							Lorem Ipsum Lorem Ipsum Lorem Ipsum.
						</Box>
					</Box>
					<Divider />
					<Box className={classes.annotationDataContainer}>
						<Box className={classes.annotationImageContainer}>
							<img
								alt=""
								className={classes.annotationImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/tasks/1/annotation-1.png"
							/>
						</Box>
						<Box className={classes.annotationContainer}>
							<Typography>
								<span className={classes.annotationTag}>
									#B12345
								</span>{' '}
								let's replace with
								<span className={classes.annotationTag}>
									#B12347
								</span>
							</Typography>
						</Box>
					</Box>
					<Divider />
					{/* Comments */}
					<Box className={classes.userCommentContainer}>
						<Box className={classes.userImageContainer}>
							<img
								alt=""
								className={classes.userImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
							/>
						</Box>
						<Box className={classes.commentTextContainer}>
							<Box className={classes.userCommentNameContainer}>
								<Box className={classes.userCommentName}>
									Sarah Peterson
								</Box>
								<Box className={classes.userCommentTime}>
									10:58 PM
								</Box>
							</Box>
							<Box className={classes.userComment}>
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum
							</Box>
						</Box>
					</Box>
					<Box className={classes.userCommentContainer}>
						<Box className={classes.userImageContainer}>
							<img
								alt=""
								className={classes.userImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
							/>
						</Box>
						<Box className={classes.commentTextContainer}>
							<Box className={classes.userCommentNameContainer}>
								<Box className={classes.userCommentName}>
									Sarah Peterson
								</Box>
								<Box className={classes.userCommentTime}>
									10:58 PM
								</Box>
							</Box>
							<Box className={classes.userComment}>
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum
							</Box>
						</Box>
					</Box>
					<Box className={classes.userCommentContainer}>
						<Box className={classes.userImageContainer}>
							<img
								alt=""
								className={classes.userImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
							/>
						</Box>
						<Box className={classes.commentTextContainer}>
							<Box className={classes.userCommentNameContainer}>
								<Box className={classes.userCommentName}>
									Sarah Peterson
								</Box>
								<Box className={classes.userCommentTime}>
									10:58 PM
								</Box>
							</Box>
							<Box className={classes.userComment}>
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum
							</Box>
						</Box>
					</Box>
					<Box className={classes.userCommentContainer}>
						<Box className={classes.userImageContainer}>
							<img
								alt=""
								className={classes.userImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
							/>
						</Box>
						<Box className={classes.commentTextContainer}>
							<Box className={classes.userCommentNameContainer}>
								<Box className={classes.userCommentName}>
									Sarah Peterson
								</Box>
								<Box className={classes.userCommentTime}>
									10:58 PM
								</Box>
							</Box>
							<Box className={classes.userComment}>
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum
							</Box>
						</Box>
					</Box>
					<Box className={classes.userCommentContainer}>
						<Box className={classes.userImageContainer}>
							<img
								alt=""
								className={classes.userImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
							/>
						</Box>
						<Box className={classes.commentTextContainer}>
							<Box className={classes.userCommentNameContainer}>
								<Box className={classes.userCommentName}>
									Sarah Peterson
								</Box>
								<Box className={classes.userCommentTime}>
									10:58 PM
								</Box>
							</Box>
							<Box className={classes.userComment}>
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum
							</Box>
						</Box>
					</Box>
					<Box className={classes.userCommentContainer}>
						<Box className={classes.userImageContainer}>
							<img
								alt=""
								className={classes.userImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
							/>
						</Box>
						<Box className={classes.commentTextContainer}>
							<Box className={classes.userCommentNameContainer}>
								<Box className={classes.userCommentName}>
									Sarah Peterson
								</Box>
								<Box className={classes.userCommentTime}>
									10:58 PM
								</Box>
							</Box>
							<Box className={classes.userComment}>
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum
							</Box>
						</Box>
					</Box>
					<Box className={classes.userCommentContainer}>
						<Box className={classes.userImageContainer}>
							<img
								alt=""
								className={classes.userImage}
								src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif"
							/>
						</Box>
						<Box className={classes.commentTextContainer}>
							<Box className={classes.userCommentNameContainer}>
								<Box className={classes.userCommentName}>
									Sarah Peterson
								</Box>
								<Box className={classes.userCommentTime}>
									10:58 PM
								</Box>
							</Box>
							<Box className={classes.userComment}>
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
								Lorem Ipsum Lorem Ipsum
							</Box>
						</Box>
					</Box>
					{/* Comments */}
					<Box className={classes.commentContainerContainer}>
						<Box className={classes.commentContainer}>
							<Box className={classes.lexicalEditorContainer}>
								<LexicalEditor
									enableRichText={true}
									placeholder="Add comment here.."
								/>
							</Box>
							<Box className={classes.submitButtonContainer}>
								<AiOutlineSend
									size={20}
									className={classes.submitButton}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className={classes.columnTwo}>
					<Box className={classes.userContainer}>
						<Box className={classes.usersHeader}>Users</Box>
						<Box className={classes.userName}>
							Assignee: Sara Peterson
						</Box>
						<Box className={classes.userName}>
							Reporter: Donna Pearson
						</Box>
					</Box>
					<Box className={classes.datesContainer}>
						<Box className={classes.dateHeader}>Dates</Box>
						<Box className={classes.dueDate}>Due: 5/17/2023</Box>
						<Box className={classes.createdDate}>
							Created: 1/2/2023
						</Box>
						<Box className={classes.updatedDate}>
							Updated: 1/3/2023
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};

export default Task;

import { TextNode } from 'lexical';
import { createPortal } from 'react-dom';
import { makeStyles } from 'tss-react/mui';
import { getPossibleQueryMatch } from './utils';
import { useMentionLookupService } from './hooks';
import { useCallback, useMemo, useState } from 'react';
import { MentionsTypeaheadMenuItem } from './components';
import { MentionTypeaheadOption } from './typeheadOptions';
import { $createMentionNode } from '../../nodes/MentionNode';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
	LexicalTypeaheadMenuPlugin,
	useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';

const useStyles = makeStyles()((theme) => ({
	typeheadPopover: {
		background: '#fff',
		boxShadow: theme.shadows[1],
		borderRadius: '8px',
		width: 'auto',
		'& ul': {
			padding: '0',
			listStyle: 'none',
			margin: '0',
			borderRadius: '8px',
			maxHeight: '200px',
			overflowY: 'scroll',
			msOverflowStyle: 'none',
			scrollbarWidth: 'none',
		},
		'& ul::-webkit-scrollbar': {
			display: 'none',
		},
		'& ul li': {
			margin: '0',
			minWidth: '180px',
			fontSize: '14px',
			outline: 'none',
			cursor: 'pointer',
			borderRadius: '8px',
		},
		'& ul li.selected': {
			background: '#eee',
		},
		'& li': {
			margin: '0 8px 0 8px',
			padding: '8px',
			color: '#050505',
			cursor: 'pointer',
			lineHeight: '16px',
			fontSize: '15px',
			display: 'flex',
			alignContent: 'center',
			flexDirection: 'row',
			flexShrink: '0',
			backgroundColor: '#fff',
			borderRadius: '8px',
			border: '0',
		},
		'& li.active': {
			display: 'flex',
			width: '20px',
			height: '20px',
			backgroundSize: 'contain',
		},
		'& li:first-of-type': {
			borderRadius: '8px 8px 0px 0px',
		},
		'& li:last-child': {
			borderRadius: '0px 0px 8px 8px',
		},
		'& li:hover': {
			backgroundColor: '#eee',
		},
		'& li .text': {
			display: 'flex',
			lineHeight: '20px',
			flexGrow: '1',
			minWidth: '150px',
		},
	},
	mentionsMenu: {
		top: '25px',
		width: '250px',
		position: 'relative',
		minWidth: '250px',
	},
}));

// const defaultMentionString = 'a';

const NewMentionsPlugin = (): JSX.Element | null => {
	const { classes } = useStyles();
	const SuggestionListLengthLimit = 5; // At most, 5 suggestions are shown in the popup.
	const [editor] = useLexicalComposerContext();
	const [queryString, setQueryString] = useState<string | null>(null);
	const results = useMentionLookupService({
		mentionString: queryString,
	});
	const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
		minLength: 0,
	});

	const options = useMemo(
		() =>
			results
				.map(
					(result) =>
						new MentionTypeaheadOption(
							result,
							<i className="icon user" />
						)
				)
				.slice(0, SuggestionListLengthLimit),
		[results]
	);

	const onSelectOption = useCallback(
		(
			selectedOption: MentionTypeaheadOption,
			nodeToReplace: TextNode | null,
			closeMenu: () => void
		) => {
			editor.update(() => {
				const mentionNode = $createMentionNode(selectedOption.name);

				if (nodeToReplace) {
					nodeToReplace.replace(mentionNode);
				}

				mentionNode.select();
				closeMenu();
			});
		},
		[editor]
	);

	const checkForMentionMatch = useCallback(
		(text: string) => {
			// const defaultText = `${text}${defaultMentionString}`;
			// const searchText = text === '@' ? defaultText : text;
			const mentionMatch = getPossibleQueryMatch(text);
			const slashMatch = checkForSlashTriggerMatch(text, editor);
			return !slashMatch && mentionMatch ? mentionMatch : null;
		},
		[checkForSlashTriggerMatch, editor]
	);

	return (
		<LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
			onQueryChange={setQueryString}
			onSelectOption={onSelectOption}
			triggerFn={checkForMentionMatch}
			options={options}
			menuRenderFn={(
				anchorElementRef,
				{ selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
			) =>
				anchorElementRef.current && results.length
					? createPortal(
							<div
								className={`${classes.typeheadPopover} ${classes.mentionsMenu}`}
							>
								<ul>
									{options.map((option, i: number) => (
										<MentionsTypeaheadMenuItem
											index={i}
											isSelected={selectedIndex === i}
											onClick={() => {
												setHighlightedIndex(i);
												selectOptionAndCleanUp(option);
											}}
											onMouseEnter={() => {
												setHighlightedIndex(i);
											}}
											key={option.key}
											option={option}
										/>
									))}
								</ul>
							</div>,
							anchorElementRef.current
					  )
					: null
			}
		/>
	);
};

export default NewMentionsPlugin;

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Unstable_Grid2';
import CollectionsSort from '../CollectionsSort';
import CollectionsFilter from '../CollectionsFilter';
import { ICollection } from '../../../../common/interfaces';
import CollectionsAutocomplete from '../CollectionsAutocomplete';

const useStyles = makeStyles()((theme) => ({
	searchSortFilterContainer: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(6),
	},
	sortFilterContainer: {
		marginTop: theme.spacing(1),
	},
	gridItem: {
		flexGrow: '1',
	},
}));

interface IProps {
	collections: ICollection[] | undefined;
	filteredCollections: ICollection[] | undefined;
}

const SearchSortFilter = ({
	collections = [],
	filteredCollections,
}: IProps) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.searchSortFilterContainer}>
			<Grid container spacing={2}>
				<Grid
					xs={8}
					display="flex"
					alignItems="center"
					alignContent="center"
				>
					<CollectionsAutocomplete
						options={collections}
						className={classes.gridItem}
					/>
				</Grid>
				<Grid
					xs={2}
					display="flex"
					alignItems="center"
					alignContent="center"
				>
					<CollectionsFilter
						collections={collections}
						className={classes.gridItem}
					/>
				</Grid>
				<Grid
					xs={2}
					display="flex"
					alignItems="center"
					alignContent="center"
				>
					<CollectionsSort
						collections={filteredCollections || collections}
						className={classes.gridItem}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SearchSortFilter;

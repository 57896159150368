import { MouseEvent } from 'react';

interface IOptions {
	menuOpen: boolean;
	setMenuAnchorEl: CallableFunction;
}

const genHandleSortClick = ({ menuOpen, setMenuAnchorEl }: IOptions) => {
	return (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (menuOpen) {
			setMenuAnchorEl(null);
		} else {
			setMenuAnchorEl(event.currentTarget);
		}
	};
};

export default genHandleSortClick;

import moment from 'moment';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import { taskColors } from '../../../../../theme/colors';
import { IEvent } from '../../../../components/Calendar/interfaces';

const useStyles = makeStyles()((theme) => ({
	eventContainer: {
		boxShadow: theme.shadows[1],
		borderRadius: '5px',
		width: '100%',
		cursor: 'pointer',
		padding: '2px 10px',
		textAlign: 'center',
		color: theme.palette.common.white,
	},
}));

interface IProps {
	event: IEvent;
}

const FittingCalendarEvent = ({ event }: IProps) => {
	const { classes } = useStyles();
	const { allDay, title, start, end, extendedProps } = event;
	const { color } = extendedProps;
	const startTime = moment(start).format('h:mma');
	const endTime = moment(end).format('h:mma');
	const backgroundColor = taskColors[color];
	const timeDisplay = allDay ? 'All Day' : `${startTime} - ${endTime}`;

	return (
		<Box sx={{ backgroundColor }} className={classes.eventContainer}>
			<Box>{title}</Box>
			<Box>{timeDisplay}</Box>
		</Box>
	);
};

export default FittingCalendarEvent;

import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	collectionSearchItemImagesContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'space-evenly',
		padding: '2px',
		marginRight: theme.spacing(1),
		borderRight: '1px solid rgba(0, 0, 0, 0.12)',
	},
	collectionSearchItemImageContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'space-evenly',
		width: '20px',
		height: '20px',
		marginRight: theme.spacing(1),
	},
	collectionSearchItemImage: {
		width: '18px',
		maxHeight: '18px',
	},
}));

interface IProps {
	coverImageUrls: (string | null | undefined)[];
}

const SearchItemImage = ({ coverImageUrls }: IProps) => {
	const { classes } = useStyles();

	const coverImages = coverImageUrls?.map((imageUrl, index) => {
		return (
			<Paper
				key={index}
				elevation={0}
				className={classes.collectionSearchItemImageContainer}
			>
				<img
					className={classes.collectionSearchItemImage}
					src={`${imageUrl}`}
					alt=""
				/>
			</Paper>
		);
	});

	return (
		<div className={classes.collectionSearchItemImagesContainer}>
			{coverImages}
		</div>
	);
};

export default SearchItemImage;

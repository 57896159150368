import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Calendar from '../../components/Calendar';
import { renderEventContent } from './fullCalendarCallbacks';
import { IEvent } from '../../components/Calendar/interfaces';
import { useFindAllFullCalendarEventsQuery } from '../../api/generated';
import { selectAuthentication } from '../../../redux/slices/authenticationSlice';
import { generateEventsFromFullCalendarEvents } from '../../components/Calendar/utils';

const useStyles = makeStyles()((theme) => ({
	container: {
		height: '100vh',
	},
	calendarContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100vh',
	},
}));

const Fittings = () => {
	const { classes } = useStyles();
	const authentication = useSelector(selectAuthentication);
	const { user } = authentication;
	const { data } = useFindAllFullCalendarEventsQuery({
		findAllFullCalendarEventsInput: {
			where: {
				userId: user?.id,
			},
		},
	});

	let events: IEvent[] | undefined;
	const calendarEvents = data?.findAllFullCalendarEvents;

	if (calendarEvents) {
		events = generateEventsFromFullCalendarEvents({ calendarEvents });
	}

	return (
		<Container maxWidth="xl" className={classes.container}>
			<Box className={classes.calendarContainer}>
				<Calendar
					events={events || []}
					renderEventContent={renderEventContent}
				/>
			</Box>
		</Container>
	);
};

export default Fittings;

import { IColumn } from '../interfaces';

const columns: IColumn[] = [
	{
		id: 'fabricCode',
		label: 'Fabric Code',
	},
	{
		id: 'productionDescription',
		label: 'Product Description',
	},
	{
		id: 'content',
		label: 'Content',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'supplier',
		label: 'Supplier',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'supplierMaterialCode',
		label: 'Supplier Material Code',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'placement',
		label: 'Placement',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'quantity',
		label: 'Quantity',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'color1',
		label: 'Color 1',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'color2',
		label: 'Color 2',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'color3',
		label: 'Color 3',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'color4',
		label: 'Color 4',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'color5',
		label: 'Color 5',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'image',
		label: 'Image',
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
];

export default columns;

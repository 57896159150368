import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { ITechPack } from '../../../../common/interfaces';
import { FiFilter, FiChevronDown } from 'react-icons/fi';
import { useState, useContext, MouseEvent } from 'react';
import TechPacksConext from '../../../../contexts/TechPacksContext';

// Menu
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles()((theme) => ({
	filterContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		height: '56px',
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
	filterText: {
		marginRight: '5px',
	},
	filterIcon: {
		marginRight: theme.spacing(1),
	},
	arrowIcon: {
		marginLeft: 'auto',
	},
	menuList: {
		'&:focus-visible': {
			outline: '0 solid transparent',
		},
	},
}));

interface IProps {
	className?: string;
	techPacks: ITechPack[];
}

const TechPacksFilter = ({ className, techPacks }: IProps) => {
	const { classes } = useStyles();
	const [selectedFilter, setSelectedFilter] = useState('');
	const techPacksApi = useContext(TechPacksConext);
	const { setSelectedTechPacks } = techPacksApi;

	// Menu
	// --------
	const [anchorMenuEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorMenuEl);

	const handleFilterClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (menuOpen) {
			setMenuAnchorEl(null);
		} else {
			setMenuAnchorEl(event.currentTarget);
		}
	};

	const handleFilterIsReadyClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setSelectedFilter('Ready');
		setMenuAnchorEl(null);

		const filteredTechPacks = techPacks.filter((techPack: ITechPack) => {
			return techPack.isReady;
		});

		setSelectedTechPacks && setSelectedTechPacks(filteredTechPacks);
	};

	const handleFilterIsNotReadyClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setSelectedFilter('Not Ready');
		setMenuAnchorEl(null);

		const filteredTechPacks = techPacks.filter((techPack: ITechPack) => {
			return !techPack.isReady;
		});

		setSelectedTechPacks && setSelectedTechPacks(filteredTechPacks);
	};

	const handleFilterClearClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setSelectedFilter('');
		setMenuAnchorEl(null);
		setSelectedTechPacks && setSelectedTechPacks([]);
	};
	// --------

	// Common Event Handler
	// -------------------------
	const stopEventPropagation = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Paper
			onClick={handleFilterClick}
			className={`${classes.filterContainer} ${className}`}
		>
			<FiFilter size={20} className={classes.filterIcon} />
			<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
				<Typography className={classes.filterText}>Filter:</Typography>
				<Typography> {selectedFilter}</Typography>
			</Box>
			<FiChevronDown size={20} className={classes.arrowIcon} />
			<Menu
				id="card-options-button"
				aria-labelledby="card-options-button"
				anchorEl={anchorMenuEl}
				open={menuOpen}
				onClose={handleFilterClick}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuList
					className={classes.menuList}
					onClick={stopEventPropagation}
				>
					<MenuItem onClick={handleFilterIsReadyClick}>
						<ListItemText>Ready</ListItemText>
					</MenuItem>
					<MenuItem onClick={handleFilterIsNotReadyClick}>
						<ListItemText>Not Ready</ListItemText>
					</MenuItem>
					<Divider />
					<MenuItem onClick={handleFilterClearClick}>
						<ListItemText>Clear</ListItemText>
					</MenuItem>
				</MenuList>
			</Menu>
		</Paper>
	);
};

export default TechPacksFilter;

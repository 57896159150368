import { FiFramer } from 'react-icons/fi';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme) => ({
	companyLogoNameContainer: {
		display: 'flex',
		justifyContent: 'center',
		justifyItems: 'center',
		alignContent: 'center',
		alignItems: 'center',
	},
	logoNameContainer: {
		display: 'flex',
		justifyContent: 'center',
		justifyItems: 'center',
		alignContent: 'center',
		alignItems: 'center',
		color: theme.palette.primary.main,
	},
	logoIcon: {
		marginRight: '10px',
	},
}));

const CompanyLogoName = () => {
	const { classes } = useStyles();

	return (
		<div className={classes.companyLogoNameContainer}>
			<div className={classes.logoNameContainer}>
				<FiFramer size={18} className={classes.logoIcon} />
				<Typography data-testid="LogoText" variant="h6">
					Facilery
				</Typography>
			</div>
		</div>
	);
};

export default CompanyLogoName;

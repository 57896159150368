import Box from '@mui/material/Box';

interface IProps {
	children: JSX.Element;
}

const PageContentWithHeader = ({ children }: IProps) => {
	return <Box sx={{ width: '100%' }}>{children}</Box>;
};

export default PageContentWithHeader;

import { createContext, Dispatch, SetStateAction } from 'react';
import { ITechPack } from '../common/interfaces';

interface ITechPackApi {
	setSelectedTechPacks?: Dispatch<SetStateAction<ITechPack[] | []>>;
}

const techPacksApi: ITechPackApi = {};

const TechPacksContext = createContext(techPacksApi);

export default TechPacksContext;

import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as ReactLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles()((theme) => ({
	breadcrumbsContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
}));

interface IProps {
	pageName: string | null | undefined;
}

const BreadcrumbsComponent = ({ pageName }: IProps) => {
	const { classes } = useStyles();
	const link = '/collections';

	return (
		<div role="presentation" className={classes.breadcrumbsContainer}>
			<Breadcrumbs
				aria-label="breadcrumb"
				separator={<NavigateNextIcon fontSize="small" />}
			>
				<Link
					to={link}
					underline="hover"
					color="inherit"
					component={ReactLink}
				>
					<Typography>Collections</Typography>
				</Link>
				<Typography color="text.primary">{pageName}</Typography>
			</Breadcrumbs>
		</div>
	);
};

export default BreadcrumbsComponent;

import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { useState, MouseEvent } from 'react';
import { ITechPack, IActionItem } from '../../../../common/interfaces';
import { CardHeader, CardImage, CardDetails, CardActions } from './components';

const useStyles = makeStyles()((theme) => ({
	techPackContainer: {
		width: '275px',
		minWidth: '275px',
		margin: theme.spacing(1),
	},
}));

interface IProps {
	techPack: ITechPack;
}

const TechPackCard = ({ techPack }: IProps) => {
	let actionItems: IActionItem[] = [];
	const { classes } = useStyles();
	const { coverImageUrl } = techPack;
	const [anchorMenuEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorMenuEl);

	const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (menuOpen) {
			setMenuAnchorEl(null);
		} else {
			setMenuAnchorEl(event.currentTarget);
		}
	};

	if (!techPack.isReady) {
		actionItems = [
			{
				id: 1,
				action: 'Some action item',
			},
			{
				id: 2,
				action: 'Some action item',
			},
			{
				id: 3,
				action: 'Some action item',
			},
		];
	}

	return (
		<Paper elevation={2} className={classes.techPackContainer}>
			<CardHeader
				title={techPack?.name}
				menuOpen={menuOpen}
				anchorMenuEl={anchorMenuEl}
				handleMenuClick={handleMenuClick}
			/>
			<CardImage imageUrl={coverImageUrl} />
			<CardDetails
				techPack={techPack}
				actionItemCount={actionItems.length}
			/>
			<CardActions actionItems={actionItems} />
		</Paper>
	);
};

export default TechPackCard;

const mentionCandidates = [
	'A1234',
	'A1235',
	'A1236',
	'A1237',
	'A1238',
	'A1239',
	'B1234',
	'B1235',
	'B1236',
	'B1237',
	'B1238',
	'B1239',
	'Z1234',
	'Z1235',
	'Z1236',
	'Z1237',
	'Z1238',
	'Z1239',
];

export default mentionCandidates;

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	placeholder: {
		position: 'absolute',
		left: '9px',
		userSelect: 'none',
		pointerEvents: 'none',
		display: 'inline-block',
		color: theme.palette.heatherGrey.main,
	},
	plainTextPluginContainer: {
		position: 'relative',
	},
}));

interface IProps {
	text: string;
	className: string;
}

const Placeholder = ({ text, className }: IProps) => {
	const { classes } = useStyles();

	return <Box className={`${classes.placeholder} ${className}`}>{text}</Box>;
};

export default Placeholder;

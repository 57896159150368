import { ITechPack } from '../../../../common/interfaces';

interface IOptions {
	techPacks: ITechPack[] | null | undefined;
	imageCount?: number;
}

const getCoverImageUrls = ({ techPacks, imageCount = 4 }: IOptions) => {
	let coverImageUrls: (string | null | undefined)[] = [];
	const firstFourTechPacks = techPacks?.slice(0, imageCount);

	if (firstFourTechPacks) {
		coverImageUrls = firstFourTechPacks.map((techPack) => {
			return techPack.coverImageUrl;
		});
	}

	return coverImageUrls;
};

export default getCoverImageUrls;

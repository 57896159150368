import { fabric } from 'fabric';
import Canvas from '../Canvas';
import LineArrow from '../shapes/LineArrow';
import { IShape, IPoints } from '../../interfaces';
import { AppDispatch } from '../../../../redux/store';
import AnnotationImage from '../shapes/AnnotationImage';
import {
	shapesStateAdd,
	newCanvasState,
	undoCanvasState,
	redoCanvasState,
	resetCanvasState,
	shapesStateReset,
	updateCanvasState,
	removeObejectFromCanvasState,
} from '../../../../redux/slices/canvasStateSlice';

interface IInitOptions {
	imageUrl: string;
}

interface ICreateLineArrowOptions {
	points: IPoints;
}

interface IUpdatePositionOptions {
	shape: IShape | undefined;
	points: IPoints;
}

interface IGroupShapeOptions {
	shape: IShape | undefined;
}

interface ISetShapeCoordsOptions {
	shape: IShape | undefined;
}

interface ICreateGroupedShapeOptions {
	shape?: LineArrow;
}

interface IAddShapeOptions {
	shape: IShape | undefined;
}

interface IAddBackgroundImageOptions {
	image: fabric.Image;
}

interface IRemoveOptions {
	shape: fabric.Object | undefined;
}

interface IConstructorOptions {
	canvas: Canvas;
	appDispatch: AppDispatch;
}

interface IRenderStateOptions {
	state: string | null;
}

class CanvasManger {
	canvas: Canvas;
	appDispatch: AppDispatch;
	nextText?: string | number;

	constructor({ canvas, appDispatch }: IConstructorOptions) {
		this.canvas = canvas;
		this.appDispatch = appDispatch;
	}

	init = async ({ imageUrl }: IInitOptions) => {
		const image = await AnnotationImage({
			imageUrl,
			containerHeight: this.canvas.containerHeight,
		});

		this.addBackgroundImage({ image });
	};

	addShape = ({ shape }: IAddShapeOptions) => {
		if (shape) {
			shape.fabricItems.forEach((fabricItem) => {
				this.canvas.api.add(fabricItem);
			});

			this.canvas.api.requestRenderAll();
		}
	};

	remove = ({ shape }: IRemoveOptions) => {
		if (shape) {
			const { data } = shape;
			const { id } = data;

			this.appDispatch(removeObejectFromCanvasState({ id }));
		}
	};

	updateShapePosition = ({ shape, points }: IUpdatePositionOptions) => {
		const { x, y } = points;

		if (shape) {
			shape.updatePosition({ x, y });
			this.canvas.api.requestRenderAll();
		}
	};

	setShapeCoords = ({ shape }: ISetShapeCoordsOptions) => {
		if (shape) {
			shape.setCoords();
		}
	};

	groupShape = ({ shape }: IGroupShapeOptions) => {
		if (shape) {
			shape.fabricItems.forEach((fabricItem) => {
				this.canvas.api.remove(fabricItem);
			});

			const group = shape.groupFabricItems();

			this.canvas.api.add(group);
			this.canvas.api.requestRenderAll();
		}
	};

	addBackgroundImage = ({ image }: IAddBackgroundImageOptions) => {
		if (this.canvas.api.contextContainer) {
			this.canvas.api.add(image);
			this.canvas.api.centerObject(image);

			const currentCanvasState = this.canvas.toJson();

			this.appDispatch(newCanvasState());
			this.appDispatch(updateCanvasState(currentCanvasState));
			this.appDispatch(shapesStateReset());
		}
	};

	disposeCanvas = () => {
		this.canvas.api.dispose();
	};

	reset = () => {
		this.appDispatch(resetCanvasState());
	};

	undo = () => {
		this.appDispatch(undoCanvasState());
	};

	redo = () => {
		this.appDispatch(redoCanvasState());
	};

	setNextText = ({ text }: { text: string | number }) => {
		this.nextText = text;
	};

	createLineArrow = ({ points }: ICreateLineArrowOptions) => {
		return new LineArrow({
			points,
			text: this.nextText,
		});
	};

	saveState = () => {
		const currentCanvasState = this.canvas.toJson();
		this.appDispatch(updateCanvasState(currentCanvasState));
	};

	createGroupedShape = ({ shape }: ICreateGroupedShapeOptions) => {
		if (shape) {
			this.setShapeCoords({ shape });
			this.groupShape({ shape });
			this.saveState();

			const { id, name, textContent } = shape;
			this.appDispatch(
				shapesStateAdd({ shape: { id, name, textContent } })
			);
		}
	};

	renderState = ({ state }: IRenderStateOptions) => {
		if (state) {
			this.canvas.renderState({ state });
		}
	};
}

export default CanvasManger;

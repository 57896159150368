import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Annotation from '../../components/models/Annotations/Annotation';
import { useFindOneTechPackQuery } from '../../api/generated';
import {
	Breadcrumbs,
	SpecDiagrams,
	FitModelPhotos,
	TechPackHeader,
	BlackWhiteSketch,
	VersionsDropdown,
	ComponentDataTable,
	SpecificationDataTable,
} from './components';

const useStyles = makeStyles()((theme) => ({
	root: {},
}));

const TechPack = () => {
	const { classes } = useStyles();
	const [isAnnotating, setIsAnnotating] = useState(true);
	const { id: techPackIdString } = useParams();
	const id = Number(techPackIdString);
	const { data } = useFindOneTechPackQuery({
		findOneTechPackInput: {
			id,
		},
	});
	const techPack = data?.findOneTechPack;

	const handleButtonClick = () => {
		setIsAnnotating(true);
	};

	const handleExitClick = () => {
		setIsAnnotating(false);
	};

	return (
		<>
			{isAnnotating ? (
				<Container className={classes.root} maxWidth="xl">
					<Annotation handleExitClick={handleExitClick} />
				</Container>
			) : (
				<Container className={classes.root} maxWidth="xl">
					<Breadcrumbs
						pageName={`${techPack?.name} # ${techPack?.modelNumber}`}
					/>
					<VersionsDropdown />
					<TechPackHeader techPack={techPack} />
					<BlackWhiteSketch handleButtonClick={handleButtonClick} />
					<SpecDiagrams />
					<FitModelPhotos />
					<SpecificationDataTable />
					<ComponentDataTable />
				</Container>
			)}
		</>
	);
};

export default TechPack;

import {
	ICanvasObject,
	IConstructorOptions,
	IGetObjectByIdOptions,
} from './interfaces';

class CanvasModel {
	model: any;
	state: string;
	objects: ICanvasObject[];

	constructor({ state }: IConstructorOptions) {
		this.state = state;
		this.model = JSON.parse(state);
		this.objects = this.model.objects;
	}

	firstObject = () => {
		// padding by 1 since first object is image background
		return this.objects[1];
	};

	lastObject = () => {
		const objectsLength = this.objects.length;
		const lastObject = this.objects[objectsLength - 1];

		return lastObject;
	};

	getObjectById = ({ id }: IGetObjectByIdOptions) => {
		return this.objects.find((object) => {
			return id === object?.data?.id;
		});
	};

	getObjectIndexById = ({ id }: IGetObjectByIdOptions) => {
		return this.objects.findIndex((object) => {
			return id === object?.data?.id;
		});
	};

	deleteObject = ({ id }: IGetObjectByIdOptions) => {
		const deletedObject = this.getObjectById({ id });
		const index = this.getObjectIndexById({ id });
		this.objects.splice(index, 1);

		return { deletedObject, index };
	};

	getObjectIds = () => {
		let objectIds: { [key: string]: boolean } = {};

		this.objects.forEach((object) => {
			const data = object?.data;

			if (data) {
				const { id } = data;

				objectIds[id] = true;
			}
		});

		return objectIds;
	};

	getObjectIdsAsArray = () => {
		let objectIds: string[] = [];

		this.objects.forEach((object) => {
			const data = object?.data;

			if (data) {
				const { id } = data;

				objectIds.push(id);
			}
		});

		return objectIds;
	};

	toJson = () => {
		return JSON.stringify(this.model);
	};
}

export default CanvasModel;

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { IconButtonProps } from '@mui/material/IconButton';

interface IExpandMoreProps extends IconButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: IExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

export default ExpandMore;

import { makeStyles } from 'tss-react/mui';

const useCalendarStyles = makeStyles()((theme) => ({
	root: {
		width: '100%',
		height: '90%',

		// Calendar Container
		'& .fc .fc-view-harness': {
			borderRadius: '10px !important',
			boxShadow: `${theme.shadows[1]} !important`,
		},

		// Table
		//---------------
		'& table': {
			borderRadius: '10px !important',
			// boxShadow: theme.shadows[2],
		},
		'& table tr.fc-scrollgrid-section-header th:first-of-type': {
			borderTopRightRadius: '10px !important',
		},
		'& table td:last-of-type': {
			borderBottomRightRadius: '10px !important',
			borderBottomLeftRadius: '10px !important',
		},
		// Header
		//---------------
		// Toolbar
		'& .fc .fc-toolbar': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center !important',
			position: 'relative',
		},
		'& .fc .fc-toolbar.fc-header-toolbar': {
			padding: theme.spacing(2),
			borderRadius: '10px',
			boxShadow: theme.shadows[1],
			marginBottom: theme.spacing(7),
		},
		'& .fc-header-toolbar .fc-toolbar-chunk:first-of-type': {
			position: 'absolute',
			left: '10px',
		},
		'& .fc-header-toolbar .fc-toolbar-chunk:last-of-type': {
			position: 'absolute',
			right: '10px',
		},
		'& .fc-toolbar-chunk': {
			display: 'flex',
		},
		'& .fc-toolbar-chunk .fc-toolbar-title': {
			margin: '0 20px !important',
			// width: '235px',
			// height: '42px',
			textAlign: 'center',
		},
		// Buttons
		// ---------------
		'& .fc-toolbar-chunk button.fc-button': {
			backgroundColor: theme.palette.secondary.main,
			borderColor: theme.palette.secondary.main,
			boxShadow: theme.shadows[1],
			padding: theme.spacing(1),
			borderRadius: '10px',
			transition:
				'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		},
		'& .fc-toolbar-chunk button.fc-button-active': {
			backgroundColor: `${theme.palette.secondary.main} !important`,
			borderColor: `${theme.palette.secondary.main} !important`,
			boxShadow: theme.shadows[1],
		},
		'& .fc-toolbar-chunk button.fc-button:focus': {
			backgroundColor: `${theme.palette.secondary.main} !important`,
			borderColor: `${theme.palette.secondary.main} !important`,
			boxShadow: `${theme.shadows[1]} !important`,
		},
		'& .fc-toolbar-chunk button.fc-button:hover': {
			boxShadow: `${theme.shadows[3]} !important`,
		},
		// Buttons (prev, next)
		'& .fc-toolbar-chunk button.fc-prev-button': {
			margin: '0 !important',
			padding: '0 4px !important',
			fontSize: '.9rem',
		},
		'& .fc-toolbar-chunk button.fc-next-button': {
			margin: '0 !important',
			padding: '0 4px !important',
			fontSize: '.9rem',
		},
		// Grids
		// ---------------
		'& .fc .fc-daygrid-day-frame': {
			// height: '50px',
		},
		'& .fc .fc-daygrid-day-top': {
			display: 'flex',
			justifyContent: 'end',
			marginTop: '3px',
			marginRight: '3px',
		},

		// Gric - Clicked and is active
		'& .fc .fc-daygrid-day-bg .fc-daygrid-bg-harness .fc-highlight': {
			backgroundColor: `${theme.palette.secondary.main} !important`,
			opacity: '.05',
		},

		// Today - Daily Grid
		'& .fc .fc-day.fc-day-today': {
			backgroundColor: 'unset',
		},
		//--

		// All Day Event
		'& .fc .fc-h-event': {
			backgroundColor: theme.palette.common.white,
			border: `1px solid ${theme.palette.common.white}`,
		},
		//--

		// Today - Weekly Date header - Option - 1
		'& .fc th .fc-col-header-cell.fc-day-today': {
			color: `${theme.palette.secondary.main} !important`,
			// color: theme.palette.common.white,
			// opacity: '.65',
		},
		// '& .fc th .fc-day-today:last-of-type': {
		// 	borderTopRightRadius: '10px',
		// },
		// '& .fc th .fc-day-today:first-of-type': {
		// 	borderTopLeftRadius: '10px',
		// },
		//--

		'& .fc .fc-day.fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number':
			{
				width: '24px',
				height: '24px',
				padding: 'unset',
				textAlign: 'center',
				borderRadius: '50px',
				color: theme.palette.common.white,
				backgroundColor: theme.palette.secondary.main,
			},
		'& .fc .fc-event': {
			padding: '4px 2px',
		},
		// Event
		'& .fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover':
			{
				background: theme.palette.common.white,
			},
	},
}));

export default useCalendarStyles;

interface IOptions {
	strings: string[];
}

const capitalizeStrings = ({ strings }: IOptions) => {
	return strings.map((string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	});
};

export default capitalizeStrings;

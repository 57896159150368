import { VALID_CHARS, TRIGGERS } from './helpers';

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
	'(^|\\s|\\()(' +
		'[' +
		TRIGGERS +
		']' +
		'((?:' +
		VALID_CHARS +
		'){0,' +
		ALIAS_LENGTH_LIMIT +
		'})' +
		')$'
);

export default AtSignMentionsRegexAliasRegex;

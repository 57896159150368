import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import { Shape } from '../enums';

const useStyles = makeStyles()(() => ({
	notificationContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '20px',
		width: '20px',
		padding: '5px',
		borderRadius: '5px',
		border: '1px solid red',
		backgroundColor: 'red',
		transition: 'ease 0s',
	},
	notification: {
		color: 'white',
		fontSize: '.8rem',
	},
	hide: {
		display: 'none',
	},
	circle: {
		height: '16px',
		width: '16px',
		fontSize: '.6rem',
		borderRadius: '50%',
	},
}));

interface INotificationCountOptions {
	count: number;
	className?: string;
	shape?: Shape.Circle | Shape.Square;
	shouldDisplay: boolean;
}

const NotificationCount = ({
	count,
	className,
	shape = Shape.Square,
	shouldDisplay,
}: INotificationCountOptions) => {
	const { classes } = useStyles();
	const displayNotification = count > 0 && shouldDisplay;
	const hideClass = displayNotification ? '' : classes.hide;
	const circleClass = shape === Shape.Circle ? classes.circle : '';

	return (
		<Box
			className={`${classes.notificationContainer} ${hideClass} ${circleClass} ${className}`}
		>
			<Box className={classes.notification}>{count}</Box>
		</Box>
	);
};

export default NotificationCount;

import { MouseEvent } from 'react';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FiPlus, FiXCircle, FiArchive } from 'react-icons/fi';
import { stopEventPropagation } from '../../../../../common/eventHandlers';

interface IProps {
	menuOpen: boolean;
	handleMenuClick: (event: MouseEvent<HTMLElement>) => void;
	anchorMenuEl: null | HTMLElement;
}

const MenuComponent = ({ menuOpen, handleMenuClick, anchorMenuEl }: IProps) => {
	const handleAddToFittingClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleAddToWorkspaceClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleOtherActionClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Menu
			id="card-options-button"
			aria-labelledby="card-options-button"
			anchorEl={anchorMenuEl}
			open={menuOpen}
			onClose={handleMenuClick}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			<MenuList onClick={stopEventPropagation}>
				<MenuItem onClick={handleAddToFittingClick}>
					<ListItemIcon>
						<FiPlus size={18} />
					</ListItemIcon>
					<ListItemText>Add to Fitting</ListItemText>
				</MenuItem>
				<MenuItem onClick={handleAddToWorkspaceClick}>
					<ListItemIcon>
						<FiPlus size={18} />
					</ListItemIcon>
					<ListItemText>Add to Workspace</ListItemText>
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleOtherActionClick}>
					<ListItemIcon>
						<FiArchive size={18} />
					</ListItemIcon>
					<ListItemText>Archive</ListItemText>
				</MenuItem>
				<MenuItem onClick={handleOtherActionClick}>
					<ListItemIcon>
						<FiXCircle size={20} />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</MenuList>
		</Menu>
	);
};

export default MenuComponent;

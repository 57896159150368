import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import { getCoverImageUrls } from '../../utils';
import Typography from '@mui/material/Typography';
import SearchItemImage from '../CollectionSearchItemImage';
import { ICollection } from '../../../../../common/interfaces';

const useStyles = makeStyles()((theme) => ({
	searchItemContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		padding: theme.spacing(1),
		cursor: 'pointer',
	},
	searchItemImage: {
		width: '100%',
	},
	searchItemNameContainer: {},
	searchItemName: {
		fontSize: '1.05rem',
	},
}));

interface IProps {
	collection: ICollection;
}

const CollectionSearchItem = ({ collection }: IProps) => {
	const { classes } = useStyles();
	const { techPacks } = collection;
	const coverImageUrls = getCoverImageUrls({ techPacks, imageCount: 2 });

	return (
		<Box className={classes.searchItemContainer}>
			<SearchItemImage coverImageUrls={coverImageUrls} />
			<Box className={classes.searchItemNameContainer}>
				<Typography className={classes.searchItemName}>
					{collection?.name}
				</Typography>
			</Box>
		</Box>
	);
};

export default CollectionSearchItem;

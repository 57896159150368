import { useEffect } from 'react';
import { Status } from '../../redux';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import LayoutApp from '../layouts/LayoutApp';
import LayoutHome from '../layouts/LayoutHome';
import { useNavigate } from 'react-router-dom';
import IsAuthenticated from './IsAuthenticated';
import { Routes, Route } from 'react-router-dom';
import {
	Home,
	Users,
	Task,
	Tasks,
	SignIn,
	SignUp,
	Settings,
	Fittings,
	TechPack,
	Mentions,
	TechPacks,
	Collection,
	Collections,
} from '../screens';
import {
	fetchAuthentication,
	selectAuthentication,
	selectAuthenticationStatus,
} from '../../redux/slices/authenticationSlice';

const App = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const authentication = useSelector(selectAuthentication);
	const authenticationStatus = useSelector(selectAuthenticationStatus);

	useEffect(() => {
		if (authentication && authenticationStatus === Status.Idle) {
			dispatch(fetchAuthentication());
		}
	}, [authenticationStatus, authentication, dispatch, navigate]);

	return (
		<>
			<Routes>
				<Route
					path="/users"
					element={
						<LayoutHome>
							<Users />
						</LayoutHome>
					}
				/>
				<Route path="/sign-up" element={<SignUp />} />
				<Route path="/sign-in" element={<SignIn />} />
				<Route
					path="/settings"
					element={
						<IsAuthenticated>
							<LayoutApp>
								<Settings />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/collections"
					element={
						<IsAuthenticated>
							<LayoutApp>
								<Collections />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/collections/:id"
					element={
						<IsAuthenticated>
							<LayoutApp withHeader={false}>
								<Collection />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/tech-packs"
					element={
						<IsAuthenticated>
							<LayoutApp>
								<TechPacks />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/tech-packs/:id"
					element={
						<IsAuthenticated>
							<LayoutApp withHeader={false}>
								<TechPack />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/fittings"
					element={
						<IsAuthenticated>
							<LayoutApp withHeader={false}>
								<Fittings />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/tasks"
					element={
						<IsAuthenticated>
							<LayoutApp withHeader={false}>
								<Tasks />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/tasks/:id"
					element={
						<IsAuthenticated>
							<LayoutApp withHeader={false}>
								<Task />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/mentions"
					element={
						<IsAuthenticated>
							<LayoutApp withHeader={false}>
								<Mentions />
							</LayoutApp>
						</IsAuthenticated>
					}
				/>
				<Route
					path="/"
					element={
						<LayoutHome>
							<Home />
						</LayoutHome>
					}
				/>
			</Routes>
		</>
	);
};

export default App;

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { useState, MouseEvent } from 'react';
import MenuList from '@mui/material/MenuList';
import { FiChevronDown } from 'react-icons/fi';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles()((theme) => ({
	menuContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		height: '56px',
		padding: theme.spacing(2),
		cursor: 'pointer',
		maxWidth: '200px',
		marginBottom: theme.spacing(4),
	},
	menuHeader: {
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	arrowIcon: {
		marginLeft: 'auto',
	},
	menuList: {
		'&:focus-visible': {
			outline: '0 solid transparent',
		},
	},
}));

interface IProps {
	className?: string;
}

const VersionsDropdown = ({ className }: IProps) => {
	const { classes } = useStyles();
	const [selectedVersion, setSelectedVersion] = useState('Line Adoption');

	// Menu
	// --------
	const [anchorMenuEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorMenuEl);

	const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (menuOpen) {
			setMenuAnchorEl(null);
		} else {
			setMenuAnchorEl(event.currentTarget);
		}
	};

	const handleMenuItemClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setSelectedVersion(event.currentTarget?.innerText);

		if (menuOpen) {
			setMenuAnchorEl(null);
		} else {
			setMenuAnchorEl(event.currentTarget);
		}
	};
	// --------

	// Common Event Handler
	// -------------------------
	const stopEventPropagation = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Paper
			onClick={handleMenuClick}
			className={`${classes.menuContainer} ${className}`}
		>
			<Box className={classes.menuHeader}>
				<Typography>{selectedVersion}</Typography>
				<FiChevronDown size={20} className={classes.arrowIcon} />
			</Box>
			<Menu
				id="card-options-button"
				aria-labelledby="card-options-button"
				anchorEl={anchorMenuEl}
				open={menuOpen}
				onClose={handleMenuClick}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuList
					className={classes.menuList}
					onClick={stopEventPropagation}
				>
					<MenuItem onClick={handleMenuItemClick}>
						<ListItemText>Line Adoption</ListItemText>
					</MenuItem>
					<MenuItem onClick={handleMenuItemClick}>
						<ListItemText>Revised Fit</ListItemText>
					</MenuItem>
					<MenuItem onClick={handleMenuItemClick}>
						<ListItemText>2nd Revised Fit</ListItemText>
					</MenuItem>
					<MenuItem onClick={handleMenuItemClick}>
						<ListItemText>3rd Revised Fit</ListItemText>
					</MenuItem>
					<MenuItem onClick={handleMenuItemClick}>
						<ListItemText>Final Fit</ListItemText>
					</MenuItem>
				</MenuList>
			</Menu>
		</Paper>
	);
};

export default VersionsDropdown;

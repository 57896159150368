import { createContext, Dispatch, SetStateAction } from 'react';
import { ICollection } from '../common/interfaces';

interface ICollectionApi {
	setSelectedCollections?: Dispatch<SetStateAction<ICollection[] | []>>;
}

const techPacksApi: ICollectionApi = {};

const CollectionsContext = createContext(techPacksApi);

export default CollectionsContext;

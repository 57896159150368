import { IRow } from '../interfaces';

// TODO Ability to add images

const rows: IRow[] = [
	// Subheader 1 - FABRICS/INTERLININGS
	{
		id: 1,
		fabricCode: 'A2101',
		productionDescription: 'Naia/Viscose/Poly Mechanical Stretch Twill',
		content: '32%Acetate/48%Viscose/20%Poly 159gsm/44/45"',
		supplier: 'Mizuda',
		supplierMaterialCode: '#OAYB2000068+KF',
		placement: 'A 1 - Self',
		quantity: '',
		color1: 'LODEN 19-0511 TCX',
		color2: 'DARK OLIVE 19-0414 TC',
		color3: 'NAVAL 19-3924 TCX',
		color4: 'JET BLACK 19-4004 TCX',
		color5: 'TRAIL MOSS 19-0618 TCX',
		image: 'TODO',
	},
	{
		id: 2,
		fabricCode: 'N/A',
		productionDescription: 'Non Fusible, Lightweight Interfacing',
		content: 'TBD',
		supplier: 'Factory Sourced',
		supplierMaterialCode: '???',
		placement: 'Pkt Plkt Welts CF Fly Fctry Advise',
		quantity: '',
		color1: 'CHARCOAL',
		color2: 'CHARCOAL',
		color3: 'CHARCOAL',
		color4: 'CHARCOAL',
		color5: 'CHARCOAL',
		image: 'TODO',
	},
	// Subheader 1 - Trims
	{
		id: 3,
		fabricCode: 'N/A',
		productionDescription: 'Thread',
		content: 'TBD',
		supplier: 'Factory Recommended',
		supplierMaterialCode: '???',
		placement: 'All Over',
		quantity: '',
		color1: 'DTM',
		color2: 'DTM',
		color3: 'DTM',
		color4: 'DTM',
		color5: 'DTM',
		image: 'TODO',
	},
	{
		id: 4,
		fabricCode: 'N/A',
		productionDescription: 'Thread',
		content: 'TBD',
		supplier: 'Factory Recommended',
		supplierMaterialCode: '???',
		placement: 'B.T. @ Loop Logo Edges',
		quantity: '',
		color1: 'TRAIL MOSS 19-0618 TCX',
		color2: 'TRAIL MOSS 19-0618 TCX',
		color3: 'TRAIL MOSS 19-0618 TCX',
		color4: 'TRAIL MOSS 19-0618 TCX',
		color5: 'TRAIL MOSS 19-0618 TCX',
		image: 'TODO',
	},
	{
		id: 5,
		fabricCode: 'T03',
		productionDescription: '24L Plastic Snap',
		content: 'TBD',
		supplier: 'K and K',
		supplierMaterialCode: '#1510',
		placement: '1 CF Fly',
		quantity: '1',
		color1: 'DTM',
		color2: 'DTM',
		color3: 'DTM',
		color4: 'DTM',
		color5: 'DTM',
		image: 'TODO',
	},
	{
		id: 6,
		fabricCode: 'T05',
		productionDescription: '3/8" Twill Tape',
		content: 'TBD',
		supplier: 'K and K',
		supplierMaterialCode: 'PT-0278',
		placement: 'Branded T05 Loop Logo',
		quantity: '',
		color1: 'DARK OLIVE 19-0414 TC',
		color2: 'DARK OLIVE 19-0414 TC',
		color3: 'DARK OLIVE 19-0414 TC',
		color4: 'DARK OLIVE 19-0414 TC',
		color5: 'DARK OLIVE 19-0414 TC',
		image: 'TODO',
	},
	{
		id: 7,
		fabricCode: 'T10',
		productionDescription: '13/4" Elastic',
		content: 'TBD',
		supplier: 'Factory Sourced',
		supplierMaterialCode: 'E1566317',
		placement: 'WB',
		quantity: '',
		color1: 'JET BLACK 19-4004 TCX',
		color2: 'JET BLACK 19-4004 TCX',
		color3: 'JET BLACK 19-4004 TCX',
		color4: 'JET BLACK 19-4004 TCX',
		color5: 'JET BLACK 19-4004 TCX',
		image: 'TODO',
	},
	{
		id: 8,
		fabricCode: 'T07',
		productionDescription: 'Knit Tape Coil Zipper',
		content: 'TBD',
		supplier: 'YKK',
		supplierMaterialCode: 'CFC-39 DSYG C5 BP12 KENSIN N-ANTI',
		placement: 'CF Fly & Security BK PKT',
		quantity: '2',
		color1: 'DTM',
		color2: 'DTM',
		color3: 'DTM',
		color4: 'DTM',
		color5: 'DTM',
		image: 'TODO',
	},
	{
		id: 9,
		fabricCode: 'T12',
		productionDescription: '16L/10.2mm Thin Cap Hidden Snap',
		content: 'TBD',
		supplier: 'K and K',
		supplierMaterialCode: '???',
		placement: "Wearer's L BK PKT",
		quantity: '1',
		color1: 'DTM',
		color2: 'DTM',
		color3: 'DTM',
		color4: 'DTM',
		color5: 'DTM',
		image: 'TODO',
	},
	{
		id: 10,
		fabricCode: 'T09',
		productionDescription: '16L Safety Bttn 4 Hole Chalk Bttn',
		content: 'TBD',
		supplier: 'Factory Sourced',
		supplierMaterialCode: '???',
		placement: '1 CF Fly',
		quantity: '1',
		color1: 'LODEN 19-0511 TCX',
		color2: 'DARK OLIVE 19-0414 TC',
		color3: 'NAVAL 19-3924 TCX',
		color4: 'JET BLACK 19-4004 TCX',
		color5: 'TRAIL MOSS 19-0618 TCX',
		image: 'TODO',
	},
];

export default rows;

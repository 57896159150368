import { QueryMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { AtSignMentionsRegex, AtSignMentionsRegexAliasRegex } from '../regexes';

const checkForAtSignMentions = (
	text: string,
	minMatchLength: number
): QueryMatch | null => {
	let match = AtSignMentionsRegex.exec(text);

	if (match === null) {
		match = AtSignMentionsRegexAliasRegex.exec(text);
	}
	if (match !== null) {
		// The strategy ignores leading whitespace but we need to know it's
		// length to add it to the leadOffset
		const maybeLeadingWhitespace = match[1];

		const matchingString = match[3];
		if (matchingString.length >= minMatchLength) {
			return {
				leadOffset: match.index + maybeLeadingWhitespace.length,
				matchingString,
				replaceableString: match[2],
			};
		}
	}
	return null;
};

export default checkForAtSignMentions;

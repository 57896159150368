import Box from '@mui/material/Box';
import { IEvent } from './interfaces';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import { handleDateClick } from './eventHandlers';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import useCalendarStyles from './hooks/useCalendarStyles';
import { ReactElement, JSXElementConstructor } from 'react';

interface IProps {
	events: IEvent[];
	renderEventContent?: (
		eventInfo: any
	) => ReactElement<any, string | JSXElementConstructor<any>>;
}

const Calendar = ({ events, renderEventContent }: IProps) => {
	const { classes } = useCalendarStyles();

	return (
		<Box className={classes.root}>
			<FullCalendar
				editable
				selectable
				fixedWeekCount={false}
				headerToolbar={{
					start: 'today',
					center: 'prev title next',
					end: 'dayGridMonth dayGridWeek dayGridDay',
				}}
				plugins={[dayGridPlugin, interactionPlugin]}
				initialView="dayGridWeek"
				events={events}
				eventContent={renderEventContent}
				dateClick={handleDateClick}
				eventAdd={(e: any) => {
					console.log('Event Add', e);
				}}
				eventChange={(e: any) => {
					console.log('Event Change', e);
				}}
				eventRemove={(e: any) => {
					console.log('Event Remove', e);
				}}
				height="100%"
			/>
		</Box>
	);
};

export default Calendar;

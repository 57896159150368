import { useState } from 'react';
import Menu from './components/Menu';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { FiBarChart, FiChevronDown } from 'react-icons/fi';
import { ICollection } from '../../../../common/interfaces';
import { genHandleSortClick } from './genClickHandlers';

const useStyles = makeStyles()((theme) => ({
	sortContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		height: '56px',
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
	sortText: {
		marginRight: '5px',
	},
	sortIcon: {
		transform: 'rotate(270deg)',
		marginRight: theme.spacing(1),
	},
	arrowIcon: {
		marginLeft: 'auto',
	},
}));

interface IProps {
	className?: string;
	collections: ICollection[];
}

const CollectionsSort = ({ className, collections }: IProps) => {
	const { classes } = useStyles();
	const [selectedSort, setSelectedSort] = useState('');

	const [anchorMenuEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorMenuEl);
	const handleSortClick = genHandleSortClick({ menuOpen, setMenuAnchorEl });

	return (
		<Paper
			onClick={handleSortClick}
			className={`${classes.sortContainer} ${className}`}
		>
			<FiBarChart size={20} className={classes.sortIcon} />
			<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
				<Typography className={classes.sortText}>Sort:</Typography>
				<Typography> {selectedSort}</Typography>
			</Box>
			<FiChevronDown size={20} className={classes.arrowIcon} />
			<Menu
				menuOpen={menuOpen}
				collections={collections}
				anchorMenuEl={anchorMenuEl}
				setMenuAnchorEl={setMenuAnchorEl}
				setSelectedSort={setSelectedSort}
				handleSortClick={handleSortClick}
			/>
		</Paper>
	);
};

export default CollectionsSort;

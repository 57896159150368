import { MutableRefObject } from 'react';
import { AppDispatch } from '../../redux/store';
import { Canvas, EventManager, CanvasManager } from './classes';
import { newCanvasState } from '../../redux/slices/canvasStateSlice';

interface IConstructorOptions {
	appDispatch: AppDispatch;
	canvasRef: MutableRefObject<null>;
	canvasContainer: HTMLElement | null;
}

interface IRenderStateOptions {
	state: string | null;
}

interface IInitOptions {
	imageUrl: string;
}

class FabricCanvas {
	canvas: Canvas;
	appDispatch: AppDispatch;
	eventManager: EventManager;
	canvasManager: CanvasManager;

	constructor({
		canvasRef,
		appDispatch,
		canvasContainer,
	}: IConstructorOptions) {
		this.appDispatch = appDispatch;

		this.appDispatch(newCanvasState());

		this.canvas = new Canvas({ canvasContainer, canvasRef });

		this.canvasManager = new CanvasManager({
			canvas: this.canvas,
			appDispatch: this.appDispatch,
		});

		this.eventManager = new EventManager({
			canvas: this.canvas,
			canvasManager: this.canvasManager,
		});

		this.eventManager.addListeners();
	}

	init = async ({ imageUrl }: IInitOptions) => {
		this.canvasManager.init({ imageUrl });
	};

	renderState = ({ state }: IRenderStateOptions) => {
		if (state) {
			this.canvasManager.renderState({ state });
		}
	};

	tearDown = () => {
		this.canvasManager.disposeCanvas();
		this.eventManager.removeListeners();
	};

	setNextText = ({ text }: { text: string | number }) => {
		this.canvasManager.setNextText({ text });
	};
}

export default FabricCanvas;

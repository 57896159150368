import environmentConfig from '../../common/constants/environmentConfig';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
const { serverBaseUrl } = environmentConfig[environment];

export const api = createApi({
	baseQuery: graphqlRequestBaseQuery({
		url: `${serverBaseUrl}/graphql`,
	}),
	endpoints: () => ({}),
});

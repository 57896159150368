import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TechPackCard from './TechPackCard';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import SearchSortFilter from './SearchSortFilter';
import { Link as ReactLink } from 'react-router-dom';
import { ITechPack } from '../../../common/interfaces';
import { useFindAllTechPacksQuery } from '../../api/generated';
import TechPacksConext from '../../../contexts/TechPacksContext';
import { selectAuthentication } from '../../../redux/slices/authenticationSlice';

const useStyles = makeStyles()((theme) => ({
	root: {},
	searchSortFilterContainer: {
		display: 'flex',
		width: '100%',
		marginBottom: theme.spacing(6),
	},
	techPacksContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
}));

const TechPacks = () => {
	const { classes } = useStyles();
	const [selectedTechPacks, setSelectedTechPacks] = useState<
		ITechPack[] | []
	>([]);

	const techPacksApi = { setSelectedTechPacks };

	const authentication = useSelector(selectAuthentication);
	const { user } = authentication;
	const { data } = useFindAllTechPacksQuery({
		findAllTechPacksInput: {
			where: {
				userId: user?.id,
			},
		},
	});

	let techPacks = data?.findAllTechPacks;
	let filteredTechPacks: ITechPack[];

	if (selectedTechPacks.length > 0) {
		filteredTechPacks = selectedTechPacks;
	} else if (
		Array.isArray(data?.findAllTechPacks) &&
		typeof data?.findAllTechPacks?.length === 'number' &&
		data?.findAllTechPacks?.length > 0
	) {
		filteredTechPacks = data?.findAllTechPacks as ITechPack[];
	} else {
		filteredTechPacks = [] as ITechPack[];
	}

	const techPacksComponents = filteredTechPacks?.map(
		(techPack: ITechPack) => {
			const { id } = techPack;
			const path = `/tech-packs/${id}`;

			return (
				<Link
					to={path}
					key={id}
					color="primary"
					underline="none"
					component={ReactLink}
				>
					<TechPackCard key={id} techPack={techPack} />
				</Link>
			);
		}
	);

	return (
		<TechPacksConext.Provider value={techPacksApi}>
			<SearchSortFilter
				techPacks={techPacks}
				filteredTechPacks={filteredTechPacks}
			/>

			<Box className={classes.techPacksContainer}>
				{techPacksComponents}
			</Box>
		</TechPacksConext.Provider>
	);
};

export default TechPacks;

import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import SectionHeader from './SectionHeader';

const useStyles = makeStyles()((theme) => ({
	sectionContainer: {
		position: 'relative',
		marginTop: theme.spacing(10),
	},
}));

interface IProps {
	header: string;
	children?: ReactNode;
}

const BlackWhiteSketch = ({ children, header = '' }: IProps) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.sectionContainer}>
			<SectionHeader header={header} />
			{children}
		</Box>
	);
};

export default BlackWhiteSketch;

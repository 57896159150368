import { gql } from '@apollo/client';

const SIGN_UP = gql`
	mutation Mutation($createUserEmailInput: CreateUserEmailInput!) {
		signUp(createUserEmailInput: $createUserEmailInput) {
			user {
				id
				email
				role
				createdAt
				updatedAt
			}
			isAuthenticated
		}
	}
`;

export default SIGN_UP;

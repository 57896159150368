import { CapitalizedNameMentionsRegex } from '../regexes';
import { QueryMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin';

const checkForCapitalizedNameMentions = (
	text: string,
	minMatchLength: number
): QueryMatch | null => {
	const match = CapitalizedNameMentionsRegex.exec(text);
	if (match !== null) {
		// The strategy ignores leading whitespace but we need to know it's
		// length to add it to the leadOffset
		const maybeLeadingWhitespace = match[1];

		const matchingString = match[2];
		if (matchingString != null && matchingString.length >= minMatchLength) {
			return {
				leadOffset: match.index + maybeLeadingWhitespace.length,
				matchingString,
				replaceableString: matchingString,
			};
		}
	}
	return null;
};

export default checkForCapitalizedNameMentions;

import CanvasUtils from '../CanvasUtils';
import CanvasModel from '../CanvasModel';

interface IUpdate {
	state: string;
}

class CanvasState {
	base: string | null;
	current: string | null;
	past: string[];
	future: string[];

	constructor() {
		this.base = null;
		this.current = null;
		this.past = [];
		this.future = [];
	}

	update = ({ state }: IUpdate) => {
		if (!this.base) {
			this.base = state;
		}

		if (this.current) {
			this.past.push(this.current);
		}

		this.current = state;
		this.future = [];
	};

	undo = () => {
		const pastState = this.past.pop();
		let result;

		if (pastState) {
			if (this.current) {
				result = CanvasUtils.compareStates({
					baseState: this.current,
					compareState: pastState,
				});

				// console.log(result);

				this.future.unshift(this.current);
			}

			this.current = pastState;
		}

		return result;
	};

	redo = () => {
		const futureState = this.future.shift();
		let result;

		if (futureState) {
			if (this.current) {
				result = CanvasUtils.compareStates({
					baseState: this.current,
					compareState: futureState,
				});

				// console.log(result);

				this.past.push(this.current);
			}

			this.current = futureState;
		}

		return result;
	};

	reset = () => {
		this.past = [];
		this.future = [];
		this.current = this.base;
	};

	new = () => {
		this.base = null;
		this.current = null;
		this.past = [];
		this.future = [];
	};

	remove = ({ id }: { id: string }) => {
		if (id && this.current) {
			const canvasModel = new CanvasModel({ state: this.current });

			canvasModel.deleteObject({ id });

			const updatedState = canvasModel.toJson();

			this.update({ state: updatedState });
		}
	};
}

export default CanvasState;

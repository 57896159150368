import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	collectionCardImagesContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap',
		height: '200px',
		padding: '10px',
		borderBottom: '1px solid #eee',
	},
	collectionCardImageContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'space-evenly',
		marginBottom: '2px',
		width: '125px',
		height: '85px',
	},
	collectionCardImage: {
		width: '110px',
		maxHeight: '80px',
	},
}));

interface IProps {
	coverImageUrls: (string | null | undefined)[];
}

const CardImage = ({ coverImageUrls }: IProps) => {
	const { classes } = useStyles();
	const hasTechPacks = coverImageUrls?.length > 0;
	const coverImages = coverImageUrls?.map((imageUrl, index) => {
		return (
			<Paper
				key={index}
				elevation={0}
				className={classes.collectionCardImageContainer}
			>
				<img
					className={classes.collectionCardImage}
					src={`${imageUrl}`}
					alt=""
				/>
			</Paper>
		);
	});

	return (
		<div className={classes.collectionCardImagesContainer}>
			{hasTechPacks ? coverImages : 'No Tech Packs assigned'}
		</div>
	);
};

export default CardImage;

import { MouseEvent } from 'react';
import { SortBy } from '../enums';
import { Sort } from '../../../../../common/enums';
import sortByDeadline from '../sortBy/sortByDealine';
import { ICollection } from '../../../../../common/interfaces';

interface IOptions {
	sortOrder: Sort;
	collections: ICollection[];
	setMenuAnchorEl: CallableFunction;
	setSelectedSort: CallableFunction;
	setSelectedCollections: CallableFunction | undefined;
}

const genHandleSortDeadlineClickASC = ({
	collections,
	sortOrder,
	setMenuAnchorEl,
	setSelectedSort,
	setSelectedCollections,
}: IOptions) => {
	return (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setMenuAnchorEl(null);
		setSelectedSort(`${SortBy.Deadline} (${sortOrder})`);

		const sortedCollections = sortByDeadline({
			collections,
			sortOrder,
		});

		setSelectedCollections && setSelectedCollections(sortedCollections);
	};
};

export default genHandleSortDeadlineClickASC;

import openedMixin from '../mixins/openedMixin';
import closedMixin from '../mixins/closedMixin';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

const openDrawerWidth = 240;

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: openDrawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin({ theme, width: openDrawerWidth }),
		'& .MuiDrawer-paper': openedMixin({ theme, width: openDrawerWidth }),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default Drawer;

import { fabric } from 'fabric';

interface IPoints {
	x: number;
	y: number;
}

interface IConstructorOptions {
	points: IPoints;
}

class Line {
	api: fabric.Line;

	constructor({ points }: IConstructorOptions) {
		const { x, y } = points;
		this.api = new fabric.Line([x, y, x, y], {
			// Geometry
			originX: 'center',
			originY: 'center',
			// UI
			strokeWidth: 1,
			fill: 'red',
			stroke: 'red',
			hoverCursor: 'all-scroll',
			borderColor: 'transparent',
			backgroundColor: 'transparent',
			opacity: 1,
			// Controls
			selectable: true,
			hasControls: false,
			lockMovementX: false,
			lockMovementY: false,
		});
	}
}

export default Line;

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import { useEffect, useRef, useState } from 'react';
import FabricCanvas from '../../../../../../sdks/FabricCanvas';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

const useStyles = makeStyles()((theme) => ({
	canvasContainer: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		justifyItems: 'center',
		width: '100%',
		height: '80vh',
		// border: '1px solid red',
	},
	canvas: {
		border: '2px dashed #ccc',
		borderRadius: '10px',
	},
}));

const FabricCanvasComponent = () => {
	const [fabricCanvas, setFabricCanvas] = useState<FabricCanvas | null>(null);
	const canvasRef = useRef(null);
	const { classes } = useStyles();
	const appDispatch = useAppDispatch();
	const imageUrl =
		'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/tech-packs/bw-sketches/pant.png';
	const canvasStateCurrent = useAppSelector(
		(state) => state.canvasState.canvasState.current
	);
	const canvasState = useAppSelector((state) => state.canvasState);
	const currentShapeCount = canvasState.shapesState.count();
	const canvasContainer = document.getElementById('canvas-container');

	useEffect(() => {
		if (!fabricCanvas && imageUrl && canvasRef && canvasContainer) {
			const fabricCanvasInstance = new FabricCanvas({
				canvasRef,
				appDispatch,
				canvasContainer,
			});

			fabricCanvasInstance.init({ imageUrl });
			setFabricCanvas(fabricCanvasInstance);
		}

		return () => {
			fabricCanvas?.tearDown();
		};
	}, [fabricCanvas, imageUrl, canvasRef, canvasContainer, appDispatch]);

	useEffect(() => {
		if (fabricCanvas && canvasStateCurrent) {
			fabricCanvas.renderState({ state: canvasStateCurrent });
			fabricCanvas.setNextText({ text: currentShapeCount });
		}
	}, [fabricCanvas, currentShapeCount, canvasStateCurrent]);

	return (
		<Box id="canvas-container" className={classes.canvasContainer}>
			<canvas ref={canvasRef} className={classes.canvas} />
		</Box>
	);
};

export default FabricCanvasComponent;

import capitalizeStrings from './capitalizeStrings';
import splitStringFromKebabCase from './splitStringKebabCase';
import getFirstPathFromPathname from './getFirstPathFromPathname';

interface IOptions {
	pathname: string;
}

const getTitleFromPathname = ({ pathname }: IOptions) => {
	const firstPath = getFirstPathFromPathname({ pathname });
	const firstPathParts = splitStringFromKebabCase({ string: firstPath });
	const capitalizedPathnames = capitalizeStrings({ strings: firstPathParts });

	return capitalizedPathnames.join(' ');
};

export default getTitleFromPathname;

import { useContext } from 'react';
import Menu from '@mui/material/Menu';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Sort } from '../../../../../common/enums';
import ListItemText from '@mui/material/ListItemText';
import { ITechPack } from '../../../../../common/interfaces';
import TechPacksConext from '../../../../../contexts/TechPacksContext';
import { stopEventPropagation } from '../../../../../common/eventHandlers';
import {
	genHandleSortClearClick,
	genHandleSortDeadlineClick,
} from '../genClickHandlers';

const useStyles = makeStyles()((theme) => ({
	menuList: {
		'&:focus-visible': {
			outline: '0 solid transparent',
		},
	},
}));

interface IProps {
	techPacks: ITechPack[];
	menuOpen: boolean;
	setMenuAnchorEl: CallableFunction;
	setSelectedSort: CallableFunction;
	handleSortClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	anchorMenuEl: null | HTMLElement;
}

const MenuComponent = ({
	techPacks,
	menuOpen,
	setMenuAnchorEl,
	setSelectedSort,
	handleSortClick,
	anchorMenuEl,
}: IProps) => {
	const { classes } = useStyles();
	const techPacksApi = useContext(TechPacksConext);
	const { setSelectedTechPacks } = techPacksApi;

	const handleSortDeadlineClickASC = genHandleSortDeadlineClick({
		techPacks,
		setMenuAnchorEl,
		setSelectedSort,
		setSelectedTechPacks,
		sortOrder: Sort.ASC,
	});

	const handleSortDeadlineClickDESC = genHandleSortDeadlineClick({
		techPacks,
		setMenuAnchorEl,
		setSelectedSort,
		setSelectedTechPacks,
		sortOrder: Sort.DESC,
	});

	const handleSortClearClick = genHandleSortClearClick({
		setMenuAnchorEl,
		setSelectedSort,
		setSelectedTechPacks,
	});

	return (
		<Menu
			id="card-options-button"
			aria-labelledby="card-options-button"
			anchorEl={anchorMenuEl}
			open={menuOpen}
			onClose={handleSortClick}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<MenuList
				className={classes.menuList}
				onClick={stopEventPropagation}
			>
				<MenuItem onClick={handleSortDeadlineClickASC}>
					<ListItemText>Deadline (ASC)</ListItemText>
				</MenuItem>
				<MenuItem onClick={handleSortDeadlineClickDESC}>
					<ListItemText>Deadline (DESC)</ListItemText>
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleSortClearClick}>
					<ListItemText>Clear</ListItemText>
				</MenuItem>
			</MenuList>
		</Menu>
	);
};

export default MenuComponent;

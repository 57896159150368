import { Theme, CSSObject } from '@mui/material/styles';

interface IProps {
	theme: Theme;
	width: number;
}

const openedMixin = ({ theme, width }: IProps): CSSObject => ({
	width,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

export default openedMixin;

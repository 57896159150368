class ActionState {
	isDrawing: boolean;
	isSelecting: boolean;

	constructor() {
		this.isDrawing = false;
		this.isSelecting = false;
	}

	setSelected = (isSelecting: boolean) => {
		this.isSelecting = isSelecting;
	};

	setDrawing = (isDrawing: boolean) => {
		this.isDrawing = isDrawing;
	};
}

export default ActionState;

import { Sort } from '../../../../../common/enums';
import { ICollection } from '../../../../../common/interfaces';

interface IOptions {
	sortOrder: Sort;
	collections: ICollection[];
}

const sortByDealine = ({ collections, sortOrder }: IOptions) => {
	const sortedCollections = [...collections].sort(
		(collectionA: ICollection, collectionB: ICollection) => {
			let sort = 0;
			const collectionADeadline = new Date(
				collectionA.deadline as unknown as Date
			);
			const collectionBDeadline = new Date(
				collectionB.deadline as unknown as Date
			);
			const collectionPackADealineTimestamp =
				collectionADeadline.getTime();
			const collectionPackBDealineTimestamp =
				collectionBDeadline.getTime();

			if (sortOrder === Sort.ASC) {
				sort =
					collectionPackADealineTimestamp -
					collectionPackBDealineTimestamp;
			} else if (sortOrder === Sort.DESC) {
				sort =
					collectionPackBDealineTimestamp -
					collectionPackADealineTimestamp;
			}

			return sort;
		}
	);

	return sortedCollections;
};

export default sortByDealine;

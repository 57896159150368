import { VALID_JOINS, VALID_CHARS, TRIGGERS } from './helpers';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
	'(^|\\s|\\()(' +
		'[' +
		TRIGGERS +
		']' +
		'((?:' +
		VALID_CHARS +
		VALID_JOINS +
		'){0,' +
		LENGTH_LIMIT +
		'})' +
		')$'
);

export default AtSignMentionsRegex;

import Line from '../Line';
import Text from '../Text';
import Circle from '../Circle';
import { fabric } from 'fabric';
import Triangle from '../Triangle';
import { v4 as uuidv4 } from 'uuid';
import { IShape } from '../../../interfaces';

const { Group } = fabric;

interface IPoints {
	x: number;
	y: number;
}

interface IConstructorOptions {
	points: IPoints;
	text: string | number | undefined;
}

interface IUpdatePositionOptions {
	x: number;
	y: number;
}

class LineArrow implements IShape {
	id: string;
	name: string;
	textContent: string;
	line: Line;
	text: Text;
	circle: Circle;
	arrow: Triangle;
	group?: fabric.Group;
	fabricItems: (fabric.Line | fabric.Triangle)[];

	constructor({ points, text }: IConstructorOptions) {
		const textContent = text ? text.toString() : '';

		this.id = uuidv4();
		this.name = 'LineArrow';
		this.textContent = textContent;
		this.line = new Line({ points });
		this.text = new Text({ text: textContent, points });
		this.arrow = new Triangle({ points });
		this.circle = new Circle({ points });

		this.fabricItems = [
			this.line.api,
			this.arrow.api,
			this.circle.api,
			this.text.api,
		];
	}

	setCoords = () => {
		this.fabricItems.forEach((fabricItem) => {
			fabricItem?.setCoords();
		});
	};

	updatePosition = ({ x, y }: IUpdatePositionOptions) => {
		const x1 = this.line.api.x1;
		const y1 = this.line.api.y1;
		const x2 = x;
		const y2 = y;

		if (x1 && y1) {
			this.line.api.set({
				x2,
				y2,
			});

			this.circle.api.set({
				left: x,
				top: y,
			});

			this.text.api.set({
				left: x + 0.4,
				top: y + 0.4,
			});

			this.arrow.setAngle({
				x1,
				y1,
				x2,
				y2,
			});
		}
	};

	groupFabricItems = () => {
		this.group = new Group(
			[this.line.api, this.arrow.api, this.circle.api, this.text.api],
			{
				name: this.name,
				data: {
					id: this.id,
					name: this.name,
					textContent: this.textContent,
				},
				// UI
				hoverCursor: 'all-scroll',
				borderColor: 'transparent',
				backgroundColor: 'transparent',
				// Controls
				selectable: true,
				hasControls: false,
				lockMovementX: false,
				lockMovementY: false,
				subTargetCheck: true,
			}
		);

		return this.group;
	};
}

export default LineArrow;

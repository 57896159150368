import Section from './Section';
import Box from '@mui/material/Box';
import { MouseEventHandler } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	blackWhiteSketchContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(10),
	},
	blackWhiteSketchImageContainer: {},
	blackWhiteSketchImage: {
		maxHeight: '600px',
	},
	buttonAnnotate: {
		position: 'absolute',
		right: '10px',
		top: '50px',
	},
}));

interface IProps {
	handleButtonClick: MouseEventHandler<HTMLButtonElement>;
}

const BlackWhiteSketch = ({ handleButtonClick }: IProps) => {
	const { classes } = useStyles();

	return (
		<Section header="Black and White Sketch">
			<Button
				variant="contained"
				className={classes.buttonAnnotate}
				onClick={handleButtonClick}
			>
				Annotate
			</Button>
			<Box className={classes.blackWhiteSketchContainer}>
				<Box className={classes.blackWhiteSketchImageContainer}>
					<img
						className={classes.blackWhiteSketchImage}
						src="https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/tech-packs/bw-sketches/pant.png"
						alt=""
					/>
				</Box>
			</Box>
		</Section>
	);
};

export default BlackWhiteSketch;

import { fabric } from 'fabric';
import theme from '../../../../../theme';

interface IPoints {
	x: number;
	y: number;
}

interface IConstructorOptions {
	text: string;
	points: IPoints;
}

interface IUpdateAngleOptions {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
}

class Text {
	api: fabric.Text;

	constructor({ text, points }: IConstructorOptions) {
		const { x, y } = points;
		const { fontFamily } = theme.typography;

		this.api = new fabric.Text(text, {
			// Geometry
			left: x,
			top: y,
			originX: 'center',
			originY: 'center',
			// UI
			fontFamily: fontFamily?.replace(';', ''),
			fontSize: 9,
			fontWeight: 'lighter',
			textAlign: 'center',
			fill: 'white',
			stroke: 'white',
			hoverCursor: 'all-scroll',
			hasBorders: true,
			borderColor: 'white',
			backgroundColor: 'transparent',
			opacity: 1,
			// Controls
			selectable: true,
			hasControls: false,
			lockMovementX: false,
			lockMovementY: false,
		});
	}

	setAngle = ({ x1, x2, y1, y2 }: IUpdateAngleOptions) => {
		let angle;
		const verticalHeight = Math.abs(y2 - y1);
		const horizontalWidth = Math.abs(x2 - x1);
		const tangentRaio = verticalHeight / horizontalWidth;
		const basicAngle = (Math.atan(tangentRaio) * 180) / Math.PI;
		const offset = -90;

		if (x2 > x1) {
			if (y2 < y1) {
				angle = -basicAngle + offset;
			} else if (y2 === y1) {
				angle = 0 + offset;
			} else if (y2 > y1) {
				angle = basicAngle + offset;
			}
		} else if (x2 < x1) {
			if (y2 > y1) {
				angle = 180 - basicAngle + offset;
			} else if (y2 === y1) {
				angle = 180 + offset;
			} else if (y2 < y1) {
				angle = 180 + basicAngle + offset;
			}
		}

		if (angle) {
			this.api.set({ angle });
		}
	};
}

export default Text;

import { FiX } from 'react-icons/fi';
import { MouseEventHandler } from 'react';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Annotations from './components/Annotations';
import FabricCanvas from './components/FabricCanvas';

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		// border: '1px solid blue',
	},
	closeButton: {
		position: 'absolute',
		top: '20px',
		right: '40px',
		cursor: 'pointer',
	},
}));

interface IProps {
	handleExitClick: MouseEventHandler;
}

const Annotation = ({ handleExitClick }: IProps) => {
	const { classes } = useStyles();

	return (
		<Container className={classes.container} maxWidth="xl">
			<FiX
				size={30}
				className={classes.closeButton}
				onClick={handleExitClick}
			/>
			<FabricCanvas />
			<Annotations />
		</Container>
	);
};

export default Annotation;

import environmentConfig from '../../common/constants/environmentConfig';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
const { serverBaseUrl } = environmentConfig[environment];

const link = createHttpLink({
	credentials: 'include',
	uri: `${serverBaseUrl}/graphql`,
});

const client = new ApolloClient({
	link,
	uri: `${serverBaseUrl}/graphql`,
	cache: new InMemoryCache(),
});

export default client;

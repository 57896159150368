import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import CollectionCard from './CollectionCard';
import SearchSortFilter from './SearchSortFilter';
import { Link as ReactLink } from 'react-router-dom';
import { ICollection } from '../../../common/interfaces';
import CollectionsConext from '../../../contexts/CollectionsContext';
import { useFindAllCollectionsWithTechPacksQuery } from '../../api/generated';
import { selectAuthentication } from '../../../redux/slices/authenticationSlice';

const useStyles = makeStyles()((theme) => ({
	collectionsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	searchSortFilterContainer: {
		display: 'flex',
		width: '100%',
		marginBottom: theme.spacing(6),
	},
}));

const Collections = () => {
	const { classes } = useStyles();
	const [selectedCollections, setSelectedCollections] = useState<
		ICollection[] | []
	>([]);

	const collectionsApi = { setSelectedCollections };

	const authentication = useSelector(selectAuthentication);
	const { user } = authentication;
	const { data } = useFindAllCollectionsWithTechPacksQuery({
		findAllCollectionsInput: {
			where: {
				userId: user?.id,
			},
		},
	});

	const collections = data?.findAllCollections;
	let filteredCollections: ICollection[];

	if (selectedCollections.length > 0) {
		filteredCollections = selectedCollections;
	} else if (
		Array.isArray(data?.findAllCollections) &&
		typeof data?.findAllCollections?.length === 'number' &&
		data?.findAllCollections?.length > 0
	) {
		filteredCollections = data?.findAllCollections as ICollection[];
	} else {
		filteredCollections = [] as ICollection[];
	}

	const collectionsComponents = filteredCollections?.map(
		(collection: ICollection) => {
			const { id } = collection;
			const link = `/collections/${id}`;

			return (
				<Link
					to={link}
					key={id}
					color="primary"
					underline="none"
					component={ReactLink}
				>
					<CollectionCard collection={collection} />
				</Link>
			);
		}
	);

	return (
		<CollectionsConext.Provider value={collectionsApi}>
			<SearchSortFilter
				collections={collections}
				filteredCollections={filteredCollections}
			/>

			<Box className={classes.collectionsContainer}>
				{collectionsComponents}
			</Box>
		</CollectionsConext.Provider>
	);
};

export default Collections;

import { Action } from './enums';
import CanvasModel from '../CanvasModel';
import { ICompareStatesOptions } from './interfaces';

class CanvasUtils {
	static diffArrays = ({
		baseArray,
		compareArray,
	}: {
		baseArray: string[];
		compareArray: string[];
	}) => {
		const baseSet = new Set(baseArray);
		const compareSet = new Set(compareArray);

		const baseToCompareDiff = [
			...new Set(baseArray.filter((string) => !compareSet.has(string))),
		];

		const compareToBaseDiff = [
			...new Set(compareArray.filter((string) => !baseSet.has(string))),
		];

		return [...baseToCompareDiff, ...compareToBaseDiff];
	};

	static getCanvasObjectDiffId = ({
		baseArray,
		compareArray,
	}: {
		baseArray: string[];
		compareArray: string[];
	}) => {
		const arrayDiffs = this.diffArrays({
			baseArray,
			compareArray,
		});

		if (arrayDiffs.length > 1) {
			throw new Error(
				'getCanvasObjectDiffId::Returned diff has length greater than 1!'
			);
		}

		return arrayDiffs[0];
	};

	static compareStates = ({
		baseState,
		compareState,
	}: ICompareStatesOptions) => {
		let action: Action | undefined;
		let objectId: string | undefined;

		const baseStateModel = new CanvasModel({ state: baseState });
		const compareStateModel = new CanvasModel({ state: compareState });
		const baseStateObjects = baseStateModel.getObjectIdsAsArray();
		const compareStateObjects = compareStateModel.getObjectIdsAsArray();
		const baseStateObjectsCount = baseStateObjects.length;
		const compareStateObjectsCount = compareStateObjects.length;

		if (baseStateObjectsCount === compareStateObjectsCount) {
			action = Action.Modified;
			objectId = this.getCanvasObjectDiffId({
				baseArray: baseStateObjects,
				compareArray: compareStateObjects,
			});
		}

		if (baseStateObjectsCount < compareStateObjectsCount) {
			action = Action.Added;
			objectId = this.getCanvasObjectDiffId({
				baseArray: baseStateObjects,
				compareArray: compareStateObjects,
			});
		}

		if (baseStateObjectsCount > compareStateObjectsCount) {
			action = Action.Removed;
			objectId = this.getCanvasObjectDiffId({
				baseArray: baseStateObjects,
				compareArray: compareStateObjects,
			});
		}

		return { action, objectId };
	};
}

export default CanvasUtils;

interface ITaskColors {
	[key: string]: string;
}

const taskColors: ITaskColors = {
	red: '#fb4b50',
	orange: '#fb7f27',
	aqua: '#59debc',
	purple: '#a96fdf',
	lightBlue: '#2b9fe2',
};

export default taskColors;

import { createTheme } from '@mui/material/styles';
import { textColors, defaultColors, themeColors } from './colors';

// define custom colors: https://material-ui.com/customization/palette/
declare module '@mui/material/styles/createPalette' {
	interface Palette {
		heatherGrey: Palette['primary'];
		lightestGrey: Palette['primary'];
	}
	interface PaletteOptions {
		heatherGrey: PaletteOptions['primary'];
		lightestGrey: PaletteOptions['primary'];
	}
}

declare module '@mui/material' {
	interface ButtonPropsColorOverrides {
		heatherGrey: true;
	}
	interface TextFieldPropsColorOverrides {
		heatherGrey: true;
	}
}

const { heatherGrey, lightestGrey } = themeColors;

const {
	errorMain,
	primaryLight,
	primaryMain,
	primaryDark,
	secondaryMain,
	backgroundDefault,
} = defaultColors;

const { primaryText } = textColors;

const theme = createTheme({
	typography: {
		fontFamily: `Montserrat, Helvetica, Arial, sans-serif;`,
		body1: {
			fontFamily: `Montserrat, Helvetica, Arial, sans-serif;`,
		},
		body2: {
			fontFamily: `Montserrat, Helvetica, Arial, sans-serif;`,
		},
	},
	palette: {
		primary: {
			light: primaryLight,
			main: primaryMain,
			dark: primaryDark,
		},
		secondary: {
			main: secondaryMain,
		},
		error: {
			main: errorMain,
		},
		background: {
			default: backgroundDefault,
		},
		text: {
			primary: primaryText,
		},
		heatherGrey: {
			light: heatherGrey,
			main: heatherGrey,
			dark: heatherGrey,
			contrastText: heatherGrey,
		},
		lightestGrey: {
			light: lightestGrey,
			main: lightestGrey,
			dark: lightestGrey,
			contrastText: lightestGrey,
		},
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: primaryMain,
					},
					'&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: '#cc0101 !important',
					},
				},
			},
		},
	},
});

export default theme;

import { useContext } from 'react';
import { SyntheticEvent } from 'react';
import { Search } from 'react-feather';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { ICollection } from '../../../../common/interfaces';
import CollectionSearchItem from './CollectionSearchItem';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CollectionsConext from '../../../../contexts/CollectionsContext';

const useStyles = makeStyles()((theme) => ({
	collectionsAutocompleteContainer: {},
	autoCompleteListBox: {
		padding: '0',
	},
	renderOptionLi: {
		background: 'transparent',
		'&:hover': {
			background: 'transparent',
		},
	},
}));

interface IProps {
	options: ICollection[];
	className: string;
}

const CollecionsAutoComplete = ({ options, className }: IProps) => {
	const { classes } = useStyles();
	const collectionsApi = useContext(CollectionsConext);
	const { setSelectedCollections } = collectionsApi;

	return (
		<Autocomplete
			id="collections-autocomplete"
			options={options}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option.name || ''}
			className={`${classes.collectionsAutocompleteContainer} ${className}`}
			classes={{
				listbox: `${classes.autoCompleteListBox}`,
			}}
			onChange={(
				event: SyntheticEvent,
				selectedCollection: ICollection | null
			) => {
				if (setSelectedCollections && selectedCollection) {
					setSelectedCollections([selectedCollection as ICollection]);
				} else if (setSelectedCollections) {
					setSelectedCollections([]);
				}
			}}
			renderOption={(props, option) => (
				<li
					{...props}
					key={option.id}
					className={classes.renderOptionLi}
				>
					<CollectionSearchItem collection={option} />
					<Divider />
				</li>
			)}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						placeholder="E.g. Winter - 2023"
						label="Search for Collection"
						InputProps={{
							...params.InputProps,
							type: 'text',
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							),
						}}
					/>
				);
			}}
		/>
	);
};

export default CollecionsAutoComplete;

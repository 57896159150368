import { IColumn } from '../interfaces';

const columns: IColumn[] = [
	{
		id: 'pom',
		label: 'POM',
		// minWidth: 170
	},
	{
		id: 'description',
		label: 'Description',
		// minWidth: 100
	},
	{
		id: 'tolerance',
		label: 'Tolerance (+/-)',
		// minWidth: 170,
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'xs',
		label: 'XS',
		// minWidth: 170,
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 's',
		label: 'S',
		// minWidth: 170,
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'm',
		label: 'M',
		// minWidth: 170,
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'l',
		label: 'L',
		// minWidth: 170,
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'xl',
		label: 'XL',
		// minWidth: 170,
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'notes',
		label: 'Notes',
		// minWidth: 170,
		align: 'center',
		format: (value: number) => value.toLocaleString('en-US'),
	},
];

export default columns;

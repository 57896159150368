import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { makeStyles } from 'tss-react/mui';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { IRow, IColumn } from './interfaces';
import TableContainer from '@mui/material/TableContainer';

const useStyles = makeStyles()((theme) => ({
	specificationContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(10),
	},
	tableHeaderCell: {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
	},
	tableCell: {
		'&:last-child': {
			borderRight: '1px solid rgba(0, 0, 0, 0)',
		},
		borderRight: '1px solid rgba(224, 224, 224, 1)',
	},
}));

interface IProps {
	rows?: IRow[];
	columns?: IColumn[];
}

const DataTable = ({ rows = [], columns = [] }: IProps) => {
	const { classes } = useStyles();

	return (
		<Paper elevation={2} sx={{ width: '100%', overflow: 'hidden' }}>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									className={`${classes.tableHeaderCell} ${classes.tableCell}`}
									style={{
										minWidth: column.minWidth,
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => {
							return (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={row.id}
								>
									{columns.map((column) => {
										const value = row[column.id];
										return (
											<TableCell
												key={column.id}
												align={column.align}
												className={classes.tableCell}
											>
												{column.format &&
												typeof value === 'number'
													? column.format(value)
													: value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default DataTable;

import { TypeaheadOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';

class MentionTypeaheadOption extends TypeaheadOption {
	name: string;
	picture: JSX.Element;

	constructor(name: string, picture: JSX.Element) {
		super(name);
		this.name = name;
		this.picture = picture;
	}
}

export default MentionTypeaheadOption;

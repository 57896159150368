import { useState } from 'react';
import Logo from './components/Logo';
import Drawer from './components/Drawer';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import MainNav from './components/MainNav';
import { useNavigate } from 'react-router-dom';
import FooterNav from './components/FooterNav';
import { useAppDispatch } from '../../../../hooks';
import { SIGN_OUT } from '../../../../queries/sessions';
import { apolloClient } from '../../../../services/apollo';
import {
	revokeAuthentication,
	selectAuthentication,
} from '../../../../redux/slices/authenticationSlice';

const drawerBackgroundColor = '#1e1b34';

const useStyles = makeStyles()((theme) => ({
	drawerPaper: {
		backgroundColor: drawerBackgroundColor,
	},
}));

const SideBar = () => {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const appDispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const authentication = useSelector(selectAuthentication);
	const { user } = authentication;

	const handleSignOut = async (event: any) => {
		await apolloClient.mutate({
			mutation: SIGN_OUT,
			variables: {
				userId: user?.id,
			},
		});

		appDispatch(revokeAuthentication());
		navigate('/', { replace: true });
	};

	const toggleDrawerOpen = () => {
		if (open) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	};

	return (
		<Drawer
			open={open}
			variant="permanent"
			classes={{ paper: classes.drawerPaper }}
		>
			<Logo open={open} onClick={toggleDrawerOpen} />
			<MainNav open={open} />
			<FooterNav open={open} handleSignOut={handleSignOut} />
		</Drawer>
	);
};

export default SideBar;

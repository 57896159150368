import moment from 'moment';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { FiOctagon, FiCheckCircle } from 'react-icons/fi';
import { ITechPack } from '../../../../../common/interfaces';

const useStyles = makeStyles()((theme) => ({
	techPackDetailsContainer: {
		display: 'flex',
		height: '100px',
		flexDirection: 'column',
		justifyItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
		paddingBottom: theme.spacing(0),
	},
	techPackDetailContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
	},
	techPackDetailHeader: {
		flexDirection: 'column',
		alignItems: 'center',
		alignContent: 'center',
		placeSelf: 'center',
		marginBottom: 'auto',
	},
	techPackDetailIconContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		marginRight: '7px',
	},
	techPackDetailIcon: {
		color: 'rgba(0, 0, 0, 0.12)',
	},
	iconWarnColor: {
		color: theme.palette.warning.main,
	},
	iconSuccessColor: {
		color: theme.palette.success.main,
	},
	techPackDetailIconDeadline: {
		color: theme.palette.warning.main,
	},
	techPackDetailsName: {
		fontWeight: 'bold',
		fontSize: '1.1rem',
	},
}));

interface IProps {
	techPack: ITechPack;
	actionItemCount: number;
}

const CardDetails = ({ techPack, actionItemCount }: IProps) => {
	const { classes } = useStyles();
	const { deadline, hasBeenFit, isReady } = techPack;
	const deadlineFormatted = moment(deadline).format('l');
	const hasBeenFitted = hasBeenFit ? 'Has been fitted' : 'Needs fitting';

	return (
		<div className={classes.techPackDetailsContainer}>
			{/* {isReady && ( */}
			<Box className={`${classes.techPackDetailContainer}`}>
				<Typography className={classes.techPackDetailIconContainer}>
					{isReady ? (
						<FiCheckCircle
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconSuccessColor}`}
						/>
					) : (
						<FiOctagon
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconWarnColor}`}
						/>
					)}
				</Typography>
				<Typography>Is Ready</Typography>
			</Box>
			{/* )} */}
			<Box className={classes.techPackDetailContainer}>
				<Typography className={classes.techPackDetailIconContainer}>
					{isReady ? (
						<FiCheckCircle
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconSuccessColor}`}
						/>
					) : (
						<FiOctagon
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconWarnColor}`}
						/>
					)}
				</Typography>
				<Typography>{hasBeenFitted}</Typography>
			</Box>

			<Box className={classes.techPackDetailContainer}>
				<Typography className={classes.techPackDetailIconContainer}>
					{isReady ? (
						<FiCheckCircle
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconSuccessColor}`}
						/>
					) : (
						<FiOctagon
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconWarnColor}`}
						/>
					)}
				</Typography>
				<Typography>Action Items ({actionItemCount})</Typography>
			</Box>

			{/* {!isReady && ( */}
			<Box className={`${classes.techPackDetailContainer}`}>
				<Typography className={classes.techPackDetailIconContainer}>
					{isReady ? (
						<FiCheckCircle
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconSuccessColor}`}
						/>
					) : (
						<FiOctagon
							size={18}
							className={`${classes.techPackDetailIcon} ${classes.iconWarnColor}`}
						/>
					)}
				</Typography>
				<Typography>Deadline: {deadlineFormatted}</Typography>
			</Box>
			{/* )} */}
		</div>
	);
};

export default CardDetails;

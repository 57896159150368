import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import TechPacksSort from '../TechPacksSort';
import Grid from '@mui/material/Unstable_Grid2';
import TechPacksFilter from '../TechPacksFilter';
import { ITechPack } from '../../../../common/interfaces';
import TechPacksAutocomplete from '../TechPacksAutocomplete';

const useStyles = makeStyles()((theme) => ({
	searchSortFilterContainer: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(6),
	},
	sortFilterContainer: {
		marginTop: theme.spacing(1),
	},
	gridItem: {
		flexGrow: '1',
	},
}));

interface IProps {
	techPacks: ITechPack[] | undefined;
	filteredTechPacks: ITechPack[] | undefined;
}

const SearchSortFilter = ({ techPacks = [], filteredTechPacks }: IProps) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.searchSortFilterContainer}>
			<Grid container spacing={2}>
				<Grid
					xs={6}
					display="flex"
					alignItems="center"
					alignContent="center"
				>
					<TechPacksAutocomplete
						options={techPacks}
						className={classes.gridItem}
					/>
				</Grid>
				<Grid
					xs={3}
					display="flex"
					alignItems="center"
					alignContent="center"
				>
					<TechPacksFilter
						techPacks={techPacks}
						className={classes.gridItem}
					/>
				</Grid>
				<Grid
					xs={3}
					display="flex"
					alignItems="center"
					alignContent="center"
				>
					<TechPacksSort
						techPacks={filteredTechPacks || techPacks}
						className={classes.gridItem}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SearchSortFilter;

import { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import { FiCalendar } from 'react-icons/fi';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles()((theme) => ({
	taskFormContainer: {
		backgroundColor: 'white',
		padding: theme.spacing(2),
		color: theme.palette.heatherGrey.main,
	},
	taskFormHeader: {
		width: '40%',
		textAlign: 'center',
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(5),
		marginLeft: 'auto',
		marginRight: 'auto',
		fontSize: '1.2rem',
	},
	taskFormHeaderDivider: {},
	taskTitle: {
		width: '100%',
		color: theme.palette.heatherGrey.main,
		marginBottom: theme.spacing(3),
		'& label.Mui-focused': {
			color: theme.palette.heatherGrey.main,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: theme.palette.lightestGrey.main,
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: theme.palette.lightestGrey.main,
			},
			'&:hover fieldset': {
				borderColor: theme.palette.heatherGrey.main,
			},
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.lightestGrey.main,
			},
		},
	},
	taskDescription: {
		width: '100%',
		color: theme.palette.heatherGrey.main,
		marginBottom: theme.spacing(5),
		'& label.Mui-focused': {
			color: theme.palette.heatherGrey.main,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: theme.palette.lightestGrey.main,
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: theme.palette.lightestGrey.main,
			},
			'&:hover fieldset': {
				borderColor: theme.palette.heatherGrey.main,
			},
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.lightestGrey.main,
			},
		},
	},
	buttonsContainer: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'space-between',
	},
	buttonDueDate: {
		borderRadius: '10px',
		textTransform: 'none',
	},
	buttonDueDateIcon: {},
	buttonAssignTo: {
		borderRadius: '10px',
		textTransform: 'none',
	},
	buttonCancel: {
		borderRadius: '10px',
		textTransform: 'none',
	},
	buttonSubmit: {
		borderRadius: '10px',
		textTransform: 'none',
	},
	buttonGroup: {
		'& button:first-of-type': {
			marginRight: '5px',
		},
	},
}));

interface IProps {
	buttonSize?: 'small' | 'medium' | 'large';
	handleAddTaskClick?: (event: MouseEvent<HTMLElement>) => void;
}

const TaskForm = ({ buttonSize = 'medium', handleAddTaskClick }: IProps) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.taskFormContainer}>
			<Box className={classes.taskFormHeader}>New Task</Box>
			<Box sx={{ width: '100%' }}>
				<TextField
					label="Task Name"
					focused
					color="heatherGrey"
					className={classes.taskTitle}
				/>
			</Box>
			<Box sx={{ width: '100%' }}>
				<TextField
					label="Task Description"
					multiline
					rows={4}
					focused
					color="heatherGrey"
					className={classes.taskDescription}
				/>
			</Box>
			<Box className={classes.buttonsContainer}>
				<Box className={classes.buttonGroup}>
					<Button
						startIcon={
							<FiCalendar
								size={18}
								className={classes.buttonDueDateIcon}
							/>
						}
						size={buttonSize}
						disableRipple={true}
						variant="outlined"
						color="heatherGrey"
						className={classes.buttonDueDate}
					>
						Due Date
					</Button>
					<Button
						size={buttonSize}
						disableRipple={true}
						variant="outlined"
						color="heatherGrey"
						className={classes.buttonAssignTo}
					>
						Assign To
					</Button>
				</Box>
				<Box className={classes.buttonGroup}>
					<Button
						size={buttonSize}
						disableRipple={true}
						variant="outlined"
						color="heatherGrey"
						className={classes.buttonCancel}
					>
						Cancel
					</Button>
					<Button
						size={buttonSize}
						disableRipple={true}
						variant="contained"
						color="secondary"
						onClick={handleAddTaskClick}
						className={classes.buttonSubmit}
					>
						Add Task
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default TaskForm;

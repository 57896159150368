import { FittingCalendarEvent } from '../components';
import { IEvent } from '../../../components/Calendar/interfaces';

interface IEventInfo {
	event: IEvent;
}

const renderEventContent = (eventInfo: IEventInfo) => {
	const { event } = eventInfo;

	return <FittingCalendarEvent event={event} />;
};

export default renderEventContent;

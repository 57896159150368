import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';

const greyPruple = '#74778e';

const useStyles = makeStyles()((theme) => ({
	mentionCardContainer: {
		display: 'flex',
		marginBottom: '20px',
		padding: theme.spacing(3),
	},
	mention: {
		color: theme.palette.secondary.main,
	},
}));

interface IProps {
	mention: any;
}

const Mention = ({ mention }: IProps) => {
	const { classes } = useStyles();
	const { user } = mention;

	return (
		<Paper className={classes.mentionCardContainer}>
			<Box sx={{ color: greyPruple }}>
				Hi <span className={classes.mention}>{user}</span>! Curious what
				you think about the new design of the leather jacket we put out.
			</Box>
		</Paper>
	);
};

export default Mention;

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { TaskCard, TabPannel } from './components';
import { Link as ReactLink } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
	pageHeader: {
		display: 'flex',
		height: '50px',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		marginTop: '30px',
		marginBottom: '30px',
	},
	pageHeaderPageTitle: {
		color: theme.palette.primary.main,
		fontSize: '2rem',
	},
	buttonIcon: {
		marginRight: '5px',
	},
}));

const openTasks = [
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
];

const closedTasks = [
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
	{
		id: 0,
		title: 'Lets fix this thing',
		description:
			'We have this thing, and it is broken, we will need you to fix it.',
		department: 'Finance',
		dueDate: '1/31/2023',
		user: {
			firstName: 'Sarah',
			lastName: 'Peterson',
			imageUrl:
				'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/users/jennifer-aniston.gif',
		},
	},
];

const Tasks = () => {
	const { classes } = useStyles();

	const openTaskComponents = openTasks.map((task) => {
		const { id } = task;

		return (
			<Link to={`/tasks/${id}`} underline="none" component={ReactLink}>
				<TaskCard task={task} />
			</Link>
		);
	});

	const closedTaskComponents = closedTasks.map((task) => {
		const { id } = task;

		return (
			<Link to={`/tasks/${id}`} underline="none" component={ReactLink}>
				<TaskCard task={task} />
			</Link>
		);
	});

	return (
		<Box sx={{ width: '100%' }}>
			<Box className={classes.pageHeader}>
				<Typography className={classes.pageHeaderPageTitle}>
					Tasks
				</Typography>
			</Box>

			<Box>
				<Container maxWidth="xl">
					<TabPannel
						openTasks={openTaskComponents}
						closedTasks={closedTaskComponents}
					/>
				</Container>
			</Box>
		</Box>
	);
};

export default Tasks;

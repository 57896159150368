import { fabric } from 'fabric';
import { toJson } from './utils';
import { MutableRefObject } from 'react';

interface IConstructorOptions {
	canvasRef: MutableRefObject<null>;
	canvasContainer: HTMLElement | null;
}

interface IRenderStateOptions {
	state: string | null;
}

type IFabricCanvas = fabric.Canvas;

interface ICanvas extends IFabricCanvas {
	contextContainer?: any;
}

class Canvas {
	api: ICanvas;
	containerWidth: number;
	containerHeight: number;

	constructor({ canvasRef, canvasContainer }: IConstructorOptions) {
		this.containerWidth = canvasContainer?.clientWidth || 0;
		this.containerHeight = canvasContainer?.clientHeight || 0;

		this.api = new fabric.Canvas(canvasRef.current, {
			selection: false,
			defaultCursor: 'crosshair',
			width: this.containerWidth,
			height: this.containerHeight,
		}) as ICanvas;
	}

	toJson = () => {
		return toJson({ canvas: this.api });
	};

	renderState = ({ state }: IRenderStateOptions) => {
		if (state) {
			this.api.loadFromJSON(state, () => {});
			this.api.requestRenderAll();
		}
	};
}

export default Canvas;

import Section from './Section';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	specDiagramsContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		justifyItems: 'center',
		alignContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(10),
	},
	specDiagramImageContainer: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	specDiagramImage: {
		maxHeight: '600px',
	},
	specDiagramDivider: {
		width: '100%',
	},
}));

const SpecDiagrams = () => {
	const { classes } = useStyles();
	const imageUrls = [
		'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/tech-packs/spec-diagrams/pant.png',
		'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/tech-packs/spec-diagrams/security-zipper.png',
		'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/tech-packs/spec-diagrams/loop-details.png',
		'https://facilery.s3.us-west-1.amazonaws.com/demo-assets/images/tech-packs/spec-diagrams/hand-pkt-bag.png',
	];

	const imageComponents = imageUrls.map((imageUrl, index) => {
		return (
			<Box key={index}>
				{index > 0 && (
					<Divider className={classes.specDiagramDivider} />
				)}
				<Box className={classes.specDiagramImageContainer}>
					<img
						className={classes.specDiagramImage}
						src={imageUrl}
						alt=""
					/>
				</Box>
			</Box>
		);
	});

	return (
		<Section header="Spec Diagrams">
			<Box className={classes.specDiagramsContainer}>
				{imageComponents}
			</Box>
		</Section>
	);
};

export default SpecDiagrams;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getTitleFromPathname } from '../../../../utils';
import { FiPlus } from 'react-icons/fi';

const useStyles = makeStyles()((theme) => ({
	// Page Layout
	workspaceHeader: {
		display: 'flex',
		height: '50px',
		justifyContent: 'space-between',
		alignContent: 'center',
		alignItems: 'center',
		padding: '0 30px',
	},
	workspaceHeaderPageTitle: {
		fontSize: '1.5rem',
	},
	workspaceContent: {
		padding: '30px',
	},
	buttonIcon: {
		marginRight: '5px',
	},
}));

interface IProps {
	children: JSX.Element;
}

const PageContentWithHeader = ({ children }: IProps) => {
	const { classes } = useStyles();
	const location = useLocation();
	const { pathname } = location;
	const pathnameCapitalized = getTitleFromPathname({ pathname });

	return (
		<Box sx={{ width: '100%' }}>
			<Box className={classes.workspaceHeader}>
				<Typography className={classes.workspaceHeaderPageTitle}>
					{pathnameCapitalized}
				</Typography>
				<Button variant="contained">
					<FiPlus className={classes.buttonIcon} /> Create New
				</Button>
			</Box>

			<Divider />

			<Box className={classes.workspaceContent}>{children}</Box>
		</Box>
	);
};

export default PageContentWithHeader;

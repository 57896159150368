import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
// import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
// import Typography from '@mui/material/Typography';
import NotificationCount from './NotificationCount';
import { Link as ReactLink } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Shape } from '../enums';
import ListItemButton from '@mui/material/ListItemButton';
import { FiGrid, FiLayers, FiUserPlus, FiAtSign } from 'react-icons/fi';
// import { BsListTask } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';

const fiIconSize = 22;
const drawerFontColor = '#fff';

const useStyles = makeStyles()((theme) => ({
	listItemText: {
		color: drawerFontColor,
		textDecoration: 'none',
	},
	listItemIconContainer: {
		position: 'relative',
	},
	notificationCountCircle: {
		position: 'absolute',
		top: '5px',
		right: '-14px',
	},
	listItemIcon: {
		color: drawerFontColor,
	},
	listItemButton: {
		position: 'relative',
		paddingRight: '15px',
		backgroundColor: 'transparent',
		borderRight: '2px solid transparent',
		'&:hover': {
			borderRight: '2px solid #fff',
		},
	},
	listSectionContainer: {
		margin: '10px 0',
	},
	listHeaderContainer: {
		marginLeft: '10px',
	},
	listHeader: {
		color: '#fff',
	},
	hideListHeader: {
		color: '#1e1b34',
	},
	// divider: {
	// 	background: drawerFontColor,
	// },
}));

const MainNav = ({ open }: { open: boolean }) => {
	const { classes } = useStyles();
	// const hideListHeaderClass = open ? '' : ''; //classes.hideListHeader;

	return (
		<>
			<Box className={classes.listSectionContainer}>
				{/* <Box className={classes.listHeaderContainer}>
					<Typography
						className={`${classes.listHeader} ${hideListHeaderClass}`}
					>
						Plan
					</Typography>
				</Box> */}

				<List>
					<Link
						to="/collections"
						underline="none"
						component={ReactLink}
					>
						<ListItem
							key="Collections"
							disablePadding
							sx={{ display: 'block' }}
						>
							<ListItemButton
								disableRipple
								className={classes.listItemButton}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<FiGrid
										size={fiIconSize}
										className={classes.listItemIcon}
									/>
								</ListItemIcon>
								<ListItemText
									primary="Collections"
									className={classes.listItemText}
									sx={{
										opacity: open ? 1 : 0,
									}}
								/>
							</ListItemButton>
						</ListItem>
					</Link>
					<Link
						to="/tech-packs"
						underline="none"
						component={ReactLink}
					>
						<ListItem
							key="Tech Packs"
							disablePadding
							sx={{ display: 'block' }}
						>
							<ListItemButton
								disableRipple
								className={classes.listItemButton}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<FiLayers
										size={fiIconSize}
										className={classes.listItemIcon}
									/>
								</ListItemIcon>
								<ListItemText
									primary="Tech Packs"
									className={classes.listItemText}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					</Link>
					<Link to="/fittings" underline="none" component={ReactLink}>
						<ListItem
							key="Fittings"
							disablePadding
							sx={{ display: 'block' }}
						>
							<ListItemButton
								disableRipple
								className={classes.listItemButton}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<FiUserPlus
										size={fiIconSize}
										className={classes.listItemIcon}
									/>
								</ListItemIcon>
								<ListItemText
									primary="Fittings"
									className={classes.listItemText}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					</Link>
				</List>
			</Box>

			{/* <Divider className={classes.divider} /> */}

			<Box className={classes.listSectionContainer}>
				{/* <Box className={classes.listHeaderContainer}>
					<Typography
						className={`${classes.listHeader} ${hideListHeaderClass}`}
					>
						Collab
					</Typography>
				</Box> */}

				<List>
					<Link to="/tasks" underline="none" component={ReactLink}>
						<ListItem
							key="Tasks"
							disablePadding
							sx={{ display: 'block' }}
						>
							<ListItemButton
								disableRipple
								className={classes.listItemButton}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
									className={classes.listItemIconContainer}
								>
									<AiOutlineUnorderedList
										size={fiIconSize}
										className={classes.listItemIcon}
									/>
									<NotificationCount
										count={5}
										shape={Shape.Circle}
										shouldDisplay={!open}
										className={
											classes.notificationCountCircle
										}
									/>
								</ListItemIcon>
								<ListItemText
									primary="Tasks"
									className={classes.listItemText}
									sx={{ opacity: open ? 1 : 0 }}
								/>
								<NotificationCount
									count={5}
									shouldDisplay={open}
								/>
							</ListItemButton>
						</ListItem>
					</Link>
					<Link to="/mentions" underline="none" component={ReactLink}>
						<ListItem
							key="Mentions"
							disablePadding
							sx={{ display: 'block' }}
						>
							<ListItemButton
								disableRipple
								className={classes.listItemButton}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
									className={classes.listItemIconContainer}
								>
									<FiAtSign
										size={fiIconSize}
										className={classes.listItemIcon}
									/>
									<NotificationCount
										count={10}
										shape={Shape.Circle}
										shouldDisplay={!open}
										className={
											classes.notificationCountCircle
										}
									/>
								</ListItemIcon>
								<ListItemText
									primary="Mentions"
									className={classes.listItemText}
									sx={{ opacity: open ? 1 : 0 }}
								/>
								<NotificationCount
									count={10}
									shouldDisplay={open}
								/>
							</ListItemButton>
						</ListItem>
					</Link>
				</List>
			</Box>
		</>
	);
};

export default MainNav;

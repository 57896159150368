import { fabric } from 'fabric';

const { Image } = fabric;

interface IOptions {
	imageUrl: string;
	containerHeight: number;
}

const AnnotationImage = ({
	imageUrl,
	containerHeight,
}: IOptions): Promise<fabric.Image> => {
	return new Promise((resolve) => {
		Image.fromURL(
			imageUrl,
			(image) => {
				image.scaleToHeight(containerHeight * 0.95);
				resolve(image);
			},
			{
				evented: false,
				hasControls: false,
				lockMovementX: true,
				lockMovementY: true,
				hoverCursor: 'crosshair',
				borderColor: 'transparent',
			}
		);
	});
};

export default AnnotationImage;

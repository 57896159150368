import List from '@mui/material/List';
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Link as ReactLink } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { FiLogOut, FiSettings } from 'react-icons/fi';

const fiIconSize = 22;
const drawerFontColor = '#fff';

const useStyles = makeStyles()((theme) => ({
	footerNavItemsContainer: {
		marginTop: 'auto',
	},
	listItemText: {
		color: drawerFontColor,
		textDecoration: 'none',
	},
	listItemIcon: {
		color: drawerFontColor,
	},
	divider: {
		background: drawerFontColor,
	},
	listItemButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			borderRight: '2px solid #fff',
		},
	},
}));

const FooterNav = ({
	open,
	handleSignOut,
}: {
	open: boolean;
	handleSignOut: React.MouseEventHandler<HTMLLIElement>;
}) => {
	const { classes } = useStyles();

	return (
		<List className={classes.footerNavItemsContainer}>
			<Divider className={classes.divider} />
			<Link to="/settings" underline="none" component={ReactLink}>
				<ListItem
					key="Settings"
					disablePadding
					sx={{ display: 'block' }}
				>
					<ListItemButton
						disableRipple
						className={classes.listItemButton}
						sx={{
							minHeight: 48,
							justifyContent: open ? 'initial' : 'center',
							px: 2.5,
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
							}}
						>
							<FiSettings
								size={fiIconSize}
								className={classes.listItemIcon}
							/>
						</ListItemIcon>
						<ListItemText
							primary="Settings"
							className={classes.listItemText}
							sx={{ opacity: open ? 1 : 0 }}
						/>
					</ListItemButton>
				</ListItem>
			</Link>
			<ListItem
				key="Log Out"
				disablePadding
				onClick={handleSignOut}
				sx={{ display: 'block' }}
			>
				<ListItemButton
					disableRipple
					className={classes.listItemButton}
					sx={{
						minHeight: 48,
						justifyContent: open ? 'initial' : 'center',
						px: 2.5,
					}}
				>
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: open ? 3 : 'auto',
							justifyContent: 'center',
						}}
					>
						<FiLogOut
							size={fiIconSize}
							className={classes.listItemIcon}
						/>
					</ListItemIcon>
					<ListItemText
						primary="Log Out"
						className={classes.listItemText}
						sx={{ opacity: open ? 1 : 0 }}
					/>
				</ListItemButton>
			</ListItem>
		</List>
	);
};

export default FooterNav;

import { fabric } from 'fabric';

interface IOptions {
	canvas: fabric.Canvas;
}

const toJson = ({ canvas }: IOptions): string => {
	const canvasObject = canvas.toJSON([
		'name',
		'data',
		'fill',
		'height',
		'width',
		'stroke',
		'evented',
		'originX',
		'originY',
		'scaleX',
		'scaleY',
		'skewX',
		'skewY',
		'left',
		'right',
		'top',
		'bottom',
		'opacity',
		'selectable',
		'hoverCursor',
		'borderColor',
		'strokeWidth',
		'hasControls',
		'lockMovementX',
		'lockMovementY',
		'subTargetCheck',
		'backgroundColor',
	]);

	return JSON.stringify(canvasObject);
};

export default toJson;

import { fabric } from 'fabric';

interface IPoints {
	x: number;
	y: number;
}

interface IConstructorOptions {
	points: IPoints;
}

interface IUpdateAngleOptions {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
}

class Circle {
	api: fabric.Circle;

	constructor({ points }: IConstructorOptions) {
		const { x, y } = points;

		this.api = new fabric.Circle({
			// Geometry
			left: x,
			top: y,
			radius: 7,
			originX: 'center',
			originY: 'center',
			// UI
			fill: 'red',
			stroke: 'red',
			hoverCursor: 'all-scroll',
			borderColor: 'transparent',
			backgroundColor: 'transparent',
			opacity: 1,
			// Controls
			hasBorders: false,
			selectable: true,
			hasControls: false,
			lockMovementX: false,
			lockMovementY: false,
		});
	}

	setAngle = ({ x1, x2, y1, y2 }: IUpdateAngleOptions) => {
		let angle;
		const verticalHeight = Math.abs(y2 - y1);
		const horizontalWidth = Math.abs(x2 - x1);
		const tangentRaio = verticalHeight / horizontalWidth;
		const basicAngle = (Math.atan(tangentRaio) * 180) / Math.PI;
		const offset = -90;

		if (x2 > x1) {
			if (y2 < y1) {
				angle = -basicAngle + offset;
			} else if (y2 === y1) {
				angle = 0 + offset;
			} else if (y2 > y1) {
				angle = basicAngle + offset;
			}
		} else if (x2 < x1) {
			if (y2 > y1) {
				angle = 180 - basicAngle + offset;
			} else if (y2 === y1) {
				angle = 180 + offset;
			} else if (y2 < y1) {
				angle = 180 + basicAngle + offset;
			}
		}

		if (angle) {
			this.api.set({ angle });
		}
	};
}

export default Circle;

import moment from 'moment';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { FiOctagon, FiCheckCircle } from 'react-icons/fi';
import { ICollection } from '../../../../../common/interfaces';

const useStyles = makeStyles()((theme) => ({
	collectionDetailsContainer: {
		display: 'flex',
		height: '100px',
		flexDirection: 'column',
		justifyItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
		paddingBottom: theme.spacing(0),
	},
	collectionDetailContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
	},
	collectionDetailHeader: {
		flexDirection: 'column',
		alignItems: 'center',
		alignContent: 'center',
		placeSelf: 'center',
		marginBottom: 'auto',
	},
	collectionDetailIconContainer: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		marginRight: '7px',
	},
	collectionDetailIcon: {
		color: 'rgba(0, 0, 0, 0.12)',
	},
	iconWarnColor: {
		color: theme.palette.warning.main,
	},
	iconSuccessColor: {
		color: theme.palette.success.main,
	},
	collectionDetailIconDeadline: {
		color: theme.palette.warning.main,
	},
	collectionDetailsName: {
		fontWeight: 'bold',
		fontSize: '1.1rem',
	},
}));

interface IProps {
	collection: ICollection;
	actionItemCount: number;
}

const CardDetails = ({ collection, actionItemCount }: IProps) => {
	const { classes } = useStyles();
	const { deadline, isReady } = collection;
	const deadlineFormatted = moment(deadline).format('l');

	return (
		<div className={classes.collectionDetailsContainer}>
			<Box className={`${classes.collectionDetailContainer}`}>
				<Typography className={classes.collectionDetailIconContainer}>
					{isReady ? (
						<FiCheckCircle
							size={18}
							className={`${classes.collectionDetailIcon} ${classes.iconSuccessColor}`}
						/>
					) : (
						<FiOctagon
							size={18}
							className={`${classes.collectionDetailIcon} ${classes.iconWarnColor}`}
						/>
					)}
				</Typography>
				<Typography>Is Ready</Typography>
			</Box>

			<Box className={classes.collectionDetailContainer}>
				<Typography className={classes.collectionDetailIconContainer}>
					{isReady ? (
						<FiCheckCircle
							size={18}
							className={`${classes.collectionDetailIcon} ${classes.iconSuccessColor}`}
						/>
					) : (
						<FiOctagon
							size={18}
							className={`${classes.collectionDetailIcon} ${classes.iconWarnColor}`}
						/>
					)}
				</Typography>
				<Typography>Action Items ({actionItemCount})</Typography>
			</Box>

			<Box className={`${classes.collectionDetailContainer}`}>
				<Typography className={classes.collectionDetailIconContainer}>
					{isReady ? (
						<FiCheckCircle
							size={18}
							className={`${classes.collectionDetailIcon} ${classes.iconSuccessColor}`}
						/>
					) : (
						<FiOctagon
							size={18}
							className={`${classes.collectionDetailIcon} ${classes.iconWarnColor}`}
						/>
					)}
				</Typography>
				<Typography>Deadline: {deadlineFormatted}</Typography>
			</Box>
		</div>
	);
};

export default CardDetails;

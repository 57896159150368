import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from 'tss-react/mui';
import { ReactElement, useState } from 'react';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const useStyles = makeStyles()((theme) => ({
	tab: {
		minWidth: '200px',
		textTransform: 'none',
	},
}));

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

interface IProps {
	openTasks: ReactElement[];
	closedTasks: ReactElement[];
}

const BasicTabs = ({ openTasks, closedTasks }: IProps) => {
	const { classes } = useStyles();
	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Tabs
				value={value}
				centered={true}
				textColor="primary"
				indicatorColor="primary"
				onChange={handleChange}
				aria-label="Tasks"
			>
				<Tab
					label="Outstanding"
					disableRipple={true}
					{...a11yProps(0)}
					className={classes.tab}
				/>
				<Tab
					label="Closed"
					disableRipple={true}
					{...a11yProps(1)}
					className={classes.tab}
				/>
			</Tabs>
			<TabPanel value={value} index={0}>
				{openTasks}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{closedTasks}
			</TabPanel>
		</Box>
	);
};

export default BasicTabs;

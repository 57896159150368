import Section from './Section';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import DataTable from './DataTable';
import rows from './DataTable/mockData/specificationTableRows';
import columns from './DataTable/mockData/specificationTableColumns';

const useStyles = makeStyles()((theme) => ({
	specificationContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(10),
	},
	tableHeaderCell: {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
	},
	tableCell: {
		'&:last-child': {
			borderRight: '1px solid rgba(0, 0, 0, 0)',
		},
		borderRight: '1px solid rgba(224, 224, 224, 1)',
	},
}));

const SpecificationDataTable = () => {
	const { classes } = useStyles();

	return (
		<Section header="Specification Data Table">
			<Box className={classes.specificationContainer}>
				<DataTable rows={rows} columns={columns} />
			</Box>
		</Section>
	);
};

export default SpecificationDataTable;

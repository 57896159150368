import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme) => ({
	root: {
		marginTop: '200px',
	},
	homeTitle: {
		fontSize: '2.5rem',
		color: theme.palette.primary.main,
	},
	hero: {
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		width: '100%',
		overflow: 'hidden',
	},
}));

const Home = () => {
	const { classes } = useStyles();

	return (
		<>
			<div className={classes.root}>
				<div className={classes.hero}>
					<Typography className={classes.homeTitle}>
						Automate your fashion design process
					</Typography>
				</div>
			</div>
		</>
	);
};

export default Home;
